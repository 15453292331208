/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AlertConfigParamsDto } from '../models/alert-config-params-dto';
import { LevelAlertDto } from '../models/level-alert-dto';
import { MinMaxAlertDto } from '../models/min-max-alert-dto';
import { PercentageChangeAlertDto } from '../models/percentage-change-alert-dto';

@Injectable({
  providedIn: 'root',
})
export class UserAlertsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAlertUsingGet
   */
  static readonly GetAlertUsingGetPath = '/frontend/user/alerts/{alertId}';

  /**
   * Get a specific alert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlertUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertUsingGet$Response(params: {
    alertId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.GetAlertUsingGetPath, 'get');
    if (params) {
      rb.path('alertId', params.alertId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>;
      })
    );
  }

  /**
   * Get a specific alert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlertUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertUsingGet(params: {
    alertId: number;
  },
  context?: HttpContext

): Observable<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)> {

    return this.getAlertUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>) => r.body as (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto))
    );
  }

  /**
   * Path part for operation updateAlertUsingPut
   */
  static readonly UpdateAlertUsingPutPath = '/frontend/user/alerts/{alertId}';

  /**
   * Update an existing alert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAlertUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlertUsingPut$Response(params: {
    alertId: number;
    body: (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.UpdateAlertUsingPutPath, 'put');
    if (params) {
      rb.path('alertId', params.alertId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>;
      })
    );
  }

  /**
   * Update an existing alert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAlertUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlertUsingPut(params: {
    alertId: number;
    body: (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)
  },
  context?: HttpContext

): Observable<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)> {

    return this.updateAlertUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>) => r.body as (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto))
    );
  }

  /**
   * Path part for operation deleteAlertUsingDelete
   */
  static readonly DeleteAlertUsingDeletePath = '/frontend/user/alerts/{alertId}';

  /**
   * Delete an existing alert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAlertUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlertUsingDelete$Response(params: {
    alertId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.DeleteAlertUsingDeletePath, 'delete');
    if (params) {
      rb.path('alertId', params.alertId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete an existing alert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAlertUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlertUsingDelete(params: {
    alertId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteAlertUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUserAlertsUsingGet
   */
  static readonly GetUserAlertsUsingGetPath = '/frontend/user/alerts';

  /**
   * Get all user's alerts.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAlertsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.GetUserAlertsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>>;
      })
    );
  }

  /**
   * Get all user's alerts.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAlertsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>> {

    return this.getUserAlertsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>>) => r.body as Array<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>)
    );
  }

  /**
   * Path part for operation createAlertUsingPost
   */
  static readonly CreateAlertUsingPostPath = '/frontend/user/alerts';

  /**
   * Create a new alert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAlertUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAlertUsingPost$Response(params: {
    body: (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.CreateAlertUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>;
      })
    );
  }

  /**
   * Create a new alert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAlertUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAlertUsingPost(params: {
    body: (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)
  },
  context?: HttpContext

): Observable<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)> {

    return this.createAlertUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<(LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto)>) => r.body as (LevelAlertDto | MinMaxAlertDto | PercentageChangeAlertDto))
    );
  }

  /**
   * Path part for operation getAlertConfigParametersUsingGet
   */
  static readonly GetAlertConfigParametersUsingGetPath = '/frontend/user/alerts/configuration';

  /**
   * Get configuration parameters related with Alerts page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlertConfigParametersUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertConfigParametersUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AlertConfigParamsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertsControllerService.GetAlertConfigParametersUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertConfigParamsDto>;
      })
    );
  }

  /**
   * Get configuration parameters related with Alerts page.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlertConfigParametersUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertConfigParametersUsingGet(params?: {
  },
  context?: HttpContext

): Observable<AlertConfigParamsDto> {

    return this.getAlertConfigParametersUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AlertConfigParamsDto>) => r.body as AlertConfigParamsDto)
    );
  }

}
