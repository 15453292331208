import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent {
  @Input() heading!: string;
  @Input() text!: string;
  @Input() icon?: string;
}
