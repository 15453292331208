import { createSelector } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import { getSharedState } from '@app-shared/store/shared.selectors';

export const queryCurrencyPairs = () =>
  createSelector(getSharedState, (state: SharedState) => state.currencyPairs.pairs);

export const queryCurrencyPairsWithStats = () =>
  createSelector(getSharedState, (state: SharedState) => state.currencyPairs.pairsWithStats);

export const queryFavouriteCurrencyPairs = () =>
  createSelector(getSharedState, (state: SharedState) => state.currencyPairs.favouritePairs);

export const queryCurrencyPairsFilter = () =>
  createSelector(getSharedState, (state: SharedState) => state.currencyPairs.pairsFilter);
