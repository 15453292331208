import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ActionButtonComponent } from '@app/shared/components/form/action-button/action-button.component';

@Component({
  selector: 'app-currencies-show-more-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, ActionButtonComponent],
  templateUrl: './currencies-show-more-button.component.html',
  styleUrls: ['./currencies-show-more-button.component.scss'],
})
export class CurrenciesShowMoreButtonComponent {
  @Input() currencies$: Observable<any[] | null> = of(null);
  @Input() currenciesLimited$: Observable<any[] | null> = of(null);
  @Input() buttonLabel = 'currencies.show-all';
  @Output() click = new EventEmitter<void>();

  onClick() {
    this.click.emit();
  }
}
