import { createFeatureSelector, createSelector } from '@ngrx/store';
import BigNumber from 'bignumber.js';

import { queryCurrencies } from '@app/shared/store/currencies/currencies.selectors';
import {
  OrderBookOrder,
  OrderBookOrderComputed,
  tradingPlatformFeatureKey,
  TradingPlatformState,
} from '@app/authenticated/trading-platform/store/trading-platform.state';
import { TradeItemDto } from '@app/generated/models/trade-item-dto';
import { OrderDirection } from '@app/authenticated/trading-platform/ui/trading-platform.enum';
import { OpenOrderDto } from '@app/generated/models/open-order-dto';
import { BestOrderItemDto } from '@app/generated/models/best-order-item-dto';
import {
  COMPUTE_ORDER_BOOK_ORDER_ON_FE,
  HIGHLIGHT_ONLY_FIRST_OCCURRENCE_OF_PRICE_IN_LAST_TRADES,
  MAXIMUM_VISIBLE_ORDERS_IN_LAST_ORDERS,
  MAXIMUM_VISIBLE_ORDERS_IN_MY_HISTORY,
  MAXIMUM_VISIBLE_ORDERS_IN_ORDER_BOOK,
} from '@app/authenticated/trading-platform/ui/trading-platform.constants';

type Record<T> = {
  [K in keyof T]: T[K];
};

const sortByKeyAsc = <T>(array: Record<T>[], key: keyof T): Record<T>[] =>
  [...(array || [])].sort((a, b) => {
    return Number(a[key]) - Number(b[key]);
  });

const sortByKeyDesc = <T>(array: Record<T>[], key: keyof T): Record<T>[] =>
  [...(array || [])].sort((a, b) => {
    return Number(b[key]) - Number(a[key]);
  });

const selectTradingPlatformState = createFeatureSelector<TradingPlatformState>(tradingPlatformFeatureKey);

const mapOrderBookData = <T extends { price?: number; cumulativePrice?: number }>(
  orders: Record<T>[],
  orderDirection: OrderDirection,
  openOrdersForSelectedPair: OpenOrderDto[] | null,
) => {
  const cumulativePriceMax = Math.max(...orders.map((order) => order.cumulativePrice ?? 0));
  return orders.map((order) => {
    const cumulativePrice = order.cumulativePrice ?? 0;
    const normalizedVolume = cumulativePrice / cumulativePriceMax;
    const hasUserOrder = openOrdersForSelectedPair?.some(({ price }) => price === order.price);

    return {
      ...order,
      hasUserOrder,
      type: orderDirection,
      cumulativePricePercentage: normalizedVolume * 100,
    };
  });
};

const computeOrderBookData = (
  orders: BestOrderItemDto[] = [],
  orderDirection: OrderDirection,
  openOrdersForSelectedPair: OpenOrderDto[] | null,
): OrderBookOrder[] => {
  if (COMPUTE_ORDER_BOOK_ORDER_ON_FE) {
    const ordersComputed: OrderBookOrderComputed[] = [];
    let totalPriceBN = BigNumber(0);
    let amountSumBN = BigNumber(0);

    for (let i = 0; i < orders.length; ++i) {
      const price = orders[i].price;
      const amount = orders[i].amount;

      if (Number.isFinite(price) && Number.isFinite(amount)) {
        const priceBN = BigNumber(price as number);
        const amountBN = BigNumber(amount as number);

        const order = {
          price,
          amount,
          cumulativePrice: 0,
          amountSum: 0,
          wholePartDiffers: false,
        };

        const orderCumulativePriceBN = totalPriceBN.plus(priceBN.multipliedBy(amountBN));
        order.cumulativePrice = orderCumulativePriceBN.toNumber();
        totalPriceBN = orderCumulativePriceBN;

        const orderAmountSumBN = amountSumBN.plus(amountBN);
        order.amountSum = orderAmountSumBN.toNumber();
        amountSumBN = orderAmountSumBN;

        const previousOrder = orders[i - 1];
        if (previousOrder) {
          if (Number.isFinite(previousOrder.price)) {
            const previousPrice = previousOrder.price;
            order.wholePartDiffers = Math.trunc(price as number) !== Math.trunc(previousPrice as number);
          }
          order.wholePartDiffers = false;
        } else {
          // first one always differs
          order.wholePartDiffers = true;
        }

        ordersComputed.push(order);
      }
    }

    return mapOrderBookData<OrderBookOrderComputed>(ordersComputed, orderDirection, openOrdersForSelectedPair);
  } else {
    return mapOrderBookData<BestOrderItemDto>(orders, orderDirection, openOrdersForSelectedPair);
  }
};

//region Account ID
// TODO: Temporary solution. It should be stored in its own store, such as UserInfo
export const selectAccountId = createSelector(selectTradingPlatformState, (state) => state.account?.id || null);
//endregion

//region Selected Currency Pair
export const selectCurrencyPair = createSelector(selectTradingPlatformState, (state) => state.currencyPair);
//endregion

//region Common info
export const selectCommonInfo = createSelector(
  selectAccountId,
  selectCurrencyPair,
  queryCurrencies(),
  (accountId, currencyPair, currencies) => {
    return {
      accountId,
      currencyPair,
      firstCurrency: currencies.find(({ name }) => currencyPair?.firstCurrency === name),
      secondCurrency: currencies.find(({ name }) => currencyPair?.secondCurrency === name),
    };
  },
);
//endregion

//region Currency Trade Limits
export const selectCurrencyTradeLimitsData = createSelector(
  selectTradingPlatformState,
  (state) => state.currencyTradeLimits.data,
);
export const selectCurrencyTradeLimitsStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.currencyTradeLimits.status,
);
export const selectCurrencyTradeLimitsError = createSelector(
  selectTradingPlatformState,
  (state) => state.currencyTradeLimits.error,
);
//endregion

//region Last Trades
export const selectLastTradesForSelectedPairData = createSelector(selectTradingPlatformState, (state) => {
  if (state.lastTrades.data) {
    const lastTradesLimitedAndSorted = sortByKeyDesc<TradeItemDto>(state.lastTrades.data, 'date').splice(
      0,
      MAXIMUM_VISIBLE_ORDERS_IN_LAST_ORDERS,
    );
    if (HIGHLIGHT_ONLY_FIRST_OCCURRENCE_OF_PRICE_IN_LAST_TRADES) {
      const lastTrades = [];
      for (let i = lastTradesLimitedAndSorted.length - 1; i >= 0; --i) {
        const trade = lastTradesLimitedAndSorted[i];
        const previousTrade = lastTradesLimitedAndSorted[i + 1];
        const typeIsNotHighlighted = previousTrade && previousTrade.price === trade.price; // <- highlight only the first occurrence of the price
        lastTrades.unshift({
          ...trade,
          typeIsNotHighlighted,
        });
      }
      return lastTrades;
    } else {
      return lastTradesLimitedAndSorted;
    }
  }
  return null;
});
export const selectLastTradesForSelectedPairStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.lastTrades.status,
);
export const selectLastTradesForSelectedPairError = createSelector(
  selectTradingPlatformState,
  (state) => state.lastTrades.error,
);
//endregion

//region Open Orders
export const selectOpenOrdersForSelectedPairData = createSelector(selectTradingPlatformState, (state) =>
  state.openOrders.data ? sortByKeyDesc<OpenOrderDto>(state.openOrders.data, 'date') : null,
);
export const selectOpenOrdersForSelectedPairStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.openOrders.status,
);
export const selectOpenOrdersForSelectedPairError = createSelector(
  selectTradingPlatformState,
  (state) => state.openOrders.error,
);
//endregion

//region Order Book
export const selectOrderBookForSelectedPairData = createSelector(selectTradingPlatformState, (state) => {
  const openOrdersForSelectedPair = state.openOrders.data;

  if (state.orderBook.data) {
    const asksSortedAndLimited = state.orderBook.data.asks
      ? sortByKeyDesc<BestOrderItemDto>(state.orderBook.data.asks, 'price').splice(
          0,
          MAXIMUM_VISIBLE_ORDERS_IN_ORDER_BOOK,
        )
      : [];
    const bidsSortedAndLimited = state.orderBook.data.bids
      ? sortByKeyDesc<BestOrderItemDto>(state.orderBook.data.bids, 'price').splice(
          0,
          MAXIMUM_VISIBLE_ORDERS_IN_ORDER_BOOK,
        )
      : [];

    const asks = computeOrderBookData(asksSortedAndLimited, OrderDirection.sell, openOrdersForSelectedPair);
    const bids = computeOrderBookData(bidsSortedAndLimited, OrderDirection.buy, openOrdersForSelectedPair);

    return {
      asks,
      bids,
    };
  }
  return null;
});
export const selectOrderBookForSelectedPairStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.orderBook.status,
);
export const selectOrderBookForSelectedPairError = createSelector(
  selectTradingPlatformState,
  (state) => state.orderBook.error,
);
// endregion

//region Trades History
export const selectTradesHistoryForSelectedPairData = createSelector(selectTradingPlatformState, (state) =>
  state.tradesHistory.data
    ? sortByKeyDesc<TradeItemDto>(state.tradesHistory.data, 'date')
        // The increment +1 is used to determine if the maximum order limit has been reached
        .splice(0, MAXIMUM_VISIBLE_ORDERS_IN_MY_HISTORY + 1)
    : null,
);
export const selectTradesHistoryForSelectedPairStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.tradesHistory.status,
);
export const selectTradesHistoryForSelectedPairError = createSelector(
  selectTradingPlatformState,
  (state) => state.tradesHistory.error,
);
//endregion

//region Trade Statistics
export const selectTradesStatisticsForSelectedPairData = createSelector(
  selectTradingPlatformState,
  (state) => state.tradesStatistics.data,
);
export const selectTradesStatisticsForSelectedPairStatus = createSelector(
  selectTradingPlatformState,
  (state) => state.tradesStatistics.status,
);
export const selectTradesStatisticsForSelectedPairError = createSelector(
  selectTradingPlatformState,
  (state) => state.tradesStatistics.error,
);
//endregion

//region Create order
export const selectOrderFormValues = createSelector(selectTradingPlatformState, (state) => state.orderFormValues);

export const selectBuyOrderFormValuesData = createSelector(
  selectOrderFormValues,
  (orderFormData) => orderFormData.buy.data,
);

export const selectSellOrderFormValuesData = createSelector(
  selectOrderFormValues,
  (orderFormData) => orderFormData.sell.data,
);
//endregion
