import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  @Input() class: string = '';
  @Input() width: string = '100%';
  @Input() height: string = '16px';
  @Input() variant: 'text' | 'circular' | 'rectangular' | 'rounded' = 'rounded';
}
