import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GuiParams } from '@app-shared/store/gui-params/gui-params-facade.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  routes = ['login', 'sign-up', 'reset-password'];

  constructor(private guiParams: GuiParams, private router: Router, private translate: TranslateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.guiParams.loggedIn$.pipe(
      map((loggedIn) => {
        const currentLang = this.translate.currentLang;
        if (loggedIn && this.isDisabledRoute(route)) {
          this.router.navigate([`/${currentLang}/`]);
          return false;
        }

        if (loggedIn) {
          return true;
        }

        const langRegex = /^\/(cs|en)(\/|$)/;
        const redirect = state.url.replace(langRegex, '');
        return this.isDisabledRoute(route) ? true : this.router.parseUrl(`/${currentLang}/login?redirect=${redirect}`);
      }),
    );
  }

  private isDisabledRoute = (route: ActivatedRouteSnapshot) => {
    return this.routes.some((val) => route?.routeConfig?.path?.includes(val));
  };
}
