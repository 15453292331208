import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserInfoControllerService } from '@app-generated/services/user-info-controller.service';
import { loadUserInfo, loadUserInfoSuccess, loadUserInfoFailure } from '@app-shared/store/user-info/user-info.actions';
import { forkJoin, of } from 'rxjs';
import { UserProfileControllerService } from '@app/generated/services/user-profile-controller.service';

@Injectable()
export class UserInfoEffects {
  loadUserInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfo),
      mergeMap(() =>
        forkJoin([this.userInfoController.getInfoUsingGet(), this.userProfileService.getProfileUsingGet()]).pipe(
          map(([userInfo, userProfile]) => {
            return loadUserInfoSuccess({ userInfo, userProfile });
          }),
          catchError((error) => of(loadUserInfoFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userInfoController: UserInfoControllerService,
    private userProfileService: UserProfileControllerService,
  ) {}
}
