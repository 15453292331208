export enum PaymentType {
  checkoutVisa = 'CHECKOUT_VISA',
  checkoutMastercard = 'CHECKOUT_MASTERCARD',
  bankTransfer = 'BANK_TRANSFER',
  userBalance = 'USER_BALANCE',
}

export enum TradeDirection {
  buy = 'BUY',
  sell = 'SELL',
}

export enum QuickTradeLimitState {
  ok = 'OK',
  underLimit = 'UNDER_LIMIT',
  aboveLimit = 'ABOVE_LIMIT',
  aboveLimitIncreaseIsPossible = 'ABOVE_LIMIT_INCREASE_IS_POSSIBLE',
}

export enum CryptoDisplayName {
  BTC = 'Bitcoin (BTC)',
  USDT = 'USDT',
  ETH = 'Ethereum (ETH)',
  ADA = 'Cardano (ADA)',
  SOL = 'Solana (SOL)',
  LTC = 'Litecoin (LTC)',
  XRP = 'Ripple (XRP)',
  DOGE = 'Dogecoin (DOGE)',
  BNB = 'Binance Coin (BNB)',
  AVAX = 'Avalanche (AVAX)',
  LINK = 'Chainlink (LINK)',
  DOT = 'Polkadot (DOT)',
  STX = 'Stacks (STX)',
  NEAR = 'NEAR Protocol (NEAR)',
  FIL = 'Filecoin (FIL)',
  WIF = 'dogwifhat (WIF)',
  ETC = 'Ethereum Classic (ETC)',
  FET = 'Fetch.ai (FET)',
  ARB = 'Arbitrum (ARB)',
  TAO = 'Bittensor (TAO)',
  AR = 'Arweave (AR)',
  FTM = 'Fantom (FTM)',
  INJ = 'Injective (INJ)',
  ATOM = 'Cosmos (ATOM)',
  UNI = 'Uniswap (UNI)',
  ICP = 'Internet Computer (ICP)',
  SUI = 'Sui (SUI)',
  HBAR = 'Hedera (HBAR)',
  APT = 'Aptos (APT)',
  MKR = 'Maker (MKR)',
  RUNE = 'THORChain (RUNE)',
  AAVE = 'Aave (AAVE)',
  OP = 'Optimism (OP)',
  IO = 'io.net (IO)',
  JTO = 'Jito (JTO)',
  PEPE = 'Pepe (PEPE)',
  SHIB = 'Shiba Inu (SHIB)',
  TRX = 'Tron (PEPE)',
  POL = 'Polygon (POL)',
  WLD = 'Worldcoin (WLD)',
}

export enum CryptoDisplayOnlyName {
  BTC = 'Bitcoin',
  USDT = 'USDT',
  ETH = 'Ethereum',
  ADA = 'Cardano',
  SOL = 'Solana',
  LTC = 'Litecoin',
  XRP = 'Ripple',
}

export enum CurrencyDisplayName {
  CZK = 'crypto.CZK',
  EUR = 'Euro',
  BTC = 'Bitcoin',
  USDT = 'USDT',
  ETH = 'Ethereum',
  ADA = 'Cardano',
  SOL = 'Solana',
  LTC = 'Litecoin',
  XRP = 'Ripple',
  DOGE = 'Dogecoin',
  BNB = 'Binance Coin',
  AVAX = 'Avalanche',
  LINK = 'Chainlink',
  DOT = 'Polkadot',
  STX = 'Stacks',
  NEAR = 'NEAR Protocol',
  FIL = 'Filecoin',
  WIF = 'dogwifhat',
  ETC = 'Ethereum Classic',
  FET = 'Fetch.ai',
  ARB = 'Arbitrum',
  TAO = 'Bittensor',
  AR = 'Arweave',
  FTM = 'Fantom',
  INJ = 'Injective',
  ATOM = 'Cosmos',
  UNI = 'Uniswap',
  ICP = 'Internet Computer',
  SUI = 'Sui',
  HBAR = 'Hedera',
  APT = 'Aptos',
  MKR = 'Maker',
  RUNE = 'THORChain',
  AAVE = 'Aave',
  OP = 'Optimism',
  IO = 'io.net',
  JTO = 'Jito',
  PEPE = 'Pepe',
  SHIB = 'Shiba Inu',
  TRX = 'Tron',
  POL = 'Polygon',
  WLD = 'Worldcoin',
}
