import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CountriesControllerService } from '@app/generated/services/countries-controller.service';
import { CountryDto } from '@app/generated/models/country-dto';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countriesCache$: ReplaySubject<CountryDto[]> = new ReplaySubject(1);

  constructor(private countriesService: CountriesControllerService) {}

  getCountries(): Observable<CountryDto[]> {
    if (!this.countriesCache$.observed) {
      this.countriesService
        .getAllUsingGet()
        .pipe(
          first(),
          tap((countries) => {
            this.countriesCache$.next(countries);
          }),
        )
        .subscribe();
    }

    return this.countriesCache$.asObservable();
  }
}
