export const CURRENCY_COLOR = Object.freeze({
  CZK: '#FF927E',
  EUR: '#4CA3FF',
  USDT: '#67BDA3',
  BTC: '#F6C34D',
  SOL: '#4CFFBF',
  XRP: '#4C90B6',
  ETH: '#777777',
  LTC: '#DBDBDB',
  ADA: '#6F94FF',
  OTHER: '#97989F',
});

export const CURRENCY_COLOR_HIGHLIGHTED = Object.freeze({
  CZK: '#FF6347',
  EUR: '#007BFF',
  USDT: '#26A17B',
  BTC: '#F2A900',
  SOL: '#00FFA3',
  XRP: '#006097',
  ETH: '#3C3C3D',
  LTC: '#CCCCCC',
  ADA: '#3366FF',
  OTHER: '#6A6C76',
});

// This is used as a fallback in case the currencies info is not loaded into the shared store
export const CURRENCY_ROUNDING: { [key: string]: number } = Object.freeze({
  CZK: 2,
  EUR: 2,
  BTC: 8,
  USDT: 2,
  ETH: 8,
  ADA: 6,
  SOL: 8,
  LTC: 8,
  XRP: 8,
  BCH: 8,
  DASH: 8,
  DAI: 2,
  DOGE: 8,
});

export const MAX_VISIBLE_CURRENCIES = 10;
