import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrolledToBottom]',
  standalone: true,
})
export class ScrolledToBottomDirective {
  @Output() public readonly scrolledToBottom = new EventEmitter();

  constructor() {}

  @HostListener('scroll', ['$event']) onScroll(event: {
    target: { scrollTop: number; scrollHeight: number; clientHeight: number } | undefined;
  }) {
    if (event.target !== undefined) {
      if (Math.abs(event.target.scrollTop - (event.target.scrollHeight - event.target.clientHeight)) < 100) {
        this.scrolledToBottom.emit();
      }
    }
  }
}
