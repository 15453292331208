/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BankidSignupDto } from '../models/bankid-signup-dto';
import { RedirectView } from '../models/redirect-view';

@Injectable({
  providedIn: 'root',
})
export class BankidControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSignupDataUsingGet
   */
  static readonly GetSignupDataUsingGetPath = '/frontend/bankid/get-signup-data';

  /**
   * Get and save a BankID access token to the session.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSignupDataUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignupDataUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BankidSignupDto>> {

    const rb = new RequestBuilder(this.rootUrl, BankidControllerService.GetSignupDataUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BankidSignupDto>;
      })
    );
  }

  /**
   * Get and save a BankID access token to the session.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSignupDataUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignupDataUsingGet(params?: {
  },
  context?: HttpContext

): Observable<BankidSignupDto> {

    return this.getSignupDataUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BankidSignupDto>) => r.body as BankidSignupDto)
    );
  }

  /**
   * Path part for operation redirectBankidUsingGet
   */
  static readonly RedirectBankidUsingGetPath = '/frontend/bankid/bankid-redirect';

  /**
   * Compose BankID URL and redirect to that URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectBankidUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectBankidUsingGet$Response(params?: {
    clickId?: string;
    affiliateCode?: string;
    referralCode?: string;
    consultant?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RedirectView>> {

    const rb = new RequestBuilder(this.rootUrl, BankidControllerService.RedirectBankidUsingGetPath, 'get');
    if (params) {
      rb.query('clickId', params.clickId, {});
      rb.query('affiliateCode', params.affiliateCode, {});
      rb.query('referralCode', params.referralCode, {});
      rb.query('consultant', params.consultant, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RedirectView>;
      })
    );
  }

  /**
   * Compose BankID URL and redirect to that URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `redirectBankidUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectBankidUsingGet(params?: {
    clickId?: string;
    affiliateCode?: string;
    referralCode?: string;
    consultant?: string;
  },
  context?: HttpContext

): Observable<RedirectView> {

    return this.redirectBankidUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<RedirectView>) => r.body as RedirectView)
    );
  }

}
