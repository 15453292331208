import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { loadBalances, setBalances, stopUpdating, updateBalance } from '@app/shared/store/balances/balances.actions';
import { UserAccountControllerService } from '@app/generated/services/user-account-controller.service';
import { PushpinService } from '@app/shared/services/pushpin.service';
import { NavigationStart, Router } from '@angular/router';
import { queryCurrencyBalances } from '@app/shared/store/balances/balances.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class BalancesEffects {
  loadBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBalances),
      switchMap((action) => {
        return this.accountService.getActiveGuiAccountUsingGet({ balances: true }).pipe(
          map(({ balances }) => {
            return setBalances({ balances });
          }),
          catchError((e) => {
            throw e;
          }),
        );
      }),
    ),
  );

  watchBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBalances),
      switchMap(() =>
        this.pushpinService.getBalances$().pipe(
          withLatestFrom(this.store.select(queryCurrencyBalances())),
          switchMap(([{ balances }, currentBalances]) => {
            const currentBalancesCurrencyKeys = Object.keys(currentBalances);
            const balancesCurrencyKeys = Object.keys(balances);
            /**
             * If the balance update contains a currency that already exists in the current balances,
             * a simple update is performed.
             */
            if (balancesCurrencyKeys.every((currency) => currentBalancesCurrencyKeys.includes(currency))) {
              return of(updateBalance({ balances }));
            }

            /**
             * If the balance update contains a currency that is not in the current balances,
             * it triggers a reload of the currencies.
             * This is relevant, for example, when a currency is traded for the first time.
             */
            return of(loadBalances());
          }),
          catchError((e) => {
            throw e;
          }),
          takeUntil(this.actions$.pipe(ofType(loadBalances))),
          takeUntil(this.actions$.pipe(ofType(stopUpdating))),
          takeUntil(this.navigationStart$),
        ),
      ),
    ),
  );

  private navigationStart$ = this.router.events.pipe(filter((event) => event instanceof NavigationStart));

  constructor(
    private store: Store,
    private actions$: Actions,
    private accountService: UserAccountControllerService,
    private pushpinService: PushpinService,
    private router: Router,
  ) {}
}
