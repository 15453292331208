<div>
  <h3
    *ngIf="!!paragraph.header"
    class="my-8 text-2xl font-thin text-center text-app-black"
    [ngClass]="paragraph.standalone ? 'text-2xl lg:text-6xl' : ''"
  >
    {{ !!articleNo ? articleNo + '.' : '' }}
    {{ !!paragraphNo ? paragraphNo : '' }} {{ paragraph.header | translate }}
  </h3>
  <div
    class="mb-3 text-app-grey"
    [ngClass]="paragraph.standalone ? 'lg:text-xl lg:mb-8 text-center lg :text-left ' : ''"
  >
    <strong>
      {{ !!articleNo && !paragraph.header ? articleNo + '.' : '' }}
      {{ !!paragraphNo && !paragraph.header ? paragraphNo : '' }}
    </strong>
    <span class="inner-html" [innerHTML]="paragraph.content | translate"></span>
  </div>
</div>
