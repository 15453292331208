import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input() checked = false;
  sanitizedLabel: SafeHtml = '';
  private subscription: Subscription = new Subscription();

  constructor(private sanitizer: DomSanitizer, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.value = this.checked;
    this.updateLabel();
    this.subscription = this.translate.onLangChange.subscribe(() => {
      this.updateLabel();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateLabel() {
    const translatedLabel = this.translate.instant(this.label);
    this.sanitizedLabel = this.sanitizer.bypassSecurityTrustHtml(translatedLabel);
  }
}
