import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'appSupport',
})
export class AppSupportPipe implements PipeTransform, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(private guiParams: GuiParams, private userInfo: UserInfo) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  transform(link: string | any[]): string | any[] {
    if (link.includes('/support') && !link.includes('/app/')) {
      this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.userInfo.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
          if (user.email) link += '?email=' + user.email;
        });
      });
    }
    return link;
  }
}
