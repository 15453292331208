import { Injectable, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, concat, fromEvent, Observable, Subject, combineLatest } from 'rxjs';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { filter, map, distinctUntilChanged, takeUntil, startWith, shareReplay, take } from 'rxjs/operators';
import { GuiParamsDto } from '@app/generated/models/gui-params-dto';
import { UserProfileControllerService } from '@app/generated/services/user-profile-controller.service';

export enum ColorScheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  SYSTEM = 'SYSTEM',
}

export enum ThemeColor {
  LIGHT = '#ffffff',
  DARK = '#333333',
}

@Injectable({
  providedIn: 'root',
})
export class ColorSchemeService implements OnDestroy {
  private colorScheme = new BehaviorSubject<ColorScheme>(ColorScheme.SYSTEM);
  private unsubscribe$ = new Subject<void>();
  private readonly resolvedColorScheme$: Observable<ColorScheme.LIGHT | ColorScheme.DARK>;
  private readonly isBrowser: boolean;
  guiParams$ = this.guiParams.guiParams$;

  constructor(
    private guiParams: GuiParams,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    readonly userProfileControllerService: UserProfileControllerService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.resolvedColorScheme$ = this.createResolvedColorSchemeObservable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getColorScheme(): Observable<ColorScheme.LIGHT | ColorScheme.DARK> {
    return this.resolvedColorScheme$;
  }

  init() {
    this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.initForLoggedUser();
      } else {
        this.initForNonLoggedUser();
      }
    });

    this.resolvedColorScheme$.pipe(takeUntil(this.unsubscribe$)).subscribe((scheme) => this.applyColorScheme(scheme));
    this.updateMetaTag('color-scheme', 'light dark');
    this.updateMetaTag('theme-color', ThemeColor.LIGHT);
  }

  private initForLoggedUser() {
    if (!this.isBrowser) {
      return;
    }

    const params$ = concat(this.guiParams.getCurrentAndSaveToStore(), this.guiParams$).pipe(
      filter((params: GuiParamsDto) => !!params.userLocale?.appearance),
      map((params: GuiParamsDto) => params.userLocale!.appearance as ColorScheme),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    );

    params$.subscribe((scheme: ColorScheme) => this.setColorScheme(scheme));
  }

  private initForNonLoggedUser() {
    try {
      const storedScheme = localStorage.getItem('colorScheme') as ColorScheme | null;
      if (storedScheme) {
        this.setColorScheme(storedScheme);
      }
    } catch (error) {}
  }

  private createResolvedColorSchemeObservable(): Observable<ColorScheme.LIGHT | ColorScheme.DARK> {
    if (!this.isBrowser) {
      return this.colorScheme.pipe(map((scheme) => (scheme === ColorScheme.SYSTEM ? ColorScheme.LIGHT : scheme)));
    }

    const colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const prefersDarkScheme$ = fromEvent<MediaQueryListEvent>(colorSchemeMediaQuery, 'change').pipe(
      map((event) => event.matches),
      startWith(colorSchemeMediaQuery.matches),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    );

    return combineLatest([this.colorScheme, prefersDarkScheme$]).pipe(
      map(([scheme, prefersDark]) =>
        scheme === ColorScheme.SYSTEM
          ? prefersDark
            ? ColorScheme.DARK
            : ColorScheme.LIGHT
          : scheme === ColorScheme.DARK
          ? ColorScheme.DARK
          : ColorScheme.LIGHT,
      ),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  setColorScheme(scheme: ColorScheme) {
    if (this.colorScheme.value === scheme) {
      return;
    }

    this.colorScheme.next(scheme);
    this.guiParams.loggedIn$.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        try {
          localStorage.setItem('colorScheme', scheme);
        } catch (error) {}
      } else {
        this.userProfileControllerService.changeAppearanceRequestUsingPost({ appearance: scheme }).subscribe();
      }
    });
  }

  private applyColorScheme(scheme: ColorScheme.LIGHT | ColorScheme.DARK) {
    this.document.documentElement.classList.toggle('dark', scheme === ColorScheme.DARK);
    this.document.documentElement.classList.toggle('light', scheme === ColorScheme.LIGHT);
    this.updateMetaTag('color-scheme', scheme.toLowerCase());
    this.updateMetaTag('theme-color', scheme === ColorScheme.DARK ? ThemeColor.DARK : ThemeColor.LIGHT);
  }

  private updateMetaTag(name: string, content: string): void {
    let meta = this.document.head.querySelector(`meta[name="${name}"]`) as HTMLMetaElement | null;
    if (!meta) {
      meta = this.document.createElement('meta');
      meta.setAttribute('name', name);
      this.document.head.appendChild(meta);
    }
    meta.setAttribute('content', content);
  }
}
