import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColorSchemeService, ColorScheme } from '@app/shared/services/color-scheme.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-appearance-switcher',
  templateUrl: './appearance-switcher.component.html',
  styleUrls: ['./appearance-switcher.component.scss'],
})
export class AppearanceSwitcherComponent implements OnInit, OnDestroy {
  currentScheme$: Observable<ColorScheme.LIGHT | ColorScheme.DARK>;
  private unsubscribe$ = new Subject<void>();
  protected readonly ColorScheme = ColorScheme;

  private currentSchemeSubject = new BehaviorSubject<ColorScheme.LIGHT | ColorScheme.DARK>(ColorScheme.LIGHT);

  constructor(private colorSchemeService: ColorSchemeService) {
    this.currentScheme$ = this.colorSchemeService.getColorScheme();
  }

  ngOnInit(): void {
    this.currentScheme$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.currentSchemeSubject);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setColorScheme(scheme: ColorScheme): void {
    this.colorSchemeService.setColorScheme(scheme);
  }

  toggleColorScheme() {
    const newScheme = this.isActive(ColorScheme.LIGHT) ? ColorScheme.DARK : ColorScheme.LIGHT;
    this.setColorScheme(newScheme);
  }

  isActive(scheme: ColorScheme): boolean {
    const currentScheme = this.currentSchemeSubject.getValue();
    return (
      (scheme === ColorScheme.LIGHT && currentScheme === ColorScheme.LIGHT) ||
      (scheme === ColorScheme.DARK && currentScheme === ColorScheme.DARK) ||
      (scheme === ColorScheme.SYSTEM && (currentScheme === ColorScheme.LIGHT || currentScheme === ColorScheme.DARK))
    );
  }
}
