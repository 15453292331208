/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BeneficiaryFlagsRequestBody } from '../models/beneficiary-flags-request-body';
import { PersonBasicDto } from '../models/person-basic-dto';
import { PersonFullDto } from '../models/person-full-dto';
import { ValueWrapperBoolean } from '../models/value-wrapper-boolean';

@Injectable({
  providedIn: 'root',
})
export class UserPersonsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveShareholderFlagUsingPut
   */
  static readonly SaveShareholderFlagUsingPutPath = '/frontend/user/persons/save-shareholder-flag/{shareholderId}';

  /**
   * Updates 'psc' flag of a shareholder
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveShareholderFlagUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveShareholderFlagUsingPut$Response(params: {
    shareholderId: number;
    body: ValueWrapperBoolean
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.SaveShareholderFlagUsingPutPath, 'put');
    if (params) {
      rb.path('shareholderId', params.shareholderId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates 'psc' flag of a shareholder
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveShareholderFlagUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveShareholderFlagUsingPut(params: {
    shareholderId: number;
    body: ValueWrapperBoolean
  },
  context?: HttpContext

): Observable<void> {

    return this.saveShareholderFlagUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveBeneficiaryFlagsUsingPut
   */
  static readonly SaveBeneficiaryFlagsUsingPutPath = '/frontend/user/persons/save-beneficiary-flag/{beneficiaryId}';

  /**
   * Updates 'ubu'/'pep'/'us' flags of a beneficiary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBeneficiaryFlagsUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBeneficiaryFlagsUsingPut$Response(params: {
    beneficiaryId: number;
    body: BeneficiaryFlagsRequestBody
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.SaveBeneficiaryFlagsUsingPutPath, 'put');
    if (params) {
      rb.path('beneficiaryId', params.beneficiaryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates 'ubu'/'pep'/'us' flags of a beneficiary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveBeneficiaryFlagsUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBeneficiaryFlagsUsingPut(params: {
    beneficiaryId: number;
    body: BeneficiaryFlagsRequestBody
  },
  context?: HttpContext

): Observable<void> {

    return this.saveBeneficiaryFlagsUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveOtherCompanyPersonUsingPost
   */
  static readonly SaveOtherCompanyPersonUsingPostPath = '/frontend/user/persons/other-company-person';

  /**
   * Save a OTHER_COMPANY_PERSON - shareholder (Create or update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOtherCompanyPersonUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOtherCompanyPersonUsingPost$Response(params: {
    body: PersonBasicDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PersonBasicDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.SaveOtherCompanyPersonUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonBasicDto>;
      })
    );
  }

  /**
   * Save a OTHER_COMPANY_PERSON - shareholder (Create or update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveOtherCompanyPersonUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOtherCompanyPersonUsingPost(params: {
    body: PersonBasicDto
  },
  context?: HttpContext

): Observable<PersonBasicDto> {

    return this.saveOtherCompanyPersonUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<PersonBasicDto>) => r.body as PersonBasicDto)
    );
  }

  /**
   * Path part for operation saveDirectorUsingPost
   */
  static readonly SaveDirectorUsingPostPath = '/frontend/user/persons/director';

  /**
   * Save a DIRECTOR (Create or update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDirectorUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDirectorUsingPost$Response(params: {
    body: PersonFullDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PersonFullDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.SaveDirectorUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonFullDto>;
      })
    );
  }

  /**
   * Save a DIRECTOR (Create or update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveDirectorUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDirectorUsingPost(params: {
    body: PersonFullDto
  },
  context?: HttpContext

): Observable<PersonFullDto> {

    return this.saveDirectorUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<PersonFullDto>) => r.body as PersonFullDto)
    );
  }

  /**
   * Path part for operation getUserPersonUsingGet
   */
  static readonly GetUserPersonUsingGetPath = '/frontend/user/persons';

  /**
   * Get user PERSON.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPersonUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPersonUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PersonFullDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.GetUserPersonUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonFullDto>;
      })
    );
  }

  /**
   * Get user PERSON.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPersonUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPersonUsingGet(params?: {
  },
  context?: HttpContext

): Observable<PersonFullDto> {

    return this.getUserPersonUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PersonFullDto>) => r.body as PersonFullDto)
    );
  }

  /**
   * Path part for operation getDirectorsUsingGet
   */
  static readonly GetDirectorsUsingGetPath = '/frontend/user/persons/directors';

  /**
   * Get user directors.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirectorsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectorsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PersonFullDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.GetDirectorsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonFullDto>>;
      })
    );
  }

  /**
   * Get user directors.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDirectorsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectorsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<PersonFullDto>> {

    return this.getDirectorsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PersonFullDto>>) => r.body as Array<PersonFullDto>)
    );
  }

  /**
   * Path part for operation getAllCompanyPersonsUsingGet
   */
  static readonly GetAllCompanyPersonsUsingGetPath = '/frontend/user/persons/company-persons';

  /**
   * Get all company persons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCompanyPersonsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCompanyPersonsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PersonBasicDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.GetAllCompanyPersonsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonBasicDto>>;
      })
    );
  }

  /**
   * Get all company persons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCompanyPersonsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCompanyPersonsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<PersonBasicDto>> {

    return this.getAllCompanyPersonsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PersonBasicDto>>) => r.body as Array<PersonBasicDto>)
    );
  }

  /**
   * Path part for operation deleteUsingDelete
   */
  static readonly DeleteUsingDeletePath = '/frontend/user/persons/{personId}';

  /**
   * Delete an existing person (DIRECTOR or OTHER_COMPANY_PERSON).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUsingDelete$Response(params: {
    personId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserPersonsControllerService.DeleteUsingDeletePath, 'delete');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete an existing person (DIRECTOR or OTHER_COMPANY_PERSON).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUsingDelete(params: {
    personId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
