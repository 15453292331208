import { Component, Input } from '@angular/core';
import { ActionButtonComponent } from '@app/shared/components/form/action-button/action-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';

@Component({
  selector: 'app-currencies-price-update-button',
  standalone: true,
  imports: [ActionButtonComponent, TranslateModule, LocalizedDatePipe],
  templateUrl: './currencies-price-update-button.component.html',
  styleUrl: './currencies-price-update-button.component.scss',
})
export class CurrenciesPriceUpdateButtonComponent {
  @Input() date?: Date;
}
