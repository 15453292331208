import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class AppGtmService {
  constructor(private gtmService: GoogleTagManagerService, private guiParams: GuiParams) {}

  async pushTag(eventData: any): Promise<void> {
    try {
      const guiParams = await firstValueFrom(this.guiParams.guiParams$);

      // visitor might be already set in the eventData, since some are flowing from the backend using SSE
      if (guiParams.visitor && !eventData.visitor) {
        eventData.visitor = guiParams.visitor;
      }

      return await this.gtmService.pushTag(eventData);
    } catch (error) {
      console.error('Error pushing event to GTM:', error);
      return Promise.resolve();
    }
  }
}
