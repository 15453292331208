/**
 * Cell definition for the table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
import { Directive } from '@angular/core';
import { CdkCellDef, CdkFooterCellDef, CdkHeaderCellDef } from '@angular/cdk/table';

@Directive({
  selector: '[appCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: CellDefDirective }],
})
export class CellDefDirective extends CdkCellDef {}

/**
 * Header cell definition for the table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[appHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: HeaderCellDefDirective }],
})
export class HeaderCellDefDirective extends CdkHeaderCellDef {}

/**
 * Footer cell definition for the table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[appFooterCellDef]',
  providers: [{ provide: CdkFooterCellDef, useExisting: FooterCellDefDirective }],
})
export class FooterCellDefDirective extends CdkFooterCellDef {}
