<div class="cm-paragraph cm-form-paragraph" [ngClass]="{ error: showError }">
  <label *ngIf="label" for="{{ id }}" translate>{{ label }}</label>
  <div>
    <div class="app-select">
      <select
        id="{{ id }}"
        class="max-w-full mb-0 outline-0 cm-input text-app-black"
        [ngClass]="{ 'border-red-500': showError, 'border-gray-500': !value }"
        [(ngModel)]="value"
        (focusout)="onTouched()"
        [autofocus]="autofocus"
        [disabled]="!!disabled || !!waiting"
      >
        <option *ngIf="placeholder" [value]="null" disabled translate>{{ placeholder || '' }}</option>
        <ng-content select="[app-option]"></ng-content>
      </select>
      <app-loader *ngIf="waiting" class="select-loader"></app-loader>
    </div>

    <span *ngIf="showError" class="clear-both cm-form-error-text"> {{ errorMessage || '' | translate }}</span>
  </div>
</div>
