import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackButtonSupportService } from '@app/shared/services/back-button-support.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  showBackButton = true;

  private unsubscribe$ = new Subject<void>();

  constructor(public router: Router, private backService: BackButtonSupportService) {}

  ngOnInit() {
    this.backService.currentDisplayState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => (this.showBackButton = val));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
