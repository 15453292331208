import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[double-section-page-template-icon]',
  template: '',
  styleUrls: ['../double-section-page-template.component.scss'],
  host: {
    '[class.double-section-page-icon]': 'true',
  },
})
export class DoubleSectionPageTemplateIconComponent {}
