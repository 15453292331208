import { Component, forwardRef, Input } from '@angular/core';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends AbstractComponent {
  @Input()
  placeholder?: string;
  isFocused?: boolean = false;

  constructor() {
    super();
  }
}
