import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterceptorService } from '../services/interceptor-service';

@Injectable()
export class DepositWithdrawalHttpInterceptor implements HttpInterceptor {
  constructor(private interceptorService: InterceptorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerValue = this.interceptorService.getCurrentValue();

    if (this.isWithdrawalRequest(req) && req.method === 'POST') {
      if (headerValue) {
        const modifiedReq = req.clone({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          setHeaders: { 'TWO-FACTOR-DATA': headerValue },
        });
        return next.handle(modifiedReq);
      }
    }
    return next.handle(req);
  }

  private isWithdrawalRequest(req: HttpRequest<any>): boolean {
    const pattern = /\/frontend\/user\/withdrawals\/[^\/]+\/methods\/[^\/]+/;
    return pattern.test(req.url);
  }
}
