import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, formatNumber } from '@angular/common';
import { LanguageService } from '@app/shared/services/language.service';
import { map } from 'rxjs';

@Pipe({
  name: 'appIsCurrencyLowerPipe',
  pure: false,
})
export class AppIsCurrencyLowerPipe implements PipeTransform {
  private langToLocale: Record<string, string> = {
    en: 'en-US',
    cs: 'cs-CZ',
  } as const;

  private _pipe: AsyncPipe;

  constructor(private ref: ChangeDetectorRef, private lang: LanguageService) {
    this._pipe = new AsyncPipe(ref);
  }

  transform(maybeValue: number | null, digitsInfo?: string): string | null {
    if (maybeValue === null) {
      return '';
    }
    const value = maybeValue as number;
    const minimalPrecisionUnit = 0.01;

    return this._pipe.transform(
      this.lang.currentLanguage$.pipe(
        map((lang) => {
          const valueFormatted = formatNumber(
            Math.max(value, minimalPrecisionUnit),
            this.langToLocale[lang],
            digitsInfo,
          );
          return value < minimalPrecisionUnit ? '<' + valueFormatted : valueFormatted;
        }),
      ),
    );
  }
}
