import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';

@Component({
  selector: 'app-logged-link-pair',
  templateUrl: './logged-link-pair.component.html',
})
export class LoggedLinkPairComponent {
  @Input() href!: string;
  @Input() routerLink!: string;
  @Input() appearance?: string;
  @Input() linkAppearance?: string;
  @Input() label?: string;
  @Input() addressLabel!: string;
  @Input() addressIcon!: string;

  isLogged$: Observable<boolean>;

  constructor(private guiParams: GuiParams) {
    this.isLogged$ = this.guiParams.loggedIn$;
  }
}
