import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { map } from 'rxjs';
import {
  loadGuiParams,
  loadGuiParamsFailure,
  loadGuiParamsLoading,
  loadGuiParamsSuccess,
} from '@app-shared/store/gui-params/gui-params.actions';
import { GuiParametersControllerService } from '@app-generated/services/gui-parameters-controller.service';
import { queryGuiParamsStatus } from '@app/shared/store/gui-params/gui-params.selectors';
import { Store } from '@ngrx/store';
import { ApiStateStatus, State } from '@app/shared/store/gui-params/gui-params.reducer';

@Injectable()
export class GuiParamsEffects {
  loadGuiParams = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadGuiParams),
      withLatestFrom(this.store.select(queryGuiParamsStatus())),
      filter(([_action, status]) => status !== ApiStateStatus.loading),
      tap(() => this.store.dispatch(loadGuiParamsLoading())),
      mergeMap(() => {
        return this.guiParamsController.getAllParamsUsingGet().pipe(
          take(1),
          map((guiParams) => {
            return loadGuiParamsSuccess({ guiParams });
          }),
          catchError((error) => {
            this.store.dispatch(loadGuiParamsFailure());
            throw error;
          }),
        );
      }),
    );
  });

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private guiParamsController: GuiParametersControllerService,
  ) {}
}
