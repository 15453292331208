import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JsonLDService {
  scriptType = 'application/json+ld';

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  orgSchema = () => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://coinmate.io',
    name: 'Coinmate s.r.o',
    logo: 'https://coinmate.io/assets/coinmate_logo_short.svg',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'SK',
      addressRegion: 'Slovakia ',
      addressLocality: 'Bratislava',
      postalCode: '851 01',
      streetAddress: 'Údernická 10',
    },
    availableLanguage: 'en',
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'Support page URL',
      url: 'https://coinmate.io/support',
    },
    slogan: 'Trade bitcoin on European cryptocurrency exchange',
    vatID: 'Company No. 52 902 617',
    sameAs: [
      'https://www.facebook.com/Coinmate',
      'https://twitter.com/CoinmateBT',
      'https://www.linkedin.com/company/coinmate/',
    ],
  });

  //this method is to be used once microdata differ site to site. More in app.component.ts.
  removeStructuredData(): void {
    const els: any[] = [];
    const types = ['structured-data', 'structured-data-org'];
    types.forEach((c: any) => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach((el: any) => this._document.head.removeChild(el));
  }

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
    let script;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(className).length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    // @ts-ignore
    script.type = this.scriptType;
    // @ts-ignore
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }
}
