import { NgModule } from '@angular/core';
import { DoubleSectionPageTemplateComponent } from '@app/shared/components/double-section-page-template/double-section-page-template.component';
import { DoubleSectionPageTemplateTitleComponent } from './double-section-page-template-title/double-section-page-template-title.component';
import { DoubleSectionPageTemplateIconComponent } from './double-section-page-template-icon/double-section-page-template-icon.component';

@NgModule({
  declarations: [DoubleSectionPageTemplateTitleComponent, DoubleSectionPageTemplateIconComponent],
  exports: [
    DoubleSectionPageTemplateComponent,
    DoubleSectionPageTemplateTitleComponent,
    DoubleSectionPageTemplateIconComponent,
  ],
  imports: [DoubleSectionPageTemplateComponent],
})
export class DoubleSectionPageTemplateModule {}
