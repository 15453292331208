import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { CdkAccordion } from '@angular/cdk/accordion';
import { AccordionBase, APP_ACCORDION } from '@app/shared/components/accordion/accordion-base';
import { AccordionItemComponent } from '@app/shared/components/accordion/accordion-item/accordion-item.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  providers: [
    {
      provide: APP_ACCORDION,
      useExisting: AccordionComponent,
    },
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.app-accordion]': 'true',
  },
})
export class AccordionComponent extends CdkAccordion implements AccordionBase, AfterContentInit, OnDestroy {
  @Output() readonly opened: EventEmitter<AccordionItemComponent> = new EventEmitter<AccordionItemComponent>();
  @Output() readonly closed: EventEmitter<AccordionItemComponent> = new EventEmitter<AccordionItemComponent>();

  @ContentChildren(AccordionItemComponent, { descendants: true })
  readonly itemComponents?: QueryList<AccordionItemComponent>;

  ngAfterContentInit() {
    this.itemComponents?.forEach((item) => {
      item.opened.subscribe(() => this.onItemOpened(item));
      item.closed.subscribe(() => this.onItemClosed(item));
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.opened.complete();
    this.closed.complete();
  }

  private onItemOpened(item: AccordionItemComponent) {
    this.opened.emit(item);
  }

  private onItemClosed(item: AccordionItemComponent) {
    this.closed.emit(item);
  }
}
