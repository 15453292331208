import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuVerticalPopoverService } from '@app/shared/components/menu-vertical/menu-vertical-popover.service';

const DESKTOP_BREAKPOINT = 768;

@Component({
  selector: 'app-menu-vertical',
  templateUrl: './menu-vertical.component.html',
  styleUrls: ['./menu-vertical.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [MenuVerticalPopoverService],
})
export class MenuVerticalComponent implements OnInit {
  visible = false;

  constructor(private menuVerticalPopoverService: MenuVerticalPopoverService) {}

  ngOnInit() {
    this.menuVerticalPopoverService.popoverVisible$.subscribe((visible) => {
      this.visible = visible;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.visible && event.target.innerWidth > DESKTOP_BREAKPOINT) {
      this.menuVerticalPopoverService.close();
    }
  }
}
