<ng-container *ngIf="displayCurrencies.length > 0">
  <ul
    class="flex space-x-4 items-center p-4 list-none cursor-pointer h-9 text-[12px] sm:text-[14px] leading-[16px] font-sans"
    (click)="openModal()"
  >
    <li class="font-normal text-cm-grey-400 dark:text-cm-grey-200 leading-[20px]">{{ 'balances' | translate }}</li>
    <li class="flex space-x-4">
      <ul class="flex space-x-4 list-none">
        <li
          *ngFor="let currency of displayCurrencies"
          class="flex items-center space-x-1 text-cm-blue-900 dark:text-app-dark-mode-white tracking-tight"
        >
          <span class="font-semibold">{{ currency.name }}:</span>
          <span class="font-normal">{{ currency.available | appCurrencyFloor: currency.name | async }}</span>
        </li>
      </ul>
    </li>
    <li class="font-semibold text-cm-blue-500" *ngIf="moreCount > 0">+{{ moreCount }} {{ 'more' | translate }}</li>
  </ul>
</ng-container>
