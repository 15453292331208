<div class="public-page-wrapper">
  <div class="pt-4 h-auto bg-top bg-cover public-header" [style.background-image]="'url(' + backgroundImg + ')'">
    <div class="pt-28 mb-7 h-80 text-center lg:pt-36 lg:mb-14 header-text lg:h-100">
      <h1 class="text-4xl font-thin lg:text-7xl text-app-blue">
        {{ headingText | translate }}
      </h1>
      <p class="py-7 text-lg font-light lg:py-10 lg:text-2xl text-app-white">
        {{ headingSubText | translate }}
      </p>
    </div>
    <button
      (click)="scroll(content)"
      class="bg-white shadow-md focus:outline-none overlapping-button flex justify-center items-center"
    >
      <svg-icon svgClass="text-xl font-bold text-app-blue" src="/assets/icons/24/chevron-down.svg"></svg-icon>
    </button>
  </div>
  <div class="m-auto public-content w-1/1" #content>
    <ng-content></ng-content>
  </div>
</div>
