<ng-content></ng-content>

<ng-container *ngIf="mobileMenuVerticalTriggerContentRef; else triggerIcon">
  <ng-container
    *ngTemplateOutlet="mobileMenuVerticalTriggerContentRef; context: { $implicit: mobileMenuVerticalIsVisible }"
  ></ng-container>
</ng-container>

<ng-template #triggerIcon>
  <svg-icon *ngIf="!mobileMenuVerticalIsVisible" src="/assets/icons/24/chevron-down.svg"></svg-icon>
  <svg-icon *ngIf="mobileMenuVerticalIsVisible" src="/assets/icons/24/close.svg"></svg-icon>
</ng-template>
