<div class="cm-alert-message" [ngClass]="getAlertLevelClass()">
  <div class="text">
    <h3 *ngIf="title" [innerHTML]="title"></h3>
    <p *ngIf="message" [innerHTML]="message"></p>
  </div>
  <button class="close" (click)="remove()">
    <svg-icon src="/assets/icons/close-circle.svg"></svg-icon>
    <span class="sr-only">{{ 'shared.common.close' | translate }}</span>
  </button>
</div>
