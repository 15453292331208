import { Component, Input, OnInit } from '@angular/core';

export interface Paragraph {
  readonly descriptor?: 'paragraph';
  header?: string;
  content: string;
  standalone?: boolean;
}

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
  @Input()
  paragraph!: Paragraph;

  @Input()
  paragraphNo?: number | null = null;

  @Input()
  articleNo?: number | null = null;

  constructor() {}

  ngOnInit(): void {}
}
