import { Component, OnInit, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
})
export class CopyToClipboardComponent implements OnInit {
  @Input() public toCopy!: string | string[];
  public copied = false;

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {}

  copyText() {
    this.clipboard.copy(Array.isArray(this.toCopy) ? this.toCopy.join('\n') : this.toCopy);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1500);
  }
}
