<div class="relative flex gap-x-[12px]">
  <input
    type="text"
    class="w-full border rounded-md px-[12px] py-[8px] pl-[40px]"
    placeholder="{{ searchPlaceholder }}"
    [(ngModel)]="searchTerm"
    (input)="onSearchTermChange($event)"
  />
  <svg-icon src="/assets/icons/icon-modal-search.svg" class="absolute left-[12px] top-[10px]"></svg-icon>
</div>
