import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import {
  queryCurrencyPairs,
  queryCurrencyPairsWithStats,
  queryFavouriteCurrencyPairs,
  queryCurrencyPairsFilter,
} from '@app-shared/store/currency-pairs/currency-pairs.selectors';
import {
  addCurrencyPairToFavourites,
  clearFilter,
  loadCurrencyPairs,
  loadCurrencyPairsWithStats,
  loadFavouriteCurrencyPairs,
  removeCurrencyPairFromFavourites,
  toggleFilter,
} from '@app-shared/store/currency-pairs/currency-pairs.actions';
import { CurrencyPairWithStatsDto } from '@app-generated/models/currency-pair-with-stats-dto';
import { UserCurrencyPairFavouritesControllerService } from '@app-generated/services/user-currency-pair-favourites-controller.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrencyPairs implements OnDestroy {
  currencyPairs$ = this.store.select(queryCurrencyPairs());
  currencyPairsWithStats$ = this.store.select(queryCurrencyPairsWithStats());
  favouriteCurrencyPairs$ = this.store.select(queryFavouriteCurrencyPairs());
  currencyPairsFilter$ = this.store.select(queryCurrencyPairsFilter());

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<SharedState>,
    private favouriteController: UserCurrencyPairFavouritesControllerService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadCurrencyPairs() {
    this.store.dispatch(loadCurrencyPairs());
  }

  loadCurrencyPairsWithStats() {
    this.store.dispatch(loadCurrencyPairsWithStats());
  }

  loadFavouriteCurrencyPairs() {
    this.store.dispatch(loadFavouriteCurrencyPairs());
  }

  addFavouritePair(pair: CurrencyPairWithStatsDto) {
    if (pair.name)
      this.favouriteController
        .addToFavouritesUsingPost({ body: pair.name })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    this.store.dispatch(addCurrencyPairToFavourites({ pair }));
  }

  removeFavouritePair(name: string) {
    this.favouriteController
      .removeFromFavouritesUsingDelete({ currencyPairName: name })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
    this.store.dispatch(removeCurrencyPairFromFavourites({ name }));
  }

  toggleFilter(name: string) {
    this.store.dispatch(toggleFilter({ name }));
  }

  clearFilter() {
    this.store.dispatch(clearFilter());
  }
}
