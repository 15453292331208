import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link-pair',
  templateUrl: './link-pair.component.html',
  styleUrls: ['./link-pair.component.scss'],
})
export class LinkPairComponent {
  @Input() appearance?: string;
  @Input() linkAppearance?: string;
  @Input() label?: string;
  @Input() addressLabel!: string;
  @Input() addressIcon!: string;
  @Input() openInNewWindow?: boolean;
  @Input() addressHref?: string;
  @Input() routerLink?: string;
  @Input() fragment?: string;
  @Input() onClick?: () => void;
}
