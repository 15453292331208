import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SelectableCurrency, SelectedCurrencyService } from '@app/shared/services/selected-currency.service';
import { BalanceWithCurrencyEquivalents } from '@app/shared/store/balances/balances.selectors';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { MAX_VISIBLE_CURRENCIES } from '@app/shared/const/currencies';

export interface CurrencyWithBalance extends BalanceWithCurrencyEquivalents {
  favorite: boolean;
}

export enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

const normalizeText = (str: string): string =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

@Component({
  selector: 'app-balances-table',
  templateUrl: './balances-table.component.html',
  styleUrls: ['./balances-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BalancesTableComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<CurrencyWithBalance[] | null> = of([]);
  @Input() loading = false;
  @Output() addFavorite = new EventEmitter<CurrencyWithBalance>();
  @Output() removeFavorite = new EventEmitter<CurrencyWithBalance>();

  @ContentChild('cellTotalBalance', { static: false }) cellTotalBalanceRef!: TemplateRef<any>;
  @ContentChild('cellPrice', { static: false }) cellPriceRef!: TemplateRef<any>;
  @ContentChild('cellChange', { static: false }) cellChangeRef!: TemplateRef<any>;

  @ContentChild('actionDropdown', { static: false }) actionDropdownRef!: TemplateRef<any>;
  @ContentChild('actionButtons', { static: false }) actionButtonsRef!: TemplateRef<any>;

  dataFiltered$: Observable<CurrencyWithBalance[] | null> = of(null);
  dataLimited$: Observable<CurrencyWithBalance[] | null> = of(null);

  searchTermSubject = new BehaviorSubject<string>('');
  showFavoritesOnly$ = new BehaviorSubject<boolean>(false);
  showWithAmountOnly$ = new BehaviorSubject<boolean>(true);
  selectedFiatCurrency$: Observable<SelectableCurrency>;

  filtersCount$ = combineLatest([this.showFavoritesOnly$, this.showWithAmountOnly$]).pipe(
    map(([showFavoritesOnly, showWithAmountOnly]) => {
      if (showFavoritesOnly && showWithAmountOnly) {
        return 2;
      } else if (showFavoritesOnly || showWithAmountOnly) {
        return 1;
      } else {
        return 0;
      }
    }),
  );

  withdrawalBlocked = false;
  withdrawalBlockedUntilDate: string | undefined;
  depositBlocked = false;
  depositVerified = false;

  protected readonly apiStateStatusEnum = ApiStateStatus;

  private isCurrenciesListLimitedSubject = new BehaviorSubject<boolean>(true);

  private unsubscribe$ = new Subject<void>();

  constructor(private guiParams: GuiParams, private selectedCurrency: SelectedCurrencyService) {
    this.selectedFiatCurrency$ = selectedCurrency.selectedCurrency$;
  }

  ngOnInit() {
    this.guiParams.guiParams$.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params) {
        // if withdrawalBlockedUntilDate is set it means that crypto withdrawal is blocked. Fiat withdrawal is not affected by this.
        this.withdrawalBlockedUntilDate = params.withdrawalBlockedUntil || undefined;
        this.withdrawalBlocked = params.withdrawalBlocked;
        this.depositBlocked = params.depositBlocked;
        this.depositVerified = params.depositVerified;
      }
    });

    this.dataFiltered$ = combineLatest([
      this.searchTermSubject,
      this.showFavoritesOnly$,
      this.showWithAmountOnly$,
      this.selectedFiatCurrency$,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(([searchTerm, showFavoritesOnly, showWithAmountOnly, selectedFiatCurrency]) =>
        this.filterCurrencyBalances(searchTerm, showFavoritesOnly, showWithAmountOnly, selectedFiatCurrency),
      ),
    );

    this.dataLimited$ = combineLatest([
      this.dataFiltered$,
      this.searchTermSubject,
      this.isCurrenciesListLimitedSubject,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([filteredData, searchTerm, isCurrenciesListLimited]) => {
        if (filteredData && !searchTerm && isCurrenciesListLimited) {
          return filteredData.slice(0, MAX_VISIBLE_CURRENCIES);
        }

        return filteredData;
      }),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSearchTermChange(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTermSubject.next(searchTerm);
  }

  onToggleFavoritesOnly(): void {
    this.showFavoritesOnly$.next(!this.showFavoritesOnly$.value);
  }

  onToggleWithAmountOnly(): void {
    this.showWithAmountOnly$.next(!this.showWithAmountOnly$.value);
  }

  onToggleFavorite(currency: CurrencyWithBalance): void {
    if (currency.favorite) {
      this.removeFavorite.emit(currency);
    } else {
      this.addFavorite.emit(currency);
    }
  }

  onShowAllCurrencies() {
    this.isCurrenciesListLimitedSubject.next(false);
  }

  isStartOfNonFavorites(index: number, currencies: CurrencyWithBalance[] | null): boolean {
    if (!currencies || index <= 0 || index >= currencies.length) {
      return false;
    }

    const currentFavorite = !!currencies[index]?.favorite;
    const previousFavorite = !!currencies[index - 1]?.favorite;

    return !currentFavorite && previousFavorite;
  }

  private filterCurrencyBalances(
    searchTerm: string,
    showFavoritesOnly: boolean,
    showWithAmountOnly: boolean,
    selectedFiatCurrency: SelectableCurrency,
  ): Observable<any> {
    return this.data$.pipe(
      map((data) => {
        return (data || [])
          .filter((currencyWithBalance) => {
            if (searchTerm) {
              const normalizedSearchTerm = normalizeText(searchTerm);
              const normalizedCurrencyName = normalizeText(currencyWithBalance.name);
              const normalizedDisplayName = normalizeText(currencyWithBalance.displayName);
              // CZK is only currency that is translated now. This allows user to search for it.
              const alternativeName = normalizeText(
                currencyWithBalance.displayName.replace('Czech crown', 'Česká koruna'),
              );

              return (
                normalizedCurrencyName.includes(normalizedSearchTerm) ||
                normalizedDisplayName.includes(normalizedSearchTerm) ||
                alternativeName.includes(normalizedSearchTerm)
              );
            }

            return true;
          })
          .filter((currencyWithBalance) => {
            const hasAmount = (currencyWithBalance.totalBalance || 0) > 0;

            if (showFavoritesOnly && showWithAmountOnly) {
              return currencyWithBalance.favorite || hasAmount;
            }

            if (showFavoritesOnly) {
              return currencyWithBalance.favorite;
            }

            if (showWithAmountOnly) {
              return hasAmount;
            }

            return true;
          })
          .sort((a, b) => {
            if (a.favorite && !b.favorite) return -1;
            if (!a.favorite && b.favorite) return 1;

            if (!a.virtual && b.virtual) return -1;
            if (a.virtual && !b.virtual) return 1;

            if (!a.virtual && !b.virtual) {
              if (a.name === selectedFiatCurrency) return -1;
              if (b.name === selectedFiatCurrency) return 1;
            }

            return (
              (b.fiatEquivalentsTotal[selectedFiatCurrency] ?? 0) - (a.fiatEquivalentsTotal[selectedFiatCurrency] ?? 0)
            );
          });
      }),
    );
  }
}
