/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InitialGoogleTwoFactorDto } from '../models/initial-google-two-factor-dto';
import { PasswordChangeDto } from '../models/password-change-dto';
import { SecuritySettingsChangeDto } from '../models/security-settings-change-dto';
import { SecuritySettingsDto } from '../models/security-settings-dto';
import { TrezorRegistrationDto } from '../models/trezor-registration-dto';

@Injectable({
  providedIn: 'root',
})
export class UserSecurityControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSecuritySettingsUsingGet
   */
  static readonly GetSecuritySettingsUsingGetPath = '/frontend/user/security';

  /**
   * Get user's security settings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecuritySettingsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecuritySettingsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SecuritySettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.GetSecuritySettingsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SecuritySettingsDto>;
      })
    );
  }

  /**
   * Get user's security settings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecuritySettingsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecuritySettingsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<SecuritySettingsDto> {

    return this.getSecuritySettingsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<SecuritySettingsDto>) => r.body as SecuritySettingsDto)
    );
  }

  /**
   * Path part for operation changeSecuritySettingsUsingPut
   */
  static readonly ChangeSecuritySettingsUsingPutPath = '/frontend/user/security';

  /**
   * Change user's security settings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeSecuritySettingsUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeSecuritySettingsUsingPut$Response(params: {
    body: SecuritySettingsChangeDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SecuritySettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.ChangeSecuritySettingsUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SecuritySettingsDto>;
      })
    );
  }

  /**
   * Change user's security settings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeSecuritySettingsUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeSecuritySettingsUsingPut(params: {
    body: SecuritySettingsChangeDto
  },
  context?: HttpContext

): Observable<SecuritySettingsDto> {

    return this.changeSecuritySettingsUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<SecuritySettingsDto>) => r.body as SecuritySettingsDto)
    );
  }

  /**
   * Path part for operation changePasswordUsingPut
   */
  static readonly ChangePasswordUsingPutPath = '/frontend/user/security/password';

  /**
   * Change user's password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePasswordUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswordUsingPut$Response(params: {
    body: PasswordChangeDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.ChangePasswordUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change user's password.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePasswordUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswordUsingPut(params: {
    body: PasswordChangeDto
  },
  context?: HttpContext

): Observable<void> {

    return this.changePasswordUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation registerTrezorUsingPost
   */
  static readonly RegisterTrezorUsingPostPath = '/frontend/user/security/register-trezor';

  /**
   * Register trezor to existing account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerTrezorUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerTrezorUsingPost$Response(params: {
    body: TrezorRegistrationDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.RegisterTrezorUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Register trezor to existing account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerTrezorUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerTrezorUsingPost(params: {
    body: TrezorRegistrationDto
  },
  context?: HttpContext

): Observable<void> {

    return this.registerTrezorUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sendOneTimeEmailCodeUsingPost
   */
  static readonly SendOneTimeEmailCodeUsingPostPath = '/frontend/user/security/email-2fa-code';

  /**
   * Generate a one-time 2FA code and send it to user's email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOneTimeEmailCodeUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendOneTimeEmailCodeUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.SendOneTimeEmailCodeUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Generate a one-time 2FA code and send it to user's email.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOneTimeEmailCodeUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendOneTimeEmailCodeUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.sendOneTimeEmailCodeUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getGoogle2FaInitialDataUsingGet
   */
  static readonly GetGoogle2FaInitialDataUsingGetPath = '/frontend/user/security/google-2fa-data';

  /**
   * Generate and return a new QR code and secret key, so the user can pair his Google Authenticator app.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoogle2FaInitialDataUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogle2FaInitialDataUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InitialGoogleTwoFactorDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.GetGoogle2FaInitialDataUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InitialGoogleTwoFactorDto>;
      })
    );
  }

  /**
   * Generate and return a new QR code and secret key, so the user can pair his Google Authenticator app.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGoogle2FaInitialDataUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogle2FaInitialDataUsingGet(params?: {
  },
  context?: HttpContext

): Observable<InitialGoogleTwoFactorDto> {

    return this.getGoogle2FaInitialDataUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<InitialGoogleTwoFactorDto>) => r.body as InitialGoogleTwoFactorDto)
    );
  }

  /**
   * Path part for operation unregisterTrezorUsingDelete
   */
  static readonly UnregisterTrezorUsingDeletePath = '/frontend/user/security/unregister-trezor';

  /**
   * Unregister trezor from current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unregisterTrezorUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  unregisterTrezorUsingDelete$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.UnregisterTrezorUsingDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Unregister trezor from current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unregisterTrezorUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unregisterTrezorUsingDelete(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.unregisterTrezorUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation invalidate2FaDataUsingDelete
   */
  static readonly Invalidate2FaDataUsingDeletePath = '/frontend/user/security/2fa-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invalidate2FaDataUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  invalidate2FaDataUsingDelete$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserSecurityControllerService.Invalidate2FaDataUsingDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invalidate2FaDataUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invalidate2FaDataUsingDelete(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.invalidate2FaDataUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
