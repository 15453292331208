/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AmlConfigDto } from '../models/aml-config-dto';
import { AmlFormDto } from '../models/aml-form-dto';
import { AmlRiskDto } from '../models/aml-risk-dto';
import { VerificationLevelDto } from '../models/verification-level-dto';

@Injectable({
  providedIn: 'root',
})
export class UserAmlControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAmlFormToEditUsingGet
   */
  static readonly GetAmlFormToEditUsingGetPath = '/frontend/user/aml/form';

  /**
   * Get logged user's AML form to edit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAmlFormToEditUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmlFormToEditUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AmlFormDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAmlControllerService.GetAmlFormToEditUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AmlFormDto>;
      })
    );
  }

  /**
   * Get logged user's AML form to edit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAmlFormToEditUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmlFormToEditUsingGet(params?: {
  },
  context?: HttpContext

): Observable<AmlFormDto> {

    return this.getAmlFormToEditUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AmlFormDto>) => r.body as AmlFormDto)
    );
  }

  /**
   * Path part for operation saveAmlFormUsingPost
   */
  static readonly SaveAmlFormUsingPostPath = '/frontend/user/aml/form';

  /**
   * Save logged user's AML form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAmlFormUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveAmlFormUsingPost$Response(params: {
    body: AmlFormDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserAmlControllerService.SaveAmlFormUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save logged user's AML form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveAmlFormUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveAmlFormUsingPost(params: {
    body: AmlFormDto
  },
  context?: HttpContext

): Observable<void> {

    return this.saveAmlFormUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRiskyAgeUsingGet
   */
  static readonly GetRiskyAgeUsingGetPath = '/frontend/user/aml/risk';

  /**
   * Get logged user's AML risk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRiskyAgeUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskyAgeUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AmlRiskDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAmlControllerService.GetRiskyAgeUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AmlRiskDto>;
      })
    );
  }

  /**
   * Get logged user's AML risk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRiskyAgeUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskyAgeUsingGet(params?: {
  },
  context?: HttpContext

): Observable<AmlRiskDto> {

    return this.getRiskyAgeUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AmlRiskDto>) => r.body as AmlRiskDto)
    );
  }

  /**
   * Path part for operation getPossibleVerificationLevelsOfUserUsingGet
   */
  static readonly GetPossibleVerificationLevelsOfUserUsingGetPath = '/frontend/user/aml/levels';

  /**
   * Get list of possible targeted verification levels for logged user including limits
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPossibleVerificationLevelsOfUserUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleVerificationLevelsOfUserUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VerificationLevelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAmlControllerService.GetPossibleVerificationLevelsOfUserUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerificationLevelDto>>;
      })
    );
  }

  /**
   * Get list of possible targeted verification levels for logged user including limits
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPossibleVerificationLevelsOfUserUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleVerificationLevelsOfUserUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<VerificationLevelDto>> {

    return this.getPossibleVerificationLevelsOfUserUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VerificationLevelDto>>) => r.body as Array<VerificationLevelDto>)
    );
  }

  /**
   * Path part for operation getAmlConfigUsingGet
   */
  static readonly GetAmlConfigUsingGetPath = '/frontend/user/aml/config';

  /**
   * Get logged user's AML configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAmlConfigUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmlConfigUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AmlConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAmlControllerService.GetAmlConfigUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AmlConfigDto>;
      })
    );
  }

  /**
   * Get logged user's AML configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAmlConfigUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmlConfigUsingGet(params?: {
  },
  context?: HttpContext

): Observable<AmlConfigDto> {

    return this.getAmlConfigUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AmlConfigDto>) => r.body as AmlConfigDto)
    );
  }

}
