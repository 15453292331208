import { Component, Input } from '@angular/core';
import { DropdownItemComponent } from '@app/shared/components/dropdown/base/dropdown-item/dropdown-item.component';

@Component({
  selector: '[menu-dropdown-item]',
  standalone: true,
  templateUrl: './menu-dropdown-item.component.html',
  host: {
    '[attr.data-dropdown-facet]': 'facet',
  },
})
export class MenuDropdownItemComponent extends DropdownItemComponent {
  @Input() facet?: 'danger' | undefined;

  protected baseClassName = 'menu-dropdown-item';
}
