<div class="table-wrapper {{ isMinimal && 'minimal' }}">
  <ng-container *ngIf="currencyData$ | async as currencyData">
    <table appTable [dataSource]="currencyData" class="w-full currencies-table">
      <caption caption class="sr-only">
        {{
          'currencies.table.caption' | translate
        }}
      </caption>

      <ng-container appColumnDef="icon">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td appCell *appCellDef="let data" class="desktop-only">
          <ng-container *ngIf="data; else skeletonLoaderColumnIcon">
            <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
              <img src="{{ data.imgSrc }}" alt="{{ data.name }}" />
            </a>
            <span *ngIf="lang === 'en'">
              <img src="{{ data.imgSrc }}" alt="{{ data.name }}" />
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="name">
        <th appHeaderCell *appHeaderCellDef>{{ 'currencies.table.header.currency' | translate }}</th>
        <td appCell *appCellDef="let data">
          <ng-container *ngIf="data; else skeletonLoaderColumnName">
            <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
              <img
                class="!inline-block relative top-[-12px]"
                [ngClass]="{
                  'md:!hidden': !isMinimal
                }"
                src="{{ data.imgSrc }}"
                alt="{{ data.name }}"
              />
              <span class="inline-block relative md:top-0 {{ !isMinimal && 'md:ml-0' }} ml-[12px] top-[3px]">
                <strong class="hover:underline">{{ data.name }}</strong>
                <span class="block w-full text-[14px] font-normal" [ngClass]="{ 'md:!hidden': !isMinimal }">{{
                  data.code
                }}</span>
              </span>
            </a>
            <span *ngIf="lang === 'en'">
              <img
                class="!inline-block relative top-[-12px]"
                [ngClass]="{ 'md:!hidden': !isMinimal }"
                src="{{ data.imgSrc }}"
                alt="{{ data.name }}"
              />
              <span class="inline-block relative md:top-0 ml-[12px] top-[3px]" [ngClass]="{ 'md:ml-0': !isMinimal }">
                <strong>{{ data.name }}</strong>
                <span class="block w-full text-[14px] font-normal" [ngClass]="{ 'md:!hidden': !isMinimal }">{{
                  data.code
                }}</span>
              </span>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="currency">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td appCell *appCellDef="let data" class="desktop-only font-normal !text-[14px]">
          <ng-container *ngIf="data; else skeletonLoaderColumnCurrency">
            <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
              {{ data.code }}
            </a>
            <span *ngIf="lang === 'en'">
              {{ data.code }}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="price">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">
          <span>{{ 'currencies.table.header.price' | translate }}</span>
          <form class="!inline-block ml-[10px]">
            <label for="currency" class="sr-only">{{ 'currencies.table.header.price' | translate }}</label>
            <select
              name="currency"
              id="currency"
              class="cm-currencies-select"
              (change)="onChangeCurrency($event)"
              [value]="selectedCurrency$ | async"
            >
              <option value="CZK">{{ 'currencies.units.CZK' | translate }}</option>
              <option value="EUR">{{ 'currencies.units.EUR' | translate }}</option>
            </select>
          </form>
        </th>
        <td appCell *appCellDef="let data" class="desktop-only">
          <ng-container *ngIf="data; else skeletonLoaderColumnPrice">
            {{ data.price | appIsCurrencyLowerPipe: data.priceFormat }}
            {{ data.priceCurrency }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="change">
        <th appHeaderCell *appHeaderCellDef class="mobile-last">
          <span>{{ 'currencies.table.header.change' | translate }}</span>
          <form class="!inline-block md:!hidden ml-[20px]">
            <label for="currency-mobile" class="sr-only">{{ 'currencies.table.header.price' | translate }}</label>
            <select
              name="currency"
              id="currency-mobile"
              class="cm-currencies-select no-bg"
              (change)="onChangeCurrency($event)"
              [value]="selectedCurrency$ | async"
            >
              <option value="CZK">{{ 'currencies.units.CZK' | translate }}</option>
              <option value="EUR">{{ 'currencies.units.EUR' | translate }}</option>
            </select>
          </form>
        </th>
        <td appCell *appCellDef="let data" class="mobile-last min-w-[140px]_">
          <ng-container *ngIf="data; else skeletonLoaderColumnChange">
            <strong class="block w-full !text-right" [ngClass]="{ 'md:!hidden': !isMinimal }">
              {{ data.price | appIsCurrencyLowerPipe: data.priceFormat }} {{ data.priceCurrency }}
            </strong>
            <app-colored-percent-change-text
              class="{{
                isMinimal && '!text-right'
              }} block font-bold text-[14px] lg:text-[16px] text-right md:text-left"
              [change]="data.change"
            ></app-colored-percent-change-text>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="buy">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td
          appCell
          *appCellDef="let data"
          class="text-right desktop-only minimal-show"
          [ngClass]="{
            'w-[55px]': isMinimal,
            'w-[80px]': !isMinimal
          }"
        >
          <ng-container *ngIf="data; else skeletonLoaderButtonBuy">
            <app-logged-button
              appearance="cm-currency-buy"
              text="currencies.table.purchase"
              addressHref="/quick-trade"
              link="/sign-up"
            >
              <img
                src="/assets/img/arrow-right-blue.svg"
                alt="{{ 'currencies.table.purchase' | translate }}"
                *ngIf="!isMinimal"
              />
            </app-logged-button>
          </ng-container>
        </td>
      </ng-container>

      <tr appHeaderRow *appHeaderRowDef="displayedColumns"></tr>
      <tr appRow *appRowDef="let data; columns: displayedColumns"></tr>
    </table>

    <ng-container *ngIf="isMinimal">
      <ng-container *ngIf="!isDataEmpty(currencyData); else skeletonLoaderButtonMore">
        <app-link-pair
          routerLink="/prices"
          addressLabel="{{ 'currencies.more' | translate }}"
          addressIcon="/assets/img/arrow-right-blue.svg"
          appearance="cm-paragraph !mb-0 mt-[16px]"
        ></app-link-pair>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #skeletonLoaderColumnIcon>
    <div class="skeleton-loader-column-icon">
      <app-skeleton class="" width="25px" height="25px" variant="circular"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnPrice>
    <div class="skeleton-loader-column-price">
      <app-skeleton></app-skeleton>
      <app-skeleton *ngIf="isMinimal"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnChange>
    <div class="skeleton-loader-column-change">
      <app-skeleton></app-skeleton>
      <app-skeleton [ngClass]="{ 'md:!hidden': !isMinimal }"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnName>
    <div class="skeleton-loader-column-name">
      <div [ngClass]="{ 'md:!hidden': !isMinimal }">
        <app-skeleton width="25px" height="25px" variant="circular"></app-skeleton>
      </div>
      <div class="skeleton-loader-column-name-items">
        <app-skeleton></app-skeleton>
        <app-skeleton [ngClass]="{ 'md:!hidden': !isMinimal }"></app-skeleton>
      </div>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnCurrency>
    <div class="skeleton-loader-column-currency">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderButtonBuy>
    <div class="skeleton-loader-button-buy">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderButtonMore>
    <div class="skeleton-loader-button-more">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>
</div>
