import { NgModule } from '@angular/core';

import { BaseModalComponent } from '@app/shared/components/modal/base-modal/base-modal.component';
import { BaseModalDirective } from '@app/shared/components/modal/base-modal/base-modal.directive';
import { BaseModalBodyComponent } from '@app/shared/components/modal/base-modal/components/base-modal-body/base-modal-body.component';
import { BaseModalFooterComponent } from '@app/shared/components/modal/base-modal/components/base-modal-footer/base-modal-footer.component';

@NgModule({
  declarations: [],
  imports: [BaseModalComponent, BaseModalDirective, BaseModalBodyComponent, BaseModalFooterComponent],
  exports: [BaseModalComponent, BaseModalDirective, BaseModalBodyComponent, BaseModalFooterComponent],
})
export class BaseModalModule {}
