import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { floor } from '@app-shared/pipes/app-floor.pipe';
import { floorPrice } from '@app-shared/pipes/app-floor-price-observable.pipe';
import { CurrencyPairs } from '@app-shared/store/currency-pairs/currency-pairs-facade.service';

@Pipe({
  name: 'appFloorPrice',
})
export class AppFloorPricePipe implements PipeTransform {
  constructor(private currencyPairsService: CurrencyPairs) {}

  transform(amount: number, currencyPairName: string, thousandSeparator: string = ' '): Observable<string> {
    return this.currencyPairsService.currencyPairs$.pipe(
      map((currencyPairs) => {
        return floorPrice(amount, currencyPairName, currencyPairs, thousandSeparator);
      }),
      catchError(() => of(floor(amount, 8, thousandSeparator))),
    );
  }
}
