import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  AttachmentDoesNotExistExceptionAdditionalInfo,
  DuplicateKeyAdditionalInfo,
  EmptyAdditionalInfo,
  InvalidArgumentAdditionalInfo,
  ItemIdAdditionalInfo,
  LevelAlertInvalidPriceAdditionalInfo,
  LimitReachedAdditionalInfo,
  MinPercentNumberAdditionalInfo,
} from '../api/error.api';
import { TranslateService } from '@ngx-translate/core';
import { capitalizeFirstLetter } from '@app-shared/util';
import { GuiParams } from '@app-shared/store/gui-params/gui-params-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * "handle[.*]Exception" methods are called dynamically, so IDEs might show they are unused.
 *
 * If you want to handle exception in a service/component/etc. directly, create an
 * empty handler here so you don't get the unknown error toast notification. Then you can subscribe
 * to the observable directly in the service (/component/etc.) as you normally world and handle
 * errors there to your liking.
 */
@Injectable({
  providedIn: 'root',
})
export class BadRequestErrorHandlerService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private guiParamsService: GuiParams,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public handleMaxNumberOfAlertsException(additionalInfo: LimitReachedAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.only-n-alerts-allowed', { value: additionalInfo.limit }),
      this.translate.instant('error.maximum-alerts-reached'),
    );
  }

  public handleMaxNumberOfAccountsException(additionalInfo: LimitReachedAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.only-n-accounts-allowed', { value: additionalInfo.limit }),
      this.translate.instant('error.maximum-accounts-reached'),
    );
  }

  public handleLevelAlertInvalidPriceException(additionalInfo: LevelAlertInvalidPriceAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.current-price-alert-price', {
        currPrice: additionalInfo.currentPrice,
        alertPrice: additionalInfo.alertPrice,
      }),
      this.translate.instant('error.invalid-alert-price'),
    );
  }

  public handleMinPercentNumberException(additionalInfo: MinPercentNumberAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.percentage-below-minimum', {
        value: additionalInfo.minPercentNumber,
        valuePercent: additionalInfo.minPercentNumber * 100,
      }),
      this.translate.instant('error.percentage-invalid'),
    );
  }

  public handleInsufficientBalanceException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.insufficient-balance'));
  }

  public handleUserIdDecryptionException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.decrypting-unique-key'));
  }

  public handleInvalidArgumentException(additionalInfo: InvalidArgumentAdditionalInfo) {
    if (additionalInfo.fieldName && additionalInfo.fieldName.length > 0) {
      this.toastr.error(
        this.translate.instant('error.n-not-valid', {
          value: additionalInfo.fieldName,
        }),
        this.translate.instant('error.invalid-argument'),
      );
    } else {
      this.toastr.error(
        this.translate.instant('error.request-invalid', {
          value: additionalInfo.fieldName,
        }),
        this.translate.instant('error.invalid-argument'),
      );
    }
  }

  public handleMethodArgumentNotValidException(additionalInfo: any) {
    let violations = '';
    for (const prop in additionalInfo) {
      // skip loop if the property is from prototype
      if (!additionalInfo.hasOwnProperty(prop)) {
        continue;
      }
      const additionalInfoTranslated = this.translate.instant(additionalInfo[prop]);
      const propTranslated = this.translate.instant(prop);

      violations += `${propTranslated}: ${additionalInfoTranslated}<br />`;
    }
    this.toastr.error(violations, this.translate.instant('error.form-validation-error'));
  }

  public handleDuplicateKeyException(additionalInfo: DuplicateKeyAdditionalInfo) {
    console.log(additionalInfo);
    this.toastr.error(
      this.translate.instant('error.n-with-n-exists', {
        entityName:
          this.translate.instant('error.' + additionalInfo.entityName) ||
          capitalizeFirstLetter(additionalInfo.entityName),
        propertyName: this.translate.instant('error.' + additionalInfo.propertyName) || additionalInfo.propertyName,
      }),
      this.translate.instant('error.duplicate-item'),
    );
  }

  public handleAccountDuplicateNameException() {
    this.toastr.error(
      this.translate.instant('error.account-name-used'),
      this.translate.instant('error.duplicate-name'),
    );
  }

  public handleItemNotFoundException(additionalInfo: ItemIdAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.item-with-id-doesnt-exist', {
        value: additionalInfo.itemId,
      }),
      this.translate.instant('error.item-not-found'),
    );
  }

  public handlePermissionDeniedException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(
      this.translate.instant('error.no-permissions-request'),
      this.translate.instant('error.forbidden'),
    );
  }

  public handleTokenExpiredException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.token-expired'));
  }

  public handlePreviousCodeStillValidException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.previous-code-still-valid'));
  }

  public handleResetPasswordException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.resetting-password'));
  }

  public handleResetPasswordEmailSendException(additionalInfo: EmptyAdditionalInfo) {
    const url =
      this.translate.currentLang !== this.translate.defaultLang ? `/${this.translate.currentLang}/support` : '/support';
    this.toastr.error(
      `${this.translate.instant('error.process-password-recovery')} <a href="${url}">${this.translate.instant(
        'lostPassword.support',
      )}</a>.`,
    );
  }

  public handleAccountActivationException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.activating-account'));
  }

  public handleForbiddenCountryException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.country-not-allowed'));
  }

  public handleCurrentPasswordInvalidException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.password-doesnt-match'));
  }

  public handleEmailChangeAlreadyTakenException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.email-address-already-taken'));
  }

  public handleOldPasswordEqualsNewPasswordException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.old-password-equals-new-password'));
  }

  public handleAttachmentDoesNotExistException(additionalInfo: AttachmentDoesNotExistExceptionAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.attachment-does-not-exist'));
  }

  public handleInvalidFileExtensionException() {
    this.toastr.error(this.translate.instant('error.invalid-file-extension'));
  }

  public handleFileSizeExceededException() {
    this.toastr.error(this.translate.instant('error.file-size-exceeded'));
  }

  public handleInvalidTwoFactorAuthenticationException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.correct-2fa-data'), this.translate.instant('error.2fa-failure'));
  }

  public handleTrezorIsNotTheOneRegisteredException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.not-the-registered'));
  }

  public handleTwoFactorChangeValidationException(additionalInfo: EmptyAdditionalInfo) {
    // do nothing here (gets handled in the component directly)
  }

  public handleInvalidPrecisionException() {
    this.toastr.error(this.translate.instant('error.invalid-precision'));
  }

  public handleUserDoesNotHavePasswordException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.no-specified-password'));
  }

  public handleTrezorAlreadyUsedException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.already-used'));
  }

  public handleUserAlreadyHasTrezorException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.user-already-has'));
  }

  public handleTrezorSignatureInvalidException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.signature-invalid'));
  }

  public handleTrezorMissingSecretException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.missing-secret'));
  }

  public handleUserUsesTrezor2FAException(additionalInfo: EmptyAdditionalInfo) {
    this.toastr.error(this.translate.instant('error.trezor.used-2fa'));
  }

  public handleSMSVerificationCodeExpiredException() {
    this.toastr.error(this.translate.instant('error.sms-code-expired'));
  }

  public handleInvalidSMSVerificationCodeException() {
    this.toastr.error(this.translate.instant('error.invalid-sms-code'));
  }

  public handleSMSSendException() {
    this.toastr.error(this.translate.instant('error.sms'));
  }

  public handleMaxNumberOfVerificationSMSExceededException() {
    this.toastr.error(this.translate.instant('error.maximum-sms'));
  }

  public handleInvalidPhoneNumberException() {
    this.toastr.error(this.translate.instant('error.invalid-phone'));
  }

  public handleInvalidGAResetCredentialsException() {
    this.toastr.error(this.translate.instant('error.ga.reset-credentials'));
  }

  public handleGAResetMaxCountExceededException() {
    this.toastr.error(this.translate.instant('error.ga.max-count'));
  }

  public handleInvalidGAResetConfirmCodesException() {
    this.toastr.error(
      this.translate.instant('login.2fa.disable.deactivation-error-text'),
      this.translate.instant('login.2fa.disable.deactivation-error-title'),
    );
  }

  public handleUserNotVerifiedForGAResetException() {
    this.guiParamsService.guiParams$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (params) => {
        let supportUrl: string;
        if (!params.todoRemoveThisUnholyPieceOfGarbageParamWhetherUserIsLoggedIn) {
          supportUrl =
            this.translate.currentLang !== this.translate.defaultLang
              ? `/${this.translate.currentLang}/support`
              : '/support';
        } else {
          supportUrl = '/customer-support';
        }
        this.toastr.error(
          `${this.translate.instant('error.ga.recovery')} <a href="${supportUrl}">${this.translate.instant(
            'lostPassword.support',
          )}</a>.`,
        );
      },
      error: () => {
        // TODO DTP-5035
      },
    });
  }

  public handleInvalidPasswordResetCredentialsException() {
    this.toastr.error(this.translate.instant('error.password.credentials'));
  }

  public handlePasswordResetNeedsSmsCode() {
    // do nothing here (gets handled in the component directly)
  }

  public handlePasswordResetMaxCountExceededException() {
    this.toastr.error(this.translate.instant('error.password.max-count'));
  }

  public handleTooManyDocumentsUploadedException() {
    this.toastr.error(this.translate.instant('error.max-documents'));
  }
}
