import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownPopoverService implements OnDestroy {
  private activeDropdownIdSubject = new BehaviorSubject<string | null>(null);
  activeDropdownId$: Observable<string | null> = this.activeDropdownIdSubject.asObservable();

  ngOnDestroy() {
    this.activeDropdownIdSubject.next(null);
  }

  open(dropdownId: string) {
    this.activeDropdownIdSubject.next(dropdownId);
  }

  close() {
    this.activeDropdownIdSubject.next(null);
  }
}
