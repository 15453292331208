/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class OpenGuiWebsocketChannelsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation openTradesChannelUsingPost
   */
  static readonly OpenTradesChannelUsingPostPath = '/frontend/websocket/channel/trades/{currencyPair}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openTradesChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openTradesChannelUsingPost$Response(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OpenGuiWebsocketChannelsControllerService.OpenTradesChannelUsingPostPath, 'post');
    if (params) {
      rb.path('currencyPair', params.currencyPair, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openTradesChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openTradesChannelUsingPost(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openTradesChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openTradeStatsChannelUsingPost
   */
  static readonly OpenTradeStatsChannelUsingPostPath = '/frontend/websocket/channel/trade-stats/{currencyPair}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openTradeStatsChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openTradeStatsChannelUsingPost$Response(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OpenGuiWebsocketChannelsControllerService.OpenTradeStatsChannelUsingPostPath, 'post');
    if (params) {
      rb.path('currencyPair', params.currencyPair, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openTradeStatsChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openTradeStatsChannelUsingPost(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openTradeStatsChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openOrderBookChannelUsingPost
   */
  static readonly OpenOrderBookChannelUsingPostPath = '/frontend/websocket/channel/order-book/{currencyPair}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openOrderBookChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openOrderBookChannelUsingPost$Response(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OpenGuiWebsocketChannelsControllerService.OpenOrderBookChannelUsingPostPath, 'post');
    if (params) {
      rb.path('currencyPair', params.currencyPair, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openOrderBookChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openOrderBookChannelUsingPost(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openOrderBookChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openCurrencyPairStatsChannelUsingPost
   */
  static readonly OpenCurrencyPairStatsChannelUsingPostPath = '/frontend/websocket/channel/currency-pair-stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openCurrencyPairStatsChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openCurrencyPairStatsChannelUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OpenGuiWebsocketChannelsControllerService.OpenCurrencyPairStatsChannelUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openCurrencyPairStatsChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openCurrencyPairStatsChannelUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openCurrencyPairStatsChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
