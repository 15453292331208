import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractComponent } from '@app/shared/components/form/abstract/abstract.component';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends AbstractComponent implements OnInit {
  @Input() checked = false;
  @Input() falseLabel?: string;
  @Input() trueLabel?: string;
  @Input() isFullWidth?: boolean = false;

  ngOnInit(): void {}
}
