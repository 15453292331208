import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent {
  @Output() otpCompleted = new EventEmitter<string>();
  @ViewChild(CodeInputComponent) codeInputComponent!: CodeInputComponent;
  otp = '';

  handleCodeCompleted(code: string) {
    this.otpCompleted.emit(code);
  }

  resetCodeInput() {
    this.codeInputComponent.reset();
  }

  focusOnField(index: number) {
    this.codeInputComponent.focusOnField(index);
  }
}
