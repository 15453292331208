import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalizedRouterService } from '@app/shared/services/localized-router.service';

@Component({
  selector: 'a[menu-vertical-item]',
  templateUrl: './menu-vertical-item.component.html',
  styleUrls: ['../menu-vertical.component.scss'],
  host: {
    '[class.menu-vertical-item]': 'true',
    '[class.menu-vertical-item-active]': 'isActive()',
    '[class]': 'facet ? "menu-vertical-item-" + facet : ""',
  },
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class MenuVerticalItemAnchorComponent {
  @Input() href?: string;
  @Input() routerLink?: any;
  @Input() facet?: 'secondary' | 'tertiary' | null;

  constructor(public router: LocalizedRouterService) {}

  protected isActive() {
    const url = this.routerLink || this.href;
    const routerUrl = this.router.url.split(/[#!?]/g)[0];
    return routerUrl.endsWith(url);
  }
}

@Component({
  selector: 'button[menu-vertical-item]',
  templateUrl: './menu-vertical-item.component.html',
  styleUrls: ['../menu-vertical.component.scss'],
  host: {
    '[class.menu-vertical-item]': 'true',
    '[class]': 'facet ? "menu-vertical-item-" + facet : ""',
  },
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class MenuVerticalItemButtonComponent {
  @Input() facet?: 'secondary' | 'tertiary' | 'link' | null;
}
