<div class="currency-pair currency-pair--{{ type }}">
  <img src="{{ pairIcon() }}" class="currency-pair__crypto-icon" />
  <app-tooltip
    text="{{ currencyPair.firstCurrency | translate }} / {{ currencyPair.secondCurrency | translate }}"
    [usePopover]="false"
  >
    <button class="currency-pair__crypto-name" (click)="changeSelectedPair(currencyPair)">
      {{ currencyPair.name | appCurrencyPairDisplay | async }}
    </button>
  </app-tooltip>

  <span class="currency-pair__crypto-price">
    {{ (currencyPair.lastPrice | appFloorPrice: currencyPair.name | async) ?? undefined | appCutZeros }}
  </span>

  <span
    class="currency-pair__crypto-change"
    [ngClass]="{
      'currency-pair__crypto-change--plus': currencyPair.changeIn24Hours > 0,
      'currency-pair__crypto-change--minus': currencyPair.changeIn24Hours < 0
    }"
  >
    {{ (currencyPair.changeIn24Hours / 100 | percent: '.2-2') || 'N/A' }}
  </span>

  <app-tooltip [text]="getButtonTooltip()" [usePopover]="false">
    <button *ngIf="isButtonDisplayed" class="currency-pair__add-button" (click)="buttonAction(currencyPair)">
      <img src="{{ buttonIcon }}" width="18" height="18" class="currency-pair__theme-icon" />
      <img src="{{ buttonIconDark }}" width="18" height="18" class="currency-pair__theme-icon--dark" />
    </button>
  </app-tooltip>
</div>
