import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-modal-dialog',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input() modalId = '';
  @Input() modalData: {
    modalTitle?: string;
    modalBody?: string;
    confirmLabel?: string;
    cancelLabel?: string;
  } = {
    modalTitle: '',
  };
  @Input() modalActions: {
    confirm: () => void;
    cancel: () => void;
    close?: () => void;
  } = {
    confirm: () => {},
    cancel: () => {},
  };

  onConfirm() {
    this.modalActions.confirm();
  }

  onCancel() {
    this.modalActions.cancel();
  }

  onClose() {
    this.modalActions.close?.();
  }
}
