import { Directive, forwardRef, Input } from '@angular/core';
import { DropdownTriggerButtonDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger-button.directive';
import { DropdownTriggerDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger.directive';

@Directive({
  selector: '[menu-dropdown-trigger]',
  standalone: true,
  host: {
    '[attr.data-dropdown-chevron-mobile]': 'withChevronMobile ? "" : undefined',
    '[attr.data-dropdown-inactive]': 'inactive ? "" : undefined',
  },
  providers: [{ provide: DropdownTriggerDirective, useExisting: forwardRef(() => MenuDropdownTriggerDirective) }],
})
export class MenuDropdownTriggerDirective extends DropdownTriggerButtonDirective {
  @Input() inactive?: boolean;
  @Input() withChevron?: boolean = false;
  @Input() withChevronMobile?: boolean = false;

  protected baseClassName = 'menu-dropdown-trigger';
}
