import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CountryDto } from '@app/generated/models/country-dto';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CountryCode, isSupportedCountry, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.css'],
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {
  @Output() selectedPhoneNumber = new EventEmitter<string>();
  @Input() showLabel = true;

  @ViewChild('dropdown') dropdown: ElementRef | undefined;
  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;

  showDropdown = false;
  selectedCountry?: CountryDto;
  countries: CountryDto[] = [];
  filteredCountries: CountryDto[] = [];
  userEnteredNumber = '';
  validationMessage = '';
  validationMessageType: 'error' | 'info' | 'none' = 'none';
  private unsubscribe$ = new Subject<void>();
  /* eslint-disable */
  private dictErrorToMessage = {
    NOT_A_NUMBER: 'phone-number-message-only-numbers',
    INVALID_COUNTRY: 'phone-number-message-select-country',
    TOO_SHORT: 'phone-number-message-too-short',
    INVALID_LENGTH: 'phone-number-message-too-short',
    TOO_LONG: 'phone-number-message-too-long',
  };
  /* eslint-enable */
  private _value = '';

  constructor(private countryService: CountryService, private translate: TranslateService) {}

  get dropdownHeight(): string {
    const rowHeight = 42;
    const numberOfRows = Math.min(3, this.filteredCountries.length);

    return `${numberOfRows * rowHeight}px`;
  }

  get value(): string {
    return this._value;
  }
  @Input()
  set value(value: string) {
    if (!value.startsWith('+')) {
      value = '+' + value;
    }
    if (!/^\+?\d+$/.test(value)) {
      this._value = '';
      return;
    }

    this._value = value;
    this.processPendingPhoneNumber();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      this.showDropdown &&
      !this.dropdown?.nativeElement.contains(event.target) &&
      !this.toggleButton?.nativeElement.contains(event.target)
    ) {
      this.showDropdown = false;
    }
  }

  ngOnInit(): void {
    this.countryService
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (countries: CountryDto[]) => {
          this.countries = countries;
          this.filteredCountries = this.countries;
          this.selectCountry(this.countries.find((country) => country.code === 'CZ') || this.countries[0]);
          this.processPendingPhoneNumber();
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onPhoneNumberChange(): void {
    this.validatePhoneNumber();
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  getFlagUrl(countryCode: string | undefined): string {
    if (!countryCode) {
      return '/assets/phone-flags/placeholder.svg';
    }
    return `/assets/phone-flags/${countryCode?.toLowerCase()}.svg`;
  }

  selectCountry(country: any): void {
    this.selectedCountry = country;
    this.showDropdown = false;
    this.filteredCountries = [...this.countries];
    this.reorderCountries();
    if (this.userEnteredNumber.length > 0) {
      this.validatePhoneNumber();
    }
  }

  onSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    const lowerCaseSearchTerm = this.removeDiacritics(searchTerm.toLowerCase());

    this.filteredCountries = this.countries.filter((country) => {
      const matchDialingCode = country.dialingCode.includes(searchTerm);
      const matchName = this.removeDiacritics(country.name.toLowerCase()).includes(lowerCaseSearchTerm);

      return matchDialingCode || matchName;
    });

    this.reorderCountries();
  }

  onInputBlur(): void {
    this.validatePhoneNumber();
  }

  private validatePhoneNumber(): void {
    if (!this.selectedCountry || !this.selectedCountry.code) {
      return;
    }

    const countryCode = this.selectedCountry?.code as CountryCode;

    this.validationMessageType = 'none';
    this.selectedPhoneNumber.emit(undefined);

    if (!isSupportedCountry(countryCode)) {
      this.selectedPhoneNumber.emit(this.selectedCountry.dialingCode + this.userEnteredNumber);
      return;
    }

    if (this.userEnteredNumber.length === 0) {
      this.setValidationMessage('error', 'phone-number-message-empty');
      return;
    }
    const validationResult = validatePhoneNumberLength(this.userEnteredNumber, countryCode);
    if (validationResult) {
      const errorMessage = this.dictErrorToMessage[validationResult] ?? 'error.unknown';
      this.setValidationMessage('error', errorMessage);
      return;
    }

    this.selectedPhoneNumber.emit(this.selectedCountry.dialingCode + this.userEnteredNumber);
    const parsedNumber = parsePhoneNumber(this.userEnteredNumber, countryCode);
    if (parsedNumber.isValid()) {
      this.setValidationMessage('none', '');
    } else {
      this.setValidationMessage('info', 'phone-number-message');
    }
  }

  private setValidationMessage(type: 'none' | 'info' | 'error', messageKey: string): void {
    this.validationMessageType = type;
    this.validationMessage = messageKey ? this.translate.instant(messageKey) : '';
  }

  private removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private reorderCountries(): void {
    if (!this.selectedCountry) {
      return;
    }

    if (!this.filteredCountries.includes(this.selectedCountry)) {
      this.filteredCountries.unshift(this.selectedCountry);
    } else {
      const index = this.filteredCountries.indexOf(this.selectedCountry);
      if (index > 0) {
        this.filteredCountries.splice(index, 1);
        this.filteredCountries.unshift(this.selectedCountry);
      }
    }
  }

  private processPendingPhoneNumber(): void {
    if (this._value && this.countries.length > 0) {
      const matchedCountry = this.countries.find((country) => this._value?.startsWith(country.dialingCode));
      if (matchedCountry) {
        this.selectedCountry = matchedCountry;
        this.reorderCountries();
        this.userEnteredNumber = this._value.replace(matchedCountry.dialingCode, '');
      } else {
        this._value = '';
      }
    }
  }
}
