import { Component, Input, OnDestroy } from '@angular/core';
import { FooterColumn, FooterLink } from '@app/shared/api/footer.api';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
})
export class FooterLinkComponent implements OnDestroy {
  @Input() columnData!: FooterColumn;

  lang = '';

  private unsubscribe$ = new Subject<void>();

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => (this.lang = val.lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHref(footerLink: FooterLink): string | null {
    if (footerLink?.lang?.length && !footerLink?.lang?.includes(this.lang)) {
      return null;
    }
    return footerLink.link;
  }

  getRouterLink(footerLink: FooterLink): string | null {
    return footerLink.routerLink;
  }
}
