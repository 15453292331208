/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IdentityCheckDto } from '../models/identity-check-dto';
import { ValidLocale } from '../models/valid-locale';

@Injectable({
  providedIn: 'root',
})
export class IdentityCheckControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getIdentityCheckKeyStatusUsingGet
   */
  static readonly GetIdentityCheckKeyStatusUsingGetPath = '/frontend/user/idcheck/status';

  /**
   * Gets current status of identity check process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdentityCheckKeyStatusUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentityCheckKeyStatusUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IdentityCheckDto>> {

    const rb = new RequestBuilder(this.rootUrl, IdentityCheckControllerService.GetIdentityCheckKeyStatusUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IdentityCheckDto>;
      })
    );
  }

  /**
   * Gets current status of identity check process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIdentityCheckKeyStatusUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentityCheckKeyStatusUsingGet(params?: {
  },
  context?: HttpContext

): Observable<IdentityCheckDto> {

    return this.getIdentityCheckKeyStatusUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<IdentityCheckDto>) => r.body as IdentityCheckDto)
    );
  }

  /**
   * Path part for operation initializeIdenfySessionUsingGet
   */
  static readonly InitializeIdenfySessionUsingGetPath = '/frontend/user/idcheck/idenfy/init';

  /**
   * Requests for identity check URL for current User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initializeIdenfySessionUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  initializeIdenfySessionUsingGet$Response(params: {
    redirectUrl: string;
    locale: ValidLocale;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, IdentityCheckControllerService.InitializeIdenfySessionUsingGetPath, 'get');
    if (params) {
      rb.query('redirectUrl', params.redirectUrl, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Requests for identity check URL for current User
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initializeIdenfySessionUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  initializeIdenfySessionUsingGet(params: {
    redirectUrl: string;
    locale: ValidLocale;
  },
  context?: HttpContext

): Observable<string> {

    return this.initializeIdenfySessionUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
