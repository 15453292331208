<section
  class="double-section-page"
  [ngClass]="{
    'double-section-page-narrower': narrower,
    'double-section-page-center': contentAlignment === 'center',
  }"
>
  <div class="double-section-page-container">
    <div class="double-section-page-column">
      <ng-content select="[intro-content]"></ng-content>
    </div>
    <div class="double-section-page-or" *ngIf="showMiddlePageDivider">
      <span class="double-section-page-or-line">&nbsp;</span>
      <span class="double-section-page-or-text">{{ 'login.page-separation-or' | translate }}</span>
      <span class="double-section-page-or-line">&nbsp;</span>
    </div>
    <div class="double-section-page-column">
      <ng-content select="[payload-content-icon]"></ng-content>
      <ng-content select="[payload-content]"></ng-content>
    </div>

    <div *ngIf="showMiddlePageDivider" class="double-section-page-bg-center">
      <img src="/assets/img/bg-center.svg" class="block" alt="" />
    </div>
  </div>
</section>
