/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyPairWithStatsDto } from '../models/currency-pair-with-stats-dto';
import { QuickTradeCreateOfferRequestDto } from '../models/quick-trade-create-offer-request-dto';
import { QuickTradeCreateOfferResult } from '../models/quick-trade-create-offer-result';
import { QuickTradeCreateOrderRequestDto } from '../models/quick-trade-create-order-request-dto';
import { QuickTradePaymentMethodsDto } from '../models/quick-trade-payment-methods-dto';
import { QuickTradeResultDto } from '../models/quick-trade-result-dto';

@Injectable({
  providedIn: 'root',
})
export class QuickTradeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation executeOrderUsingOfferIdUsingPost
   */
  static readonly ExecuteOrderUsingOfferIdUsingPostPath = '/frontend/user/quicktrade/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeOrderUsingOfferIdUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOrderUsingOfferIdUsingPost$Response(params: {
    body: QuickTradeCreateOrderRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.ExecuteOrderUsingOfferIdUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeOrderUsingOfferIdUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOrderUsingOfferIdUsingPost(params: {
    body: QuickTradeCreateOrderRequestDto
  },
  context?: HttpContext

): Observable<QuickTradeResultDto> {

    return this.executeOrderUsingOfferIdUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeResultDto>) => r.body as QuickTradeResultDto)
    );
  }

  /**
   * Path part for operation validateAndCreateOfferUsingPost
   */
  static readonly ValidateAndCreateOfferUsingPostPath = '/frontend/user/quicktrade/order/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateAndCreateOfferUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAndCreateOfferUsingPost$Response(params: {
    body: QuickTradeCreateOfferRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeCreateOfferResult>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.ValidateAndCreateOfferUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeCreateOfferResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateAndCreateOfferUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAndCreateOfferUsingPost(params: {
    body: QuickTradeCreateOfferRequestDto
  },
  context?: HttpContext

): Observable<QuickTradeCreateOfferResult> {

    return this.validateAndCreateOfferUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeCreateOfferResult>) => r.body as QuickTradeCreateOfferResult)
    );
  }

  /**
   * Path part for operation getPaymentMethodsUsingGet
   */
  static readonly GetPaymentMethodsUsingGetPath = '/frontend/user/quicktrade/payment-methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodsUsingGet$Response(params: {
    currencyName: string;
    instrumentName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradePaymentMethodsDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetPaymentMethodsUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
      rb.query('instrumentName', params.instrumentName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradePaymentMethodsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodsUsingGet(params: {
    currencyName: string;
    instrumentName: string;
  },
  context?: HttpContext

): Observable<QuickTradePaymentMethodsDto> {

    return this.getPaymentMethodsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradePaymentMethodsDto>) => r.body as QuickTradePaymentMethodsDto)
    );
  }

  /**
   * Path part for operation getOrderStateUsingGet
   */
  static readonly GetOrderStateUsingGetPath = '/frontend/user/quicktrade/order/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderStateUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStateUsingGet$Response(params: {
    txId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetOrderStateUsingGetPath, 'get');
    if (params) {
      rb.query('txId', params.txId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderStateUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStateUsingGet(params: {
    txId: string;
  },
  context?: HttpContext

): Observable<QuickTradeResultDto> {

    return this.getOrderStateUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeResultDto>) => r.body as QuickTradeResultDto)
    );
  }

  /**
   * Path part for operation getQuickTradeCurrencyPairsWithStatsUsingGet
   */
  static readonly GetQuickTradeCurrencyPairsWithStatsUsingGetPath = '/frontend/user/quicktrade/currency-pairs/stats';

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuickTradeCurrencyPairsWithStatsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsWithStatsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairWithStatsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetQuickTradeCurrencyPairsWithStatsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairWithStatsDto>>;
      })
    );
  }

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuickTradeCurrencyPairsWithStatsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsWithStatsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairWithStatsDto>> {

    return this.getQuickTradeCurrencyPairsWithStatsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairWithStatsDto>>) => r.body as Array<CurrencyPairWithStatsDto>)
    );
  }

}
