import { NgModule } from '@angular/core';

import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';
import { DropdownComponent } from '@app/shared/components/dropdown/base/dropdown.component';
import { DropdownItemComponent } from '@app/shared/components/dropdown/base/dropdown-item/dropdown-item.component';
import { DropdownIconComponent } from '@app/shared/components/dropdown/base/dropdown-icon/dropdown-icon.component';
import { DropdownTriggerDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger.directive';
import { DropdownTriggerButtonDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger-button.directive';
import { DropdownSectionDirective } from '@app/shared/components/dropdown/base/dropdown-section/dropdown-section.directive';

import { MenuDropdownComponent } from '@app/shared/components/dropdown/menu/menu-dropdown.component';
import { MenuDropdownItemComponent } from '@app/shared/components/dropdown/menu/menu-dropdown-item/menu-dropdown-item.component';
import { MenuDropdownIconComponent } from '@app/shared/components/dropdown/menu/menu-dropdown-icon/menu-dropdown-icon.component';
import { MenuDropdownTriggerDirective } from '@app/shared/components/dropdown/menu/menu-dropdown-trigger/menu-dropdown-trigger.directive';
import { MenuDropdownSectionDirective } from '@app/shared/components/dropdown/menu/menu-dropdown-section/menu-dropdown-section.directive';

@NgModule({
  imports: [
    // Base
    DropdownComponent,
    DropdownItemComponent,
    DropdownIconComponent,
    DropdownTriggerDirective,
    DropdownTriggerButtonDirective,
    DropdownSectionDirective,
    // Menu
    MenuDropdownComponent,
    MenuDropdownItemComponent,
    MenuDropdownIconComponent,
    MenuDropdownTriggerDirective,
    MenuDropdownSectionDirective,
  ],
  exports: [
    // Base
    DropdownComponent,
    DropdownItemComponent,
    DropdownIconComponent,
    DropdownTriggerDirective,
    DropdownTriggerButtonDirective,
    DropdownSectionDirective,
    // Menu
    MenuDropdownComponent,
    MenuDropdownItemComponent,
    MenuDropdownIconComponent,
    MenuDropdownTriggerDirective,
    MenuDropdownSectionDirective,
  ],
  providers: [DropdownPopoverService],
})
export class DropdownModule {}
