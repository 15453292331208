import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h',
  templateUrl: './h.component.html',
  styleUrls: ['./h.component.scss'],
})
export class HComponent {
  @Input()
  subtitle!: string;

  @Input()
  id?: string;

  @Input()
  type!: 'h1' | 'h2' | 'h3' | 'h4';

  @Input()
  color?: string;
}
