import { Component, Input } from '@angular/core';
import { IDataArr } from '@app-shared/services/strapi/interfaces';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input()
  header?: string;

  @Input()
  headerType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  @Input()
  data?: string;

  @Input()
  dataArr?: IDataArr[];

  @Input()
  id?: string;

  @Input()
  size?: 'default' | 'large' = 'default';
}
