import { createAction, props } from '@ngrx/store';
import { CurrencyPairDto } from '@app-generated/models/currency-pair-dto';
import { CurrencyPairWithStatsDto } from '@app-generated/models/currency-pair-with-stats-dto';

export const setCurrencyPairs = createAction('[Currency pairs] Set', props<{ currencyPairs: CurrencyPairDto[] }>());

export const loadCurrencyPairs = createAction('[Currency pairs] Load');

/* With stats */
export const setCurrencyPairsWithStats = createAction(
  '[Currency pairs with stats] Set',
  props<{ currencyPairsWithStats: CurrencyPairWithStatsDto[] }>(),
);

export const loadCurrencyPairsWithStats = createAction('[Currency pairs with stats] Load');

export const updateCurrencyPairWithStats = createAction(
  '[Currency pairs with stats] Update',
  props<{ currencyPairWithStats: CurrencyPairWithStatsDto }>(),
);

export const updateCurrencyPairWithStatsTemporaryEndpointForAllCurrencies = createAction(
  '[Currency pairs with stats] Update Temporary Endpoint',
  props<{ currencyPairWithStats: CurrencyPairWithStatsDto[] }>(),
);

export const loadCurrencyPairsWithStatsWs = createAction('[Currency pairs with stats from WS] Load');

/* Favourites */
export const setFavouriteCurrencyPairs = createAction(
  '[Favourite currency pairs] Set',
  props<{ favouriteCurrencyPairs: CurrencyPairWithStatsDto[] }>(),
);

export const loadFavouriteCurrencyPairs = createAction('[Favourite currency pairs] Load');

export const addCurrencyPairToFavourites = createAction(
  '[Favourite currency pairs] Add',
  props<{ pair: CurrencyPairWithStatsDto }>(),
);

export const removeCurrencyPairFromFavourites = createAction(
  '[Favourite currency pairs] Remove',
  props<{ name: string }>(),
);

/* Filters */

export const clearFilter = createAction('[Currency pairs filter] clear');

export const toggleFilter = createAction('[Currency pairs filter] toggle', props<{ name: string }>());
