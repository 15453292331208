import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { SkeletonComponent } from '@app/shared/components/skeleton/skeleton.component';
import { ScrolledToBottomDirective } from '@app/shared/directives/scrolled-to-bottom.directive';
import { DataTableHeaderDirective } from '@app/shared/components/data-table/data-table-header.directive';
import { DataTableColumnDirective } from '@app/shared/components/data-table/data-table-column.directive';

const TABLE_CELL_PADDING_HORIZONTAL = 14;

interface Row {
  id?: number;

  [key: string]: any;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    SkeletonComponent,
    ScrolledToBottomDirective,
    TranslateModule,
  ],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() rows: Row[] | null = null;
  @Input() datasetKey? = '';
  @Input() loading = false;
  @Input() columnWidths: (number | null)[] = [];
  @Input() minRowHeight: number = 52;
  @Input() emptyMessage = '';
  @Output() scrolledToBottom = new EventEmitter();

  @ContentChildren(DataTableHeaderDirective) headerTemplates!: QueryList<DataTableHeaderDirective>;
  @ContentChildren(DataTableColumnDirective) columnTemplates!: QueryList<DataTableColumnDirective>;

  @ViewChildren(DataTableColumnDirective) stickyColumns!: QueryList<DataTableColumnDirective>;

  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
  @ViewChild('headerContainer', { read: ElementRef }) headerContainer!: ElementRef;
  @ViewChild('bodyContainer', { read: ElementRef }) bodyContainer!: ElementRef;

  bodyContainerWidth: number | null = null;
  viewPortScrollbarWidth = 0;
  stickyColumnLeftPositions: (number | null)[] = [];
  showStickyShadow = false;

  private unsubscribe$ = new Subject<void>();
  private headerContainerInitWidthSynchronized = false;

  constructor() {
    this.emptyMessage = this.emptyMessage || 'shared.common.no-data';
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rows || changes.columnWidths) {
      setTimeout(() => {
        this.syncHeaderContainerWidth();
        this.syncHeaderContainerPosition();
        this.syncStickyColumns();
      }, 1);
    }

    if (changes.rows?.currentValue?.length === 0) {
      /**
       * Resets the height of the scrollable viewport in the table if no data is available.
       */
      this.viewPort?.checkViewportSize();
    }

    if (changes.datasetKey?.previousValue !== changes.datasetKey?.currentValue) {
      /**
       * Resets the scrollbar position of the table when the dataset key changes.
       */
      this.viewPort?.scrollToOffset(0);
    }
  }

  ngAfterViewInit(): void {
    if (this.viewPort) {
      setTimeout(() => {
        this.syncHeaderContainerWidth();
        this.syncStickyColumns();
        this.syncStickyShadows();
      }, 1);

      this.viewPort
        .elementScrolled()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event) => {
          if (!this.headerContainerInitWidthSynchronized) {
            this.syncHeaderContainerWidth();
            this.headerContainerInitWidthSynchronized = true;
          }

          this.syncHeaderContainerPosition();
          this.syncStickyShadows();
        });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.syncHeaderContainerWidth();
    this.syncStickyColumns();
    this.syncStickyShadows();
  }

  trackByItemId(index: number, item: any) {
    return `row-${item.id ?? index}`;
  }

  private syncHeaderContainerWidth() {
    const { clientHeight, scrollHeight } = this.viewPort.elementRef.nativeElement;

    this.viewPort.elementRef.nativeElement.style.overflow = 'scroll';
    const viewPortWidthWithScrollbar = this.viewPort.elementRef.nativeElement.clientWidth;
    this.viewPort.elementRef.nativeElement.style.overflow = 'hidden';
    const viewPortWidthWithoutScrollbar = this.viewPort.elementRef.nativeElement.clientWidth;
    this.viewPort.elementRef.nativeElement.style.overflow = '';
    const bodyContainerWidth = this.bodyContainer.nativeElement.clientWidth;

    this.viewPortScrollbarWidth =
      clientHeight === scrollHeight ? 0 : viewPortWidthWithoutScrollbar - viewPortWidthWithScrollbar;
    this.bodyContainerWidth = this.viewPortScrollbarWidth + bodyContainerWidth;
  }

  private syncHeaderContainerPosition() {
    this.headerContainer.nativeElement.scrollLeft = this.viewPort.measureScrollOffset('start');
  }

  private syncStickyColumns() {
    if (!this.columnTemplates?.length) {
      this.stickyColumnLeftPositions = [];
      return;
    }

    this.stickyColumnLeftPositions = Array.from({ length: this.columnTemplates.length }).map(() => null);

    const numberOfColumns = this.columnTemplates.length - 1;

    // Iterates columns from right to left
    for (let i = numberOfColumns; i >= 0; i--) {
      const columnWidth = this.columnWidths[i];
      // Column width must be defined
      if (!columnWidth) {
        return;
      }

      const columnTemplate = this.columnTemplates.get(i);
      // Column must be sticky and in a sequence
      if (!columnTemplate?.sticky) {
        return;
      }

      if (numberOfColumns === i) {
        this.stickyColumnLeftPositions[i] = 0;
        continue;
      }

      const previousStickyColumnLeftPosition = this.stickyColumnLeftPositions[i + 1] || 0;
      const previousColumnWidth = (this.columnWidths[i + 1] || 0) + TABLE_CELL_PADDING_HORIZONTAL;
      this.stickyColumnLeftPositions[i] = previousColumnWidth + previousStickyColumnLeftPosition;
    }
  }

  private syncStickyShadows() {
    const { clientWidth, scrollWidth, scrollLeft } = this.viewPort.elementRef.nativeElement;
    this.showStickyShadow =
      this.stickyColumnLeftPositions.some((value) => value !== null) && scrollWidth > clientWidth + scrollLeft;
  }
}
