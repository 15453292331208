import { Directive, forwardRef, Input } from '@angular/core';
import { DropdownTriggerDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger.directive';

@Directive({
  selector: 'button[dropdown-trigger]',
  standalone: true,
  host: {
    '[attr.data-dropdown-facet]': 'facet',
    '[attr.data-dropdown-chevron]': 'withChevron ? "" : undefined',
  },
  providers: [{ provide: DropdownTriggerDirective, useExisting: forwardRef(() => DropdownTriggerButtonDirective) }],
})
export class DropdownTriggerButtonDirective extends DropdownTriggerDirective {
  @Input() facet?: 'secondary-link';
  @Input() withChevron?: boolean;
}
