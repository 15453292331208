import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import { filter, map, skipWhile, switchMap, take, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { queryGuiParams, queryGuiParamsStatus } from './gui-params.selectors';
import { Observable, of } from 'rxjs';
import { loadGuiParams } from '@app/shared/store/gui-params/gui-params.actions';
import { GuiParamsDto } from '@app/generated/models/gui-params-dto';
import { ApiStateStatus } from '@app/shared/store/gui-params/gui-params.reducer';

@Injectable({
  providedIn: 'root',
})
export class GuiParams {
  guiParams$ = this.store.select(queryGuiParams()).pipe(skipWhile((guiParams) => !guiParams?.csrfToken));
  appState$ = this.guiParams$.pipe(map((params) => params.appState));
  loggedIn$ = this.guiParams$.pipe(
    map((params) => params.todoRemoveThisUnholyPieceOfGarbageParamWhetherUserIsLoggedIn || false),
  );

  constructor(private store: Store<SharedState>) {}
  load(): void {
    this.store.dispatch(loadGuiParams());
  }
  getCurrentAndSaveToStore(forceReload = false): Observable<GuiParamsDto> {
    return this.store.select(queryGuiParams()).pipe(
      tap((guiParams: GuiParamsDto | null) => {
        if (!guiParams || !guiParams.csrfToken || forceReload) {
          this.store.dispatch(loadGuiParams());
        }
      }),
      switchMap(() => {
        return this.store.select(queryGuiParamsStatus()).pipe(
          filter((status) => status !== ApiStateStatus.loading),
          withLatestFrom(this.store.select(queryGuiParams())),
          filter(([_status, updatedGuiParams]) => {
            return !!updatedGuiParams && !!updatedGuiParams.csrfToken;
          }),
          take(1),
          switchMap(([loading, updatedGuiParams]) => {
            return of(updatedGuiParams);
          }),
        );
      }),
      take(1),
    );
  }
}
