/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UserGuiWebsocketChannelsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation openPrivateTradesForPairChannelUsingPost
   */
  static readonly OpenPrivateTradesForPairChannelUsingPostPath = '/frontend/websocket/user/channel/my-trades/{currencyPair}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openPrivateTradesForPairChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateTradesForPairChannelUsingPost$Response(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserGuiWebsocketChannelsControllerService.OpenPrivateTradesForPairChannelUsingPostPath, 'post');
    if (params) {
      rb.path('currencyPair', params.currencyPair, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openPrivateTradesForPairChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateTradesForPairChannelUsingPost(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openPrivateTradesForPairChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openPrivatePendingQuickTradeStatusChannelUsingPost
   */
  static readonly OpenPrivatePendingQuickTradeStatusChannelUsingPostPath = '/frontend/websocket/user/channel/my-pending-quick-trade-status/{transactionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openPrivatePendingQuickTradeStatusChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivatePendingQuickTradeStatusChannelUsingPost$Response(params: {
    transactionId: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserGuiWebsocketChannelsControllerService.OpenPrivatePendingQuickTradeStatusChannelUsingPostPath, 'post');
    if (params) {
      rb.path('transactionId', params.transactionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openPrivatePendingQuickTradeStatusChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivatePendingQuickTradeStatusChannelUsingPost(params: {
    transactionId: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openPrivatePendingQuickTradeStatusChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openPrivateOpenOrdersForPairChannelUsingPost
   */
  static readonly OpenPrivateOpenOrdersForPairChannelUsingPostPath = '/frontend/websocket/user/channel/my-open-orders/{currencyPair}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openPrivateOpenOrdersForPairChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateOpenOrdersForPairChannelUsingPost$Response(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserGuiWebsocketChannelsControllerService.OpenPrivateOpenOrdersForPairChannelUsingPostPath, 'post');
    if (params) {
      rb.path('currencyPair', params.currencyPair, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openPrivateOpenOrdersForPairChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateOpenOrdersForPairChannelUsingPost(params: {
    currencyPair: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openPrivateOpenOrdersForPairChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openPrivateDepositTransferStatusChannelUsingPost
   */
  static readonly OpenPrivateDepositTransferStatusChannelUsingPostPath = '/frontend/websocket/user/channel/my-deposit-transfer-status/{transactionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openPrivateDepositTransferStatusChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateDepositTransferStatusChannelUsingPost$Response(params: {
    transactionId: string;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserGuiWebsocketChannelsControllerService.OpenPrivateDepositTransferStatusChannelUsingPostPath, 'post');
    if (params) {
      rb.path('transactionId', params.transactionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openPrivateDepositTransferStatusChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateDepositTransferStatusChannelUsingPost(params: {
    transactionId: string;
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openPrivateDepositTransferStatusChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation openPrivateBalancesChannelUsingPost
   */
  static readonly OpenPrivateBalancesChannelUsingPostPath = '/frontend/websocket/user/channel/my-balances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openPrivateBalancesChannelUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateBalancesChannelUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserGuiWebsocketChannelsControllerService.OpenPrivateBalancesChannelUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openPrivateBalancesChannelUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openPrivateBalancesChannelUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<string> {

    return this.openPrivateBalancesChannelUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
