import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Inject,
  Injectable,
  Type,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { equals } from 'ramda';

interface DynamicModal {
  modalId: string;
  modalData?: Record<string, any>;
  modalActions?: Record<string, () => void>;
  restoreModalAfterClose?: string;
}

interface DynamicModalState {
  show: boolean;
  minimized: boolean;
  restoreModalAfterClose?: string;
}

type DynamicModalStates = Record<DynamicModal['modalId'], DynamicModalState>;

const initialModalState = {
  show: false,
  minimized: false,
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: { [id: string]: BehaviorSubject<boolean> } = {};
  private activeModalId: string | null = null;

  private dynamicModalComponentRefs: Map<string, ComponentRef<unknown>> = new Map();
  private dynamicModalState: DynamicModalStates = {};
  private dynamicModalStateSubject: BehaviorSubject<DynamicModalStates> = new BehaviorSubject(this.dynamicModalState);

  constructor(
    private router: Router,
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.dynamicModalStateSubject
      .asObservable()
      .pipe(
        map((modals) => {
          return Object.values(modals).every(({ show, minimized }) => !show && !minimized);
        }),
      )
      .subscribe((allModalsClosed) => {
        this.document.body.style.overflow = allModalsClosed ? '' : 'hidden';
        this.document.documentElement.style.overflowX = allModalsClosed ? 'hidden' : 'unset';
      });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.closeAllModals();
    });
  }

  // region Static Modal
  isModalOpen(id: string): boolean {
    return this.modals[id] ? this.modals[id].value : false;
  }

  getModalState(id: string) {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
    return this.modals[id].asObservable();
  }

  toggleModal(id: string) {
    this.modals[id].next(!this.modals[id].value);
    this.activeModalId = this.modals[id].value ? id : null;
  }

  openModal(id: string) {
    if (this.modals[id]) {
      this.modals[id].next(true);
      this.activeModalId = id;
    }
  }

  closeModal(id: string) {
    if (this.modals[id]) {
      this.modals[id].next(false);

      if (this.activeModalId === id) {
        this.activeModalId = null;
      }
    }
  }

  closeActiveModal() {
    if (this.activeModalId && this.modals[this.activeModalId]) {
      this.modals[this.activeModalId].next(false);
      this.activeModalId = null;
    }
  }
  // endregion

  closeAllModals() {
    for (const id in this.modals) {
      if (this.modals.hasOwnProperty(id)) {
        this.modals[id].next(false);
      }
    }

    for (const modalId in this.dynamicModalState) {
      this.closeDynamicModal(modalId);
    }
  }

  // region Dynamic Modal
  /**
   * Returns the modal state as an observable
   * @param modalId
   */
  getDynamicModalState(modalId: string) {
    return this.dynamicModalStateSubject.asObservable().pipe(
      // delay(1), // Delay for correct state synchronization
      map((state) => state[modalId]),
      distinctUntilChanged((a, b) => {
        return equals(a, b);
      }),
    );
  }

  /**
   * Open modal
   * @param modalId - Modal ID
   * @param component - Modal component
   * @param modalData - Modal data, useful for payload
   * @param modalActions - Modal actions, useful for methods
   * @param restoreModalAfterClose - Modal ID of another modal, which is minimized when the modal is opened and maximized again when it is closed
   */
  openDynamicModal<
    C extends {
      modalId: string;
      modalData?: C['modalData'];
      modalActions?: C['modalActions'];
      restoreModalAfterClose?: string;
    },
  >(
    modalId: string,
    component: Type<C>,
    modalData?: C['modalData'],
    modalActions?: C['modalActions'],
    restoreModalAfterClose?: string,
  ) {
    if (this.dynamicModalComponentRefs.has(modalId)) {
      this.closeDynamicModal(modalId);
    }

    if (restoreModalAfterClose) {
      this.minimizeDynamicModal(restoreModalAfterClose);
    }

    const modalComponent = createComponent(component, {
      environmentInjector: this.injector,
    }) as ComponentRef<C>;

    const modalComponentInstance = modalComponent.instance as DynamicModal;
    modalComponentInstance.modalId = modalId;

    if (modalData) {
      modalComponentInstance.modalData = modalData;
    }

    if (modalActions) {
      modalComponentInstance.modalActions = modalActions;
    }

    document.body.appendChild(modalComponent.location.nativeElement);
    this.appRef.attachView(modalComponent.hostView);

    this.dynamicModalComponentRefs.set(modalId, modalComponent);

    this.updateModalState(modalId, {
      ...initialModalState,
      show: true,
      restoreModalAfterClose: restoreModalAfterClose ?? undefined,
    });

    this.activeModalId = modalId;
  }

  /**
   * Close modal
   * @param modalId - Modal ID
   */
  closeDynamicModal(modalId: string) {
    if (this.dynamicModalComponentRefs.has(modalId)) {
      const restoreModalAfterClose = this.dynamicModalState[modalId].restoreModalAfterClose;

      if (restoreModalAfterClose) {
        this.maximizeDynamicModal(restoreModalAfterClose);
      }

      this.updateModalState(modalId, {
        ...initialModalState,
        show: false,
      });

      const modalComponent = this.dynamicModalComponentRefs.get(modalId) as ComponentRef<unknown>;
      modalComponent.destroy();

      this.dynamicModalComponentRefs.delete(modalId);
      this.activeModalId = null;
    }
  }

  /**
   * Toggle modal
   * @param modalId - Modal ID
   * @param component - Modal component
   * @param modalData - Modal data, useful for payload
   * @param modalActions - Modal actions, useful for methods
   * @param restoreModalAfterClose - Modal ID of another modal, which is minimized when the modal is opened and maximized again when it is closed
   */
  toggleDynamicModal<C extends { modalId: string; modalData?: C['modalData']; modalActions?: C['modalActions'] }>(
    modalId: string,
    component: Type<C>,
    modalData?: C['modalData'],
    modalActions?: C['modalActions'],
    restoreModalAfterClose?: string,
  ) {
    if (this.dynamicModalComponentRefs.has(modalId)) {
      this.closeDynamicModal(modalId);
    } else {
      this.openDynamicModal(modalId, component, modalData, modalActions, restoreModalAfterClose);
    }
  }

  /**
   * Minimize modal
   * @param modalId - Modal ID
   */
  minimizeDynamicModal(modalId: string) {
    this.updateModalState(modalId, {
      ...this.dynamicModalState[modalId],
      minimized: true,
    });
  }

  /**
   * Maximize modal
   * @param modalId - Modal ID
   */
  maximizeDynamicModal(modalId: string) {
    this.updateModalState(modalId, {
      ...this.dynamicModalState[modalId],
      minimized: false,
    });
  }

  /**
   * Close active modal
   */
  closeActiveDynamicModal() {
    if (this.activeModalId) {
      this.closeDynamicModal(this.activeModalId);
    }
  }

  /**
   * Returns true if the modal is dynamic
   * @param modalId - Modal ID
   */
  isDynamicModal(modalId: string) {
    return this.dynamicModalComponentRefs.has(modalId);
  }

  private updateModalState(modalId: string, newModalState: DynamicModalState): void {
    this.dynamicModalState = {
      ...this.dynamicModalState,
      [modalId]: newModalState,
    };
    this.dynamicModalStateSubject.next(this.dynamicModalState);
  }
  // endregion
}
