import { Directive } from '@angular/core';
import { MenuVerticalPopoverService } from '@app/shared/components/menu-vertical/menu-vertical-popover.service';

@Directive({
  selector: '[menu-vertical-item]',
  standalone: true,
  host: {
    '(click)': 'closePopover()',
  },
})
export class MenuVerticalItemDirective {
  constructor(private menuVerticalPopoverService: MenuVerticalPopoverService) {}

  closePopover() {
    this.menuVerticalPopoverService.close();
  }
}
