import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ModalService } from '../../modal/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { QuickTradeOrderService } from '@app/authenticated/quick-trade/quick-trade-order-service';
import { LanguageService } from '@app/shared/services/language.service';
import { Balances } from '@app/shared/store/balances/balances-facade.service';
import { ContextCurrencyModalComponent } from '@app/shared/components/bar/context-currency-modal/context-currency-modal.component';

const CURRENCY_MODAL_ID = 'currencyBar';

@Component({
  selector: 'app-context-currency-bar',
  templateUrl: './context-currency-bar.component.html',
  styleUrls: ['./context-currency-bar.component.scss'],
})
export class ContextCurrencyBarComponent implements OnInit, OnDestroy {
  currencies: CurrencyBalance[] = [];
  displayCurrencies: CurrencyBalance[] = [];
  moreCount = 0;
  unsubscribe$ = new Subject<void>();
  currentLang = '';

  /* eslint-disable @typescript-eslint/naming-convention */
  private readonly DESKTOP_DISPLAY_COUNT = 5;
  private readonly MOBILE_DISPLAY_COUNT = 1;

  constructor(
    public modalService: ModalService,
    private breakpointObserver: BreakpointObserver,
    private userInfo: UserInfo,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private quickTradeOrderService: QuickTradeOrderService,
    private balancesFacade: Balances,
  ) {}

  ngOnInit(): void {
    this.updateCurrencies();

    this.userInfo.eventEmitter.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateCurrencies();
    });

    this.quickTradeOrderService.currencyOrdered$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateCurrencies();
    });

    this.languageService.currentLanguage$.pipe(takeUntil(this.unsubscribe$)).subscribe((lang: string) => {
      this.currentLang = lang;
      this.cdr.detectChanges();
      this.updateCurrencies();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openModal() {
    this.modalService.openDynamicModal(CURRENCY_MODAL_ID, ContextCurrencyModalComponent);
  }

  updateCurrencies() {
    this.balancesFacade.load();
    this.balancesFacade.currencyBalancesWithEquivalents$.pipe(takeUntil(this.unsubscribe$)).subscribe((balances) => {
      if (balances) {
        this.currencies = Object.values(balances)
          .map((currency) => {
            return {
              available: currency.available,
              depositActive: currency.depositActive,
              fiatEquivalentsAvailableCZK: currency.fiatEquivalentsAvailable.CZK,
              fiatEquivalentsAvailableEUR: currency.fiatEquivalentsAvailable.EUR,
              lastPriceCZK: currency.lastPrices.CZK,
              name: currency.name,
              openOrders: currency.inOpenOrders,
              total: currency.totalBalance,
              withdrawalActive: currency.withdrawalActive,
            } as CurrencyBalance;
          })
          .filter((currency) => currency.available > 0 && currency.fiatEquivalentsAvailableCZK > 0)
          .sort((a, b) => {
            return (
              (b.name === 'CZK' ? 1 : 0) - (a.name === 'CZK' ? 1 : 0) ||
              (b.name === 'EUR' ? 1 : 0) - (a.name === 'EUR' ? 1 : 0) ||
              b.fiatEquivalentsAvailableCZK - a.fiatEquivalentsAvailableCZK
            );
          });
      }

      this.breakpointObserver
        .observe([Breakpoints.HandsetPortrait, Breakpoints.Tablet])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result.matches) {
            this.displayCurrencies = this.currencies.slice(0, this.MOBILE_DISPLAY_COUNT);
            this.moreCount = this.currencies.length - this.MOBILE_DISPLAY_COUNT;
          } else {
            this.displayCurrencies = this.currencies.slice(0, this.DESKTOP_DISPLAY_COUNT);
            this.moreCount = this.currencies.length - this.DESKTOP_DISPLAY_COUNT;
          }
        });
    });
  }
}

export interface CurrencyBalance {
  available: number;
  depositActive: boolean;
  fiatEquivalentsAvailableCZK: number;
  fiatEquivalentsAvailableEUR: number;
  lastPriceCZK?: number;
  name: string;
  openOrders: number;
  total: number;
  withdrawalActive: boolean;
}
