/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReferralDto } from '../models/referral-dto';
import { ReferralParamsDto } from '../models/referral-params-dto';

@Injectable({
  providedIn: 'root',
})
export class UserReferralControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getReferralsUsingGet
   */
  static readonly GetReferralsUsingGetPath = '/frontend/user/referment';

  /**
   * Get list of Referred users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReferralDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserReferralControllerService.GetReferralsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReferralDto>>;
      })
    );
  }

  /**
   * Get list of Referred users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReferralsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<ReferralDto>> {

    return this.getReferralsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReferralDto>>) => r.body as Array<ReferralDto>)
    );
  }

  /**
   * Path part for operation getReferralParametersUsingGet
   */
  static readonly GetReferralParametersUsingGetPath = '/frontend/user/referment/params';

  /**
   * Get parameters for Referral program.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralParametersUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralParametersUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ReferralParamsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserReferralControllerService.GetReferralParametersUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferralParamsDto>;
      })
    );
  }

  /**
   * Get parameters for Referral program.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReferralParametersUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralParametersUsingGet(params?: {
  },
  context?: HttpContext

): Observable<ReferralParamsDto> {

    return this.getReferralParametersUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ReferralParamsDto>) => r.body as ReferralParamsDto)
    );
  }

}
