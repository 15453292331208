import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ISelectData {
  label: string | number | undefined;
  value: string | number | undefined;
}

@Component({
  selector: 'app-select-new',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectNewComponent),
      multi: true,
    },
  ],
})
export class SelectNewComponent extends AbstractComponent implements OnInit {
  @Input() placeholder?: string;
  @Input() data?: ISelectData[];
  @Input() observableData?: Observable<ISelectData[]>;
  @Input() icon?: string;
  @Input() rightInputText?: string | number;
  @Input() disabled?: boolean = false;
  @Input() sort?: (data: ISelectData[]) => ISelectData[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.sort) {
      if (this.data) {
        this.data = this.sort(this.data);
      } else if (this.observableData) {
        this.observableData.pipe(map((data) => (this.sort ? this.sort(data) : data)));
      }
    }
  }
}
