import { Directive } from '@angular/core';

@Directive({
  selector: '[menu-dropdown-section]',
  standalone: true,
  host: {
    '[class.menu-dropdown-section]': 'true',
  },
})
export class MenuDropdownSectionDirective {}
