import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { GuiParams } from '@app-shared/store/gui-params/gui-params-facade.service';
import { CSRF_HEADER_NAME, USER_CSRF_INTERCEPTOR_HEADER_NAME } from '@app-shared/const/csrf.const';
import { environment } from '@app/environments/environment';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private guiParams: GuiParams) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(CSRF_HEADER_NAME) || !this.requestRequiresCsrfToken(request)) {
      return next.handle(request);
    }

    // intentionally skip csrf token via header
    if (request.headers.has(USER_CSRF_INTERCEPTOR_HEADER_NAME)) {
      // dont insert csrf token to request
      request.headers.delete(USER_CSRF_INTERCEPTOR_HEADER_NAME);
      return next.handle(request);
    }

    // insert csrf token
    return this.guiParams.getCurrentAndSaveToStore().pipe(
      take(1),
      mergeMap((guiParams) =>
        next.handle(
          request.clone({
            headers: request.headers.append(CSRF_HEADER_NAME, guiParams?.csrfToken || ''),
          }),
        ),
      ),
    );
  }

  private requestRequiresCsrfToken(request: HttpRequest<unknown>): boolean {
    return (
      request.url !== `${environment.apiRoot}/frontend/gui-parameters` &&
      request.url !== `/frontend/gui-parameters` &&
      !request.url?.startsWith(`/assets/`)
    );
  }
}
