import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-trading-platform-layout',
  templateUrl: './trading-platform-layout.component.html',
})
export class TradingPlatformLayoutComponent implements OnInit, OnDestroy {
  isLoggedIn = false;

  private unsubscribe$ = new Subject<void>();

  constructor(public router: Router, private guiParams: GuiParams) {}

  ngOnInit() {
    this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
