import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopoverPosition } from '@app/shared/components/dropdown/dropdown.enum';
import { DropdownTriggerDirective } from '@app/shared/components/dropdown/base/dropdown-trigger/dropdown-trigger.directive';
import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const DESKTOP_BREAKPOINT = 768;

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    '(document:click)': 'clickOutside($event)',
    '(click)': 'clickInside($event)',
    '[attr.data-dropdown-position]': 'positions[popoverPosition]',
    '[attr.data-dropdown-open]': 'visible ? "" : undefined',
    '[attr.aria-haspopup]': '"menu"',
    '[attr.aria-expanded]': 'visible',
  },
  providers: [DropdownService],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() popoverPosition: keyof typeof PopoverPosition = PopoverPosition.bottomEnd;
  @Input() dropdownClass?: string;
  @Input() popoverClass?: string;

  @ViewChild('dropdown') dropdownRef!: ElementRef;
  @ContentChild(DropdownTriggerDirective, { descendants: true }) dropdownTriggerRef!: DropdownTriggerDirective;
  @ViewChild('dropdownPopover') dropdownPopoverRef!: ElementRef;
  @ViewChild('dropdownPopoverContainer') dropdownPopoverContainerRef!: ElementRef;

  visible = false;
  positions = {
    [PopoverPosition.topStart]: 'top-start',
    [PopoverPosition.topEnd]: 'top-end',
    [PopoverPosition.bottomStart]: 'bottom-start',
    [PopoverPosition.bottomEnd]: 'bottom-end',
  };

  protected readonly popoverPositionEnum = PopoverPosition;

  private unsubscribe$ = new Subject<void>();
  private isDropdownPopoverMovedToBody = false;

  constructor(
    protected dropdownService: DropdownService,
    protected element: ElementRef,
    protected renderer: Renderer2,
  ) {}

  clickOutside(event: Event) {
    if (this.visible) {
      if (!this.element.nativeElement.contains(event.target)) {
        this.dropdownService.closeDropdown();
      }
    }
  }

  clickInside(event: Event) {
    event.stopPropagation();

    if (this.visible) {
      const button = this.findParentButton(event.target as HTMLElement);
      if (button && !button.hasAttribute('disabled')) {
        this.dropdownService.closeDropdown();
      }
    }
  }

  ngOnInit() {
    this.dropdownService.isVisible$.pipe(takeUntil(this.unsubscribe$)).subscribe((visible) => {
      this.visible = visible;

      if (!this.visible) {
        this.movePopoverToDropdown();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private findParentButton(element: HTMLElement) {
    if (!element) {
      return null;
    }

    while (element) {
      if (this.dropdownTriggerRef?.element?.nativeElement === element) {
        return null;
      }

      if (this.dropdownTriggerRef?.element?.nativeElement.contains(element)) {
        return null;
      }

      if (this.dropdownPopoverRef?.nativeElement === element) {
        return null;
      }

      if (['a', 'button'].includes(element.tagName.toLowerCase())) {
        return element;
      }

      element = element.parentElement as HTMLElement;
    }

    return null;
  }

  ngAfterViewChecked() {
    if (this.visible) {
      if (window.innerWidth < DESKTOP_BREAKPOINT) {
        this.movePopoverToBody();
      } else {
        this.movePopoverToDropdown();
      }
    } else {
      this.movePopoverToDropdown();
    }
  }

  private movePopoverToBody() {
    if (!this.isDropdownPopoverMovedToBody && this.dropdownPopoverContainerRef.nativeElement) {
      const body = this.renderer.selectRootElement('body', true);
      this.renderer.appendChild(body, this.dropdownPopoverContainerRef.nativeElement);
      this.isDropdownPopoverMovedToBody = true;
    }
  }

  private movePopoverToDropdown() {
    if (
      this.isDropdownPopoverMovedToBody &&
      this.dropdownRef.nativeElement &&
      this.dropdownPopoverContainerRef.nativeElement
    ) {
      this.renderer.appendChild(this.dropdownRef.nativeElement, this.dropdownPopoverContainerRef.nativeElement);
      this.isDropdownPopoverMovedToBody = false;
    }
  }
}
