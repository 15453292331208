<label
  class="switch"
  [ngClass]="{
    'w-full': isFullWidth
  }"
>
  <span *ngIf="label" class="cm-label">{{ label }}</span>
  <span class="switch-outer">
    <input type="checkbox" class="switch-input" [(ngModel)]="value" />
    <span class="switch-inner">
      <span *ngIf="falseLabel" class="switch-inner-label">{{ falseLabel }}</span>
      <span *ngIf="trueLabel" class="switch-inner-label">{{ trueLabel }}</span>
    </span>
  </span>
</label>
