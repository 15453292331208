import { TimePeriod } from '@app/authenticated/portfolio/ui/portfolio.enum';
import { IntervalUnit } from '@app/shared/utils/date';
import { AccountValueHistory } from '@app/authenticated/portfolio/ui/portfolio.type';
import BigNumber from 'bignumber.js';

export const getTimePeriodDayOffset = (timePeriod: TimePeriod) => {
  switch (timePeriod) {
    case TimePeriod['24hours']:
      return 2;
    case TimePeriod['7days']:
      return 7;
    case TimePeriod['30days']:
      return 30;
    case TimePeriod['180days']:
      return 180;
    default:
      return 0;
  }
};

export const getIntervalUnitByTimePeriod = (timePeriod: TimePeriod) => {
  switch (timePeriod) {
    case TimePeriod['30days']:
      return IntervalUnit['week'];
    case TimePeriod['180days']:
      return IntervalUnit['month'];
    default:
      return IntervalUnit['day'];
  }
};

export const getDateDiff = (startDateData: AccountValueHistory, endDateData: AccountValueHistory) => {
  const startDayCzkValueBN = BigNumber(startDateData?.CZK ?? 0);
  const endDayCzkValueBN = BigNumber(endDateData?.CZK ?? 0);
  const diffCzkValueBN = endDayCzkValueBN.minus(startDayCzkValueBN);

  const startDayEurValueBN = BigNumber(startDateData?.EUR ?? 0);
  const endDayEurValueBN = BigNumber(endDateData?.EUR ?? 0);
  const diffEurValueBN = endDayEurValueBN.minus(startDayEurValueBN);

  let changePercentageCZK = 0;
  let changePercentageEUR = 0;

  if (startDayCzkValueBN.gt(0)) {
    changePercentageCZK = diffCzkValueBN.dividedBy(startDayCzkValueBN).multipliedBy(100).toNumber();
  } else if (endDayCzkValueBN.gt(0)) {
    changePercentageCZK = 100;
  }

  if (startDayEurValueBN.gt(0)) {
    changePercentageEUR = diffEurValueBN.dividedBy(startDayEurValueBN).multipliedBy(100).toNumber();
  } else if (endDayEurValueBN.gt(0)) {
    changePercentageEUR = 100;
  }

  return {
    CZK: {
      value: diffCzkValueBN.toNumber(),
      changePercentage: changePercentageCZK,
    },
    EUR: {
      value: diffEurValueBN.toNumber(),
      changePercentage: changePercentageEUR,
    },
  };
};
