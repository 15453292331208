import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, formatNumber } from '@angular/common';
import { LanguageService } from '@app/shared/services/language.service';
import { map } from 'rxjs';

@Pipe({
  name: 'appPercentageChange',
})
export class AppPercentageChangePipe implements PipeTransform {
  private langToLocale: Record<string, string> = {
    en: 'en-US',
    cs: 'cs-CZ',
  } as const;

  private _pipe: AsyncPipe;

  constructor(private ref: ChangeDetectorRef, private lang: LanguageService) {
    this._pipe = new AsyncPipe(ref);
  }

  transform(value: number | null, decimalPlaces: number = 2): string | null {
    if (value === null || isNaN(value)) return null;

    return this._pipe.transform(
      this.lang.currentLanguage$.pipe(
        map((lang) => {
          const valueLocalized = formatNumber(value, this.langToLocale[lang], `.${decimalPlaces}-${decimalPlaces}`);
          // if number is in english format remove ',', otherwise parseFloat would not parse number correctly
          const valueLocalizedAsNumber = +valueLocalized?.replace(',', '');
          const sign = valueLocalizedAsNumber > 0 ? '+' : '';
          return `${sign}${valueLocalized}%`;
        }),
      ),
    );
  }
}

@Pipe({
  name: 'appPercentage',
})
export class AppPercentagePipe implements PipeTransform {
  private langToLocale: Record<string, string> = {
    en: 'en-US',
    cs: 'cs-CZ',
  } as const;

  private _pipe: AsyncPipe;

  constructor(private ref: ChangeDetectorRef, private lang: LanguageService) {
    this._pipe = new AsyncPipe(ref);
  }

  transform(value: number | null, decimalPlaces: number = 2): string | null {
    if (value === null || isNaN(value)) return null;

    return this._pipe.transform(
      this.lang.currentLanguage$.pipe(
        map((lang) => {
          const valueLocalized = formatNumber(value, this.langToLocale[lang], `.${decimalPlaces}-${decimalPlaces}`);
          return `${valueLocalized}%`;
        }),
      ),
    );
  }
}
