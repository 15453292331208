import { TrezorRegistrationDto } from '@app/generated/models/trezor-registration-dto';

export enum TwoFactorType {
  email = 'EMAIL',
  googleAuth = 'GOOGLE_AUTH',
  trezor = 'TREZOR',
  deviceConfirmation = 'DEVICE_CONFIRMATION',
}

export interface SecuritySettings {
  twoFactorType: TwoFactorType;
  loginSecuredBy2Fa: boolean;
  userHasPassword: boolean;
}

export interface SecuritySettingsChange {
  twoFactorPayload: TwoFactorChangePayload;
  loginSecuredBy2Fa: boolean;
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
}

export interface InitialGoogleTwoFactorData {
  base64QrImage: string;
  secretKey: string;
}

export interface TwoFactorChangePayload {
  twoFactorType: TwoFactorType;
}

export interface GoogleAuthTwoFactorChangePayload extends TwoFactorChangePayload {
  secretKey: string;
  oneTimeGoogleAuthKey: string;
}

export interface EmailTwoFactorChangePayload extends TwoFactorChangePayload {
  oneTimeEmailCode: string;
}

export interface TrezorTwoFactorChangePayload extends TwoFactorChangePayload {
  trezorData: TrezorRegistrationDto;
}
