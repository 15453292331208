/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyDto } from '../models/currency-dto';
import { CurrencyTradeLimitDto } from '../models/currency-trade-limit-dto';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCurrenciesUsingGet
   */
  static readonly GetCurrenciesUsingGetPath = '/frontend/currencies';

  /**
   * Get all available currencies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrenciesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrenciesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CurrenciesControllerService.GetCurrenciesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyDto>>;
      })
    );
  }

  /**
   * Get all available currencies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrenciesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrenciesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyDto>> {

    return this.getCurrenciesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>) => r.body as Array<CurrencyDto>)
    );
  }

  /**
   * Path part for operation getTradeLimitsUsingGet
   */
  static readonly GetTradeLimitsUsingGetPath = '/frontend/currencies/trade-limits';

  /**
   * Get trade limits for all available currencies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTradeLimitsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradeLimitsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyTradeLimitDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CurrenciesControllerService.GetTradeLimitsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyTradeLimitDto>>;
      })
    );
  }

  /**
   * Get trade limits for all available currencies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTradeLimitsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradeLimitsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyTradeLimitDto>> {

    return this.getTradeLimitsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyTradeLimitDto>>) => r.body as Array<CurrencyTradeLimitDto>)
    );
  }

}
