import { Injectable, OnDestroy } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { supportedLanguages } from '@app-shared/const/locales.const';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeCs from '@angular/common/locales/cs';
import { BehaviorSubject, firstValueFrom, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, distinctUntilChanged, map, take, concatMap } from 'rxjs/operators';
import { UserProfileControllerService } from '@app/generated/services/user-profile-controller.service';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { UserLocaleDto } from '@app/generated/models/user-locale-dto';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

type Language = 'en' | 'cs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  readonly currentLanguage$: Subject<Language>;
  private changeLanguage$: Subject<Language>;
  private unsubscribe$ = new Subject<void>();

  loggedIn$ = this.guiParams.loggedIn$;
  guiParams$ = this.guiParams.guiParams$;

  constructor(
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private userProfileService: UserProfileControllerService,
    private timeAgoIntl: TimeagoIntl,
    private guiParams: GuiParams,
  ) {
    this.currentLanguage$ = new BehaviorSubject<Language>(translate.currentLang as Language);
    this.changeLanguage$ = new BehaviorSubject<Language>(translate.currentLang as Language);
  }

  init() {
    this.changeLanguage$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((language) => !!language),
        distinctUntilChanged(),
        switchMap(async (language) => language),
      )
      .subscribe(async (language) => {
        await firstValueFrom(this.translate.use(language));
        this.localize.changeLanguage(language);
        this.setLanguageTimeAgo(language);
        this.currentLanguage$.next(language);
      });

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (event: LangChangeEvent) => {
        this.changeLanguage$.next(event.lang as Language);
      },
      () => {
        // TODO DTP-5035
      },
    );

    // Handle initial language setup and changes for both logged-in and non-logged-in users
    const initialParams$ = this.guiParams.getCurrentAndSaveToStore();
    const ongoingParams$ = this.guiParams$.pipe(takeUntil(this.unsubscribe$));

    initialParams$
      .pipe(
        take(1),
        filter((params) => !!params.userLocale?.language),
        map((params) => params.userLocale!.language.toLowerCase() as Language),
      )
      .subscribe((language) => {
        this.changeLanguage(language);
      });

    ongoingParams$
      .pipe(
        filter((params) => !!params.userLocale?.language),
        map((params) => params.userLocale!.language.toLowerCase() as Language),
      )
      .subscribe((language) => {
        this.changeLanguage(language);
      });

    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeCs, 'cs');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  async changeLanguage(language: Language): Promise<void> {
    this.changeLanguage$.next(language);
  }

  changeAndSaveLanguage(language: Language): void {
    const upperCaseLanguage = language.toUpperCase() as UserLocaleDto['language'];
    this.guiParams.loggedIn$
      .pipe(
        take(1),
        filter((value) => value),
        concatMap(() =>
          this.userProfileService.changeLanguageRequestUsingPost({ lang: upperCaseLanguage }).pipe(take(1)),
        ),
        concatMap(() => this.guiParams.getCurrentAndSaveToStore(true).pipe(take(1))),
      )
      .subscribe();
  }

  setLanguageTimeAgo(language: string) {
    const { timeAgoStrings } = supportedLanguages.find((lang) => lang.key === language) || supportedLanguages[0];

    this.timeAgoIntl.strings = timeAgoStrings;
    this.timeAgoIntl.changes.next();
  }
}
