/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FeeZoneDto } from '../models/fee-zone-dto';
import { FeesDto } from '../models/fees-dto';

@Injectable({
  providedIn: 'root',
})
export class FeesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFeesUsingGet
   */
  static readonly GetFeesUsingGetPath = '/frontend/fees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: FeesDto;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, FeesControllerService.GetFeesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        [key: string]: FeesDto;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<{
[key: string]: {
[key: string]: FeesDto;
};
}> {

    return this.getFeesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: FeesDto;
};
}>) => r.body as {
[key: string]: {
[key: string]: FeesDto;
};
})
    );
  }

  /**
   * Path part for operation getFeeZonesUsingGet
   */
  static readonly GetFeeZonesUsingGetPath = '/frontend/fees/fee-zones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeeZonesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeeZonesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FeeZoneDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FeesControllerService.GetFeeZonesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FeeZoneDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeeZonesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeeZonesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<FeeZoneDto>> {

    return this.getFeeZonesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FeeZoneDto>>) => r.body as Array<FeeZoneDto>)
    );
  }

}
