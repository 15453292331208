<ng-container *ngIf="!loading; else loader">
  <ng-container *ngIf="data$ | async as data; else noData">
    <ng-container *ngIf="data?.length; else noData">
      <div class="balances-table">
        <!-- SEARCH, FILTER -->
        <div class="flex items-center justify-between relative mb-[20px] z-[100]">
          <div class="w-full md:w-auto flex items-center">
            <form>
              <label for="search" class="sr-only">
                {{ 'balances-table.search-placeholder' | translate }}
              </label>
              <input
                type="text"
                name="search"
                id="search"
                autocomplete="off"
                (input)="onSearchTermChange($event)"
                [placeholder]="'balances-table.search-placeholder' | translate"
              />
              <div class="search">
                <svg-icon src="/assets/icons/24/search.svg"></svg-icon>
              </div>
            </form>
            <div class="applied-filter" *ngIf="showWithAmountOnly$ | async">
              <span>{{ 'balances-table.with-balance' | translate }}</span>
              <button (click)="onToggleWithAmountOnly()">
                <svg-icon src="/assets/icons/icon-remove-nobg.svg"></svg-icon>
              </button>
            </div>
            <div class="applied-filter" *ngIf="showFavoritesOnly$ | async">
              <span>
                {{ 'balances-table.favourites' | translate }}
              </span>
              <button (click)="onToggleFavoritesOnly()">
                <svg-icon src="/assets/icons/icon-remove-nobg.svg"></svg-icon>
              </button>
            </div>
          </div>
          <div class="relative">
            <ng-container *ngTemplateOutlet="filterDropdown"></ng-container>
          </div>
        </div>

        <!-- TABLE -->
        <div class="table-container">
          <ng-container *ngIf="dataLimited$ | async as dataLimited">
            <ng-container *ngIf="dataLimited.length; else noFilteredData">
              <table>
                <tr class="no-background no-border">
                  <th colspan="3">
                    {{ 'balances-table.currency' | translate }}
                  </th>
                  <th *ngIf="cellTotalBalanceRef" class="table-balance">
                    {{ 'balances-table.total-balance' | translate }}
                  </th>
                  <th *ngIf="cellPriceRef" class="table-price">
                    {{ 'balances-table.price' | translate }}
                  </th>
                  <th *ngIf="cellChangeRef" class="table-change">
                    {{ 'balances-table.change' | translate }}
                  </th>
                  <th *ngIf="actionButtonsRef || actionDropdownRef" class="!hidden md:!table-cell"></th>
                </tr>

                <ng-container *ngIf="selectedFiatCurrency$ | async as selectedFiatCurrency">
                  <ng-container *ngFor="let currency of dataLimited; let i = index">
                    <ng-container *ngIf="i === 0 && currency.favorite">
                      <tr class="no-background no-border">
                        <td colspan="5" class="table-section-title">
                          {{ 'balances-table.favourites' | translate }}
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="isStartOfNonFavorites(i, dataLimited)">
                      <tr class="no-background no-border">
                        <td colspan="5" class="table-section-title">
                          {{ 'balances-table.other-currencies' | translate }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr>
                      <td class="table-favorite">
                        <button class="like" [class.active]="currency.favorite" (click)="onToggleFavorite(currency)">
                          <svg-icon class="text-cm-blue" [src]="'/assets/icons/20/favorite.svg'"></svg-icon>
                        </button>
                      </td>
                      <td class="table-icon">
                        <img
                          class="h-8 w-8 rounded-full"
                          [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                          alt="{{ currency.name }}"
                        />
                      </td>
                      <td class="table-currency">
                        <p>
                          <strong>{{ currency.displayName | translate }}</strong>
                          {{ currency.name }}
                        </p>
                      </td>
                      <td *ngIf="cellTotalBalanceRef" class="table-balance">
                        <ng-container
                          *ngTemplateOutlet="
                            cellTotalBalanceRef;
                            context: { $implicit: currency, selectedFiatCurrency }
                          "
                        ></ng-container>
                      </td>
                      <td *ngIf="cellPriceRef" class="table-price">
                        <ng-container
                          *ngTemplateOutlet="cellPriceRef; context: { $implicit: currency, selectedFiatCurrency }"
                        ></ng-container>
                      </td>
                      <td *ngIf="cellChangeRef" class="table-change">
                        <ng-container
                          *ngTemplateOutlet="cellChangeRef; context: { $implicit: currency, selectedFiatCurrency }"
                        ></ng-container>
                      </td>
                      <td *ngIf="actionButtonsRef || actionDropdownRef" class="table-buttons">
                        <ng-container
                          *ngTemplateOutlet="
                            actionButtonsRef;
                            context: {
                              $implicit: currency,
                              depositBlocked,
                              depositVerified,
                              withdrawalBlocked,
                              withdrawalBlockedUntilDate
                            }
                          "
                        >
                        </ng-container>

                        <ng-container
                          *ngTemplateOutlet="
                            actionDropdownRef;
                            context: {
                              $implicit: currency,
                              depositBlocked,
                              depositVerified,
                              withdrawalBlocked,
                              withdrawalBlockedUntilDate
                            }
                          "
                        >
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <ng-template #filterDropdown>
        <app-dropdown class="filter-dropdown">
          <button dropdown-trigger>
            <svg-icon class="text-cm-blue" [src]="'/assets/icons/icon-filter-list.svg'"></svg-icon>
            <span *ngIf="filtersCount$ | async as showFilterButton" class="filters-count">{{ showFilterButton }}</span>
          </button>
          <button (click)="onToggleFavoritesOnly()" dropdown-item>
            {{ 'balances-table.favourites' | translate }}
            <span *ngIf="showFavoritesOnly$ | async" dropdown-icon-right
              ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
            ></span>
          </button>
          <button (click)="onToggleWithAmountOnly()" dropdown-item>
            {{ 'balances-table.with-balance' | translate }}
            <span *ngIf="showWithAmountOnly$ | async" dropdown-icon-right
              ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
            ></span>
          </button>
        </app-dropdown>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #noData>
  <div class="mb-[20px] text-center text-cm-grey-300 dark:text-cm-grey-200 text-sm">
    {{ 'error.balances.no-available-balances' | translate }}
  </div>
</ng-template>

<ng-template #noFilteredData>
  <div class="mb-[20px] text-center text-cm-grey-300 dark:text-cm-grey-200 text-sm">
    {{ (searchTermSubject.value ? 'error.balances.not-found' : 'error.balances.no-available-balances') | translate }}
  </div>
</ng-template>

<app-currencies-show-more-button
  class="mt-6 lg:mt-12"
  [currencies$]="dataFiltered$"
  [currenciesLimited$]="dataLimited$"
  (click)="onShowAllCurrencies()"
></app-currencies-show-more-button>
