<div class="cm-paragraph cm-form-paragraph" [ngClass]="{ error: showError }">
  <label *ngIf="label && showLabel" class="cm-label" for="{{ formControlName }}-id">
    {{ label | translate }}
  </label>
  <div class="relative">
    <textarea
      #textarea
      class="cm-input cm-textarea"
      [ngClass]="{ 'cm-form-error-input': showError, 'cm-textarea-small': size === 'small' }"
      name="{{ formControlName }}"
      id="{{ formControlName }}-id"
      [placeholder]="placeholder || '' | translate"
      (focus)="isFocused = true"
      (focusout)="isFocused = false; onTouched()"
      [(ngModel)]="value"
    ></textarea>
  </div>
  <span *ngIf="showError" class="cm-form-error-text"> {{ errorMessage || '' | translate }}</span>
</div>
