import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencyPairDto } from '@app-generated/models/currency-pair-dto';
import { floor } from '@app-shared/pipes/app-floor.pipe';
import { CurrencyPairs } from '@app-shared/store/currency-pairs/currency-pairs-facade.service';
import { map, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'appFloorPriceObservable',
})
export class AppFloorPriceObservablePipe implements PipeTransform {
  defaultDecimalPlaces = 8;

  constructor(private currencyPairsService: CurrencyPairs) {}

  transform(
    amount$: Observable<number>,
    currencyPairName$: Observable<string>,
    thousandSeparator: string = ' ',
  ): Observable<string> {
    return amount$.pipe(
      switchMap((amount) =>
        currencyPairName$.pipe(
          switchMap((currencyPairName) =>
            this.currencyPairsService.currencyPairs$.pipe(
              map((currencyPairs) => floorPrice(amount, currencyPairName, currencyPairs, thousandSeparator)),
            ),
          ),
        ),
      ),
    );
  }
}

export const floorPrice = (
  amount: number,
  currencyPairName: string,
  currencyPairs: CurrencyPairDto[],
  thousandSeparator: string,
) => {
  const currencyPair = currencyPairs.find((currentCurrencyPair) => currentCurrencyPair.name === currencyPairName);
  if (typeof currencyPair === 'undefined') return '';
  return floor(amount, currencyPair.priceDecimalCount, thousandSeparator);
};
