import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CurrencyDto } from '@app/generated/models/currency-dto';

@Component({
  selector: 'app-qt-currency-input',
  templateUrl: './qt-currency-input.component.html',
  styleUrls: ['./qt-currency-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QtCurrencyInputComponent),
      multi: true,
    },
  ],
})
export class QtCurrencyInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() readOnly = false;
  @Input() error = false;
  @Input() isAnimated = false;
  @Input() currency?: CurrencyDto | null;
  @Input() value = '';
  @Input() isLoading = false;
  @Output() currencyButtonClick: EventEmitter<void> = new EventEmitter<void>();

  amount: string | undefined = '';

  formGroup: FormGroup = new FormGroup({
    amount: new FormControl(this.amount, Validators.required),
  });

  isTouched = false;

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((newValue) => {
      this.markAsTouched();
      this.amount = newValue.amount;
      this.onChange(newValue.amount);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.formGroup.get('amount')?.setValue(this.value);
    }
  }

  onCurrencyButtonClick() {
    this.currencyButtonClick.emit();
  }

  onChange = (_value: number) => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj === undefined || obj === null) return;
    this.markAsTouched();
    this.formGroup.setValue({ amount: obj }, { onlySelf: true });
  }

  markAsTouched() {
    if (this.isTouched) return;
    this.isTouched = true;
    this.onTouched();
  }
}
