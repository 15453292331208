import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '@app/shared/components/dropdown/base/dropdown.component';
import { PopoverPosition } from '@app/shared/components/dropdown/dropdown.enum';
import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';

@Component({
  selector: 'app-menu-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  providers: [DropdownService],
  encapsulation: ViewEncapsulation.None,
})
export class MenuDropdownComponent extends DropdownComponent {
  @Input() popoverPosition: PopoverPosition.bottomStart | PopoverPosition.bottomEnd = PopoverPosition.bottomEnd;

  /**
   * Overrides the behavior of a method from the parent class
   */
  ngAfterViewChecked() {}
}
