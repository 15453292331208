<app-link-pair
  *ngIf="(isLogged$ | async) === false"
  [appearance]="appearance"
  [linkAppearance]="linkAppearance"
  [label]="label"
  [addressLabel]="addressLabel"
  [addressIcon]="addressIcon"
  [routerLink]="routerLink"
></app-link-pair>
<app-link-pair
  *ngIf="(isLogged$ | async) === true"
  [appearance]="appearance"
  [linkAppearance]="linkAppearance"
  [label]="label"
  [addressLabel]="addressLabel"
  [addressIcon]="addressIcon"
  [addressHref]="href"
></app-link-pair>
