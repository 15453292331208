import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { Subscription } from 'rxjs';

const getColorClassname = (value: number, decimalPlaces = 2) => {
  const changeBN = BigNumber(value).decimalPlaces(decimalPlaces);
  if (changeBN.lt(0)) return 'value-negative';
  if (changeBN.gt(0)) return 'value-positive';
  return '';
};

@Component({
  selector: 'app-colored-percent-change-text',
  template: `
    <span class="{{ colorClassName }} {{ class }}">
      {{ change | appPercentageChange: decimalPlaces }}
    </span>
  `,
  styleUrls: ['./colored-value-text.component.scss'],
})
export class ColoredPercentChangeTextComponent implements OnChanges {
  @Input() change!: number;
  @Input() class?: string;
  @Input() decimalPlaces = 2;

  colorClassName = '';

  ngOnChanges() {
    this.colorClassName = getColorClassname(this.change, this.decimalPlaces);
  }
}

@Component({
  selector: 'app-colored-currency-value-text',
  template: `
    <span class="{{ colorClassName }} {{ class }}">
      {{ value | appCurrencyFloor: currency | async }} {{ currency }}
    </span>
  `,
  styleUrls: ['./colored-value-text.component.scss'],
})
export class ColoredCurrencyValueTextComponent implements OnChanges, OnDestroy {
  @Input() value!: number;
  @Input() class?: string;
  @Input() currency!: string;

  colorClassName = '';
  decimalPlaces = 2;

  private currenciesRoundingSubscription: Subscription;

  constructor(private currenciesFacade: Currencies) {
    this.currenciesRoundingSubscription = this.currenciesFacade.currenciesRounding$.subscribe((currenciesRounding) => {
      this.decimalPlaces = currenciesRounding[this.currency] || 2;
      this.colorClassName = getColorClassname(this.value, this.decimalPlaces);
    });
  }

  ngOnChanges() {
    this.colorClassName = getColorClassname(this.value, this.decimalPlaces);
  }

  ngOnDestroy() {
    this.currenciesRoundingSubscription.unsubscribe();
  }
}
