import { Component, Host, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionItemComponent } from '@app/shared/components/accordion/accordion-item/accordion-item.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: '[app-accordion-header]',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './accordion-header.component.html',
  host: {
    '[class.app-accordion-header]': 'true',
    '(click)': 'toggleItem()',
  },
  encapsulation: ViewEncapsulation.None,
})
export class AccordionHeaderComponent {
  constructor(@Host() public item: AccordionItemComponent) {}

  toggleItem() {
    this.item.toggle();
  }

  isExpanded(): boolean {
    return this.item.expanded;
  }
}
