<div>
  <label class="cm-label" *ngIf="label" for="{{ id }}">{{ label }}</label>
  <div class="flex h-14">
    <div
      *ngIf="icon"
      class="flex justify-center items-center w-16 bg-gray-100 border-t border-b border-l border-gray-300 hidden-empty"
    >
      <div>
        <svg-icon src="/assets/icons/{{ icon }}"></svg-icon>
      </div>
    </div>
    <select
      class="cm-input cm-select"
      id="{{ id }}"
      [ngClass]="{
        'border-red-500': showError,
        'border-gray-500': !value,
        'bg-gray-100 bg-selectDisabled cursor-not-allowed': disabled
      }"
      [(ngModel)]="value"
      (focusout)="onTouched()"
      [autofocus]="autofocus"
      disabled="{{ disabled }}"
    >
      <option [value]="null" selected disabled>
        {{ data || observableData ? placeholder || '' : 'No data' }}
      </option>
      <ng-container *ngIf="data">
        <option *ngFor="let item of data" value="{{ item.value }}">
          {{ item.label }}
        </option>
      </ng-container>
      <ng-container *ngIf="observableData">
        <option *ngFor="let item of observableData | async" value="{{ item.value }}">
          {{ item.label }}
        </option>
      </ng-container>
    </select>
    <div
      *ngIf="showError"
      class="block absolute top-full z-10 py-2.5 px-5 w-full text-red-500 bg-red-50 border border-red-500"
      translate
    >
      {{ errorMessage || '' }}
    </div>
    <div
      *ngIf="rightInputText"
      class="flex justify-center items-center w-16 bg-gray-100 border-t border-r border-b border-gray-300 hidden-empty"
    >
      <span class="verification-form__input-addon-text">{{ rightInputText }}</span>
    </div>
  </div>
</div>
