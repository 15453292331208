import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() searchTerm?: string;
  @Input() searchPlaceholder = '';
  @Output() searchTermChange = new EventEmitter<string>();

  onSearchTermChange(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTermChange.emit(searchTerm);
  }
}
