export const DESKTOP_BREAKPOINT = 768;
export const LIMIT_STOP_ORDER_IS_ENABLED = false;

// This flag must be TRUE if the order values `amountSum`, `commutativePrice` and `wholePartDiffers` are not calculated on the backend
// `__COMPUTE_ORDER_BOOK_ORDER_ON_FE__` is used for testing
export const COMPUTE_ORDER_BOOK_ORDER_ON_FE =
  typeof window === 'undefined'
    ? false
    : JSON.parse(window.sessionStorage?.__COMPUTE_ORDER_BOOK_ORDER_ON_FE__ || 'false');

export const HIGHLIGHT_ONLY_FIRST_OCCURRENCE_OF_PRICE_IN_LAST_TRADES =
  typeof window === 'undefined'
    ? false
    : JSON.parse(window.sessionStorage?.__HIGHLIGHT_ONLY_FIRST_OCCURRENCE_OF_PRICE_IN_LAST_TRADES__ || 'false');

export const MAXIMUM_VISIBLE_ORDERS_IN_ORDER_BOOK = 100;
export const MAXIMUM_VISIBLE_ORDERS_IN_LAST_ORDERS = 100;
export const MAXIMUM_VISIBLE_ORDERS_IN_MY_HISTORY = 500;
