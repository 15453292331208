/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BestOrderItemDto } from '../models/best-order-item-dto';

@Injectable({
  providedIn: 'root',
})
export class OrdersControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBestSellOrdersUsingGet
   */
  static readonly GetBestSellOrdersUsingGetPath = '/frontend/orders/best-sell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBestSellOrdersUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBestSellOrdersUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BestOrderItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersControllerService.GetBestSellOrdersUsingGetPath, 'get');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BestOrderItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBestSellOrdersUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBestSellOrdersUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<Array<BestOrderItemDto>> {

    return this.getBestSellOrdersUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BestOrderItemDto>>) => r.body as Array<BestOrderItemDto>)
    );
  }

  /**
   * Path part for operation getBestBuyOrdersUsingGet
   */
  static readonly GetBestBuyOrdersUsingGetPath = '/frontend/orders/best-buy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBestBuyOrdersUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBestBuyOrdersUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BestOrderItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersControllerService.GetBestBuyOrdersUsingGetPath, 'get');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BestOrderItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBestBuyOrdersUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBestBuyOrdersUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<Array<BestOrderItemDto>> {

    return this.getBestBuyOrdersUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BestOrderItemDto>>) => r.body as Array<BestOrderItemDto>)
    );
  }

}
