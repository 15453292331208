<div class="theme-toggle" (click)="toggleColorScheme()">
  <div
    class="toggle-slider"
    [ngStyle]="{ transform: isActive(ColorScheme.DARK) ? 'translateX(34px)' : 'translateX(0)' }"
  ></div>
  <button
    class="option"
    [class.text-black]="isActive(ColorScheme.LIGHT)"
    [class.text-gray-400]="!isActive(ColorScheme.LIGHT)"
  >
    <svg-icon src="/assets/icons/icon-light-mode.svg"></svg-icon>
  </button>
  <button
    class="option"
    [class.active]="isActive(ColorScheme.DARK)"
    [class.text-white]="isActive(ColorScheme.DARK)"
    [class.text-gray-400]="!isActive(ColorScheme.DARK)"
  >
    <svg-icon src="/assets/icons/icon-dark-mode.svg"></svg-icon>
  </button>
</div>
