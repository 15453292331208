import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//TODO Temporary solution. It will be reworked to websocket. https://gitlab.com/coinmate/CM/-/issues/502
@Injectable({
  providedIn: 'root',
})
export class QuickTradeOrderService {
  private currencyOrderSubject = new Subject<void>();

  currencyOrdered$ = this.currencyOrderSubject.asObservable();

  currencyOrdered() {
    this.currencyOrderSubject.next();
  }
}
