<h1
  *ngIf="type === 'h1'"
  class="cm-section-heading {{ color ? color : 'text-cm-blue-900 dark:text-app-dark-mode-white' }}"
  [id]="id"
>
  {{ subtitle }}
</h1>
<h2
  *ngIf="type === 'h2'"
  class="cm-documents-h2 {{ color ? color : 'text-cm-blue-900 dark:text-app-dark-mode-white' }}"
  [id]="id"
>
  {{ subtitle }}
</h2>
<h3
  *ngIf="type === 'h3'"
  class="cm-documents-h3 {{ color ? color : 'text-cm-blue-900 dark:text-app-dark-mode-white' }}"
  [id]="id"
>
  {{ subtitle }}
</h3>
<h4 *ngIf="type === 'h4'" class="{{ color ? color : 'text-cm-blue-900 dark:text-app-dark-mode-white' }}" [id]="id">
  {{ subtitle }}
</h4>
