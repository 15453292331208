import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as CurrencyPairs from '@app-shared/store/currency-pairs/currency-pairs.reducer';
import * as Currencies from '@app-shared/store/currencies/currencies.reducer';
import * as GuiParams from '@app-shared/store/gui-params/gui-params.reducer';
import * as UserInfo from '@app-shared/store/user-info/user-info.reducer';
import * as Balances from '@app-shared/store/balances/balances.reducer';
import { logout } from '@app/shared/store/shared.actions';

export const stateFeatureKey = 'shared-state';

export interface SharedState {
  currencyPairs: CurrencyPairs.State;
  currencies: Currencies.State;
  guiParams: GuiParams.State;
  userInfo: UserInfo.State;
  balances: Balances.State;
}

export const initialState: SharedState = {
  currencyPairs: CurrencyPairs.initialState,
  currencies: Currencies.initialState,
  guiParams: GuiParams.initialState,
  userInfo: UserInfo.initialState,
  balances: Balances.initialState,
};

export const logoutMetaReducer = (reducer: ActionReducer<SharedState>): ActionReducer<SharedState> => {
  return (state, action) => {
    if (action.type === logout.type) {
      state = {
        ...(state as SharedState),
        guiParams: GuiParams.initialState,
        userInfo: UserInfo.initialState,
        balances: Balances.initialState,
      };
    }
    return reducer(state, action);
  };
};

export const reducers: ActionReducerMap<SharedState> = {
  currencyPairs: CurrencyPairs.reducer,
  currencies: Currencies.reducer,
  guiParams: GuiParams.reducer,
  userInfo: UserInfo.reducer,
  balances: Balances.reducer,
};

export const metaReducers: MetaReducer<SharedState>[] = [logoutMetaReducer];
