import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  template: '',
})
export class AbstractComponent<T = any> implements ControlValueAccessor {
  @Input() label!: string;
  @Input() forceAutofocus?: boolean;
  @Input() disabled?: boolean;
  @Input() isValid?: boolean;
  @Input() placeholder?: string;
  @Input() errorMessage?: string;
  @Input() autofocus?: boolean;
  @Input() formControlName?: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') private _value!: T;

  id = uuidv4();

  constructor() {}

  onChange: any = () => {};
  onTouched: any = () => {};

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  get showError(): boolean {
    return !!this.errorMessage && !this.isValid;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: T): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
