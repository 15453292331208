/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OpenOrderDto } from '../models/open-order-dto';
import { OrderDto } from '../models/order-dto';
import { OrderHistoryDto } from '../models/order-history-dto';

@Injectable({
  providedIn: 'root',
})
export class UserOrdersControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOpenOrdersUsingGet
   */
  static readonly GetOpenOrdersUsingGetPath = '/frontend/user/orders/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenOrdersUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<OrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOpenOrdersUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenOrdersUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<OrderDto>> {

    return this.getOpenOrdersUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<OrderDto>>) => r.body as Array<OrderDto>)
    );
  }

  /**
   * Path part for operation getOpenOrdersForCurrencyPairOldUsingGet
   */
  static readonly GetOpenOrdersForCurrencyPairOldUsingGetPath = '/frontend/user/orders/open/{currencyPairName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenOrdersForCurrencyPairOldUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getOpenOrdersForCurrencyPairOldUsingGet$Response(params: {
    currencyPairName: string;
    limit?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<OrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOpenOrdersForCurrencyPairOldUsingGetPath, 'get');
    if (params) {
      rb.path('currencyPairName', params.currencyPairName, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenOrdersForCurrencyPairOldUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getOpenOrdersForCurrencyPairOldUsingGet(params: {
    currencyPairName: string;
    limit?: boolean;
  },
  context?: HttpContext

): Observable<Array<OrderDto>> {

    return this.getOpenOrdersForCurrencyPairOldUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<OrderDto>>) => r.body as Array<OrderDto>)
    );
  }

  /**
   * Path part for operation getOpenOrdersForCurrencyPairUsingGet
   */
  static readonly GetOpenOrdersForCurrencyPairUsingGetPath = '/frontend/user/orders/open/{accountId}/{currencyPairName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenOrdersForCurrencyPairUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersForCurrencyPairUsingGet$Response(params: {
    accountId: number;
    currencyPairName: string;
    limit?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<OpenOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOpenOrdersForCurrencyPairUsingGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('currencyPairName', params.currencyPairName, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OpenOrderDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenOrdersForCurrencyPairUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersForCurrencyPairUsingGet(params: {
    accountId: number;
    currencyPairName: string;
    limit?: boolean;
  },
  context?: HttpContext

): Observable<Array<OpenOrderDto>> {

    return this.getOpenOrdersForCurrencyPairUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<OpenOrderDto>>) => r.body as Array<OpenOrderDto>)
    );
  }

  /**
   * Path part for operation getOpenOrdersCsvUsingGet
   */
  static readonly GetOpenOrdersCsvUsingGetPath = '/frontend/user/orders/open/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenOrdersCsvUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersCsvUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOpenOrdersCsvUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenOrdersCsvUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenOrdersCsvUsingGet(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.getOpenOrdersCsvUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getOrderHistoryUsingGet
   */
  static readonly GetOrderHistoryUsingGetPath = '/frontend/user/orders/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryUsingGet$Response(params?: {
    lastId?: number;
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<OrderHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOrderHistoryUsingGetPath, 'get');
    if (params) {
      rb.query('lastId', params.lastId, {});
      rb.query('sortDirection', params.sortDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryUsingGet(params?: {
    lastId?: number;
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<Array<OrderHistoryDto>> {

    return this.getOrderHistoryUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<OrderHistoryDto>>) => r.body as Array<OrderHistoryDto>)
    );
  }

  /**
   * Path part for operation getOrderHistoryAsCsvUsingGet
   */
  static readonly GetOrderHistoryAsCsvUsingGetPath = '/frontend/user/orders/history/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryAsCsvUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryAsCsvUsingGet$Response(params?: {
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.GetOrderHistoryAsCsvUsingGetPath, 'get');
    if (params) {
      rb.query('sortDirection', params.sortDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryAsCsvUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryAsCsvUsingGet(params?: {
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<string> {

    return this.getOrderHistoryAsCsvUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation cancelAllOrdersUsingDelete
   */
  static readonly CancelAllOrdersUsingDeletePath = '/frontend/user/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelAllOrdersUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelAllOrdersUsingDelete$Response(params?: {
    currencyPairName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.CancelAllOrdersUsingDeletePath, 'delete');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelAllOrdersUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelAllOrdersUsingDelete(params?: {
    currencyPairName?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.cancelAllOrdersUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation cancelOrderUsingDelete
   */
  static readonly CancelOrderUsingDeletePath = '/frontend/user/orders/{orderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelOrderUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelOrderUsingDelete$Response(params: {
    orderId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserOrdersControllerService.CancelOrderUsingDeletePath, 'delete');
    if (params) {
      rb.path('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelOrderUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelOrderUsingDelete(params: {
    orderId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.cancelOrderUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
