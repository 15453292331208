import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from '@app/shared/components/data-table/data-table.component';
import { DataTableHeaderDirective } from '@app/shared/components/data-table/data-table-header.directive';
import { DataTableColumnDirective } from '@app/shared/components/data-table/data-table-column.directive';

@NgModule({
  declarations: [DataTableHeaderDirective, DataTableColumnDirective],
  exports: [DataTableComponent, DataTableHeaderDirective, DataTableColumnDirective],
  imports: [CommonModule, DataTableComponent],
})
export class DataTableModule {}
