<a class="{{ appearance }}" *ngIf="!(isLogged$ | async)" [routerLink]="[link | localize]">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a class="{{ appearance }}" *ngIf="isLogged$ | async" href="{{ addressHref }}">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  {{ text | translate }}
  <ng-content></ng-content>
</ng-template>
