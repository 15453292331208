import { Component, Input, OnInit } from '@angular/core';
import { CurrencyDisplayName } from '@app/authenticated/quick-trade/quick-trade.enum';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Balances } from '@app/shared/store/balances/balances-facade.service';
import { BalanceWithCurrencyEquivalents } from '@app/shared/store/balances/balances.selectors';
import { MAX_VISIBLE_CURRENCIES } from '@app/shared/const/currencies';

@Component({
  selector: 'app-context-currency-modal',
  templateUrl: './context-currency-modal.component.html',
  styleUrls: ['./context-currency-modal.component.scss'],
})
export class ContextCurrencyModalComponent implements OnInit {
  @Input() modalId = '';

  isCurrenciesListLimitedSubject = new BehaviorSubject<boolean>(true);
  searchTermSubject = new BehaviorSubject<string>('');
  currencies$: Observable<BalanceWithCurrencyEquivalents[]> = of([]);
  currenciesLimited$: Observable<BalanceWithCurrencyEquivalents[]> = of([]);
  headers = ['currencies.table.header.currency', 'balance-price'];
  lastUpdate = new Date();

  private unsubscribe$ = new Subject<void>();

  constructor(private translate: TranslateService, private balancesFacade: Balances) {
    this.balancesFacade.currencyBalancesWithEquivalents$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.lastUpdate = new Date();
        }),
      )
      .subscribe();
    this.currencies$ = this.balancesFacade.currencyBalancesWithEquivalents$.pipe(
      map((currencies) => currencies.filter(this.currenciesFilter.bind(this))),
    );
    this.currenciesLimited$ = combineLatest([
      this.balancesFacade.currencyBalancesWithEquivalents$,
      this.isCurrenciesListLimitedSubject,
      this.searchTermSubject,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([currencies, isCurrenciesListLimited, searchTerm]) => {
        const currenciesFiltered = currencies.filter(this.currenciesFilter.bind(this));
        const currenciesLimited =
          isCurrenciesListLimited && !searchTerm
            ? currenciesFiltered.slice(0, MAX_VISIBLE_CURRENCIES)
            : currenciesFiltered;

        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return currenciesLimited.filter((currency) => {
          return (
            currency.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            this.getCurrencyDisplayName(currency.name)
              // next two lines are for diacritics insensitive search
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(lowerCaseSearchTerm) ||
            this.getCurrencyDisplayName(currency.name).toLowerCase().includes(lowerCaseSearchTerm)
          );
        });
      }),
      map((currenciesFiltered) => {
        return currenciesFiltered.sort((a, b) => {
          return (
            (b.name === 'CZK' ? 1 : 0) - (a.name === 'CZK' ? 1 : 0) ||
            (b.name === 'EUR' ? 1 : 0) - (a.name === 'EUR' ? 1 : 0) ||
            b.fiatEquivalentsAvailable.CZK - a.fiatEquivalentsAvailable.CZK
          );
        });
      }),
    );
  }

  ngOnInit() {
    this.balancesFacade.load();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showAllCurrencies() {
    this.isCurrenciesListLimitedSubject.next(false);
  }

  onSearchTermChange(searchTerm: string) {
    this.searchTermSubject.next(searchTerm);
  }

  getCurrencyDisplayName(currencyCode: string): string {
    const displayName = CurrencyDisplayName[currencyCode as keyof typeof CurrencyDisplayName];
    return displayName === 'crypto.CZK' ? this.translate.instant(displayName) : displayName;
  }

  refreshData() {
    this.balancesFacade.load();
  }

  private currenciesFilter(currency: BalanceWithCurrencyEquivalents) {
    return currency.available > 0 || currency.inOpenOrders > 0;
  }
}
