import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Optional component that visually separates different sections in the menu.
 */

@Component({
  selector: 'div[menu-vertical-section]',
  templateUrl: './menu-vertical-section.component.html',
  styleUrls: ['../menu-vertical.component.scss'],
  host: {
    '[class.menu-vertical-section]': 'true',
    '[class.menu-vertical-section-center]': 'justifyCenter',
  },
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class MenuVerticalSectionComponent {
  @Input() justifyCenter? = false;
}
