import { Action, createReducer, on } from '@ngrx/store';
import { GuiParamsDto } from '@app-generated/models/gui-params-dto';
import { loadGuiParamsFailure, loadGuiParamsLoading, loadGuiParamsSuccess } from './gui-params.actions';

export enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export type State = {
  data: GuiParamsDto;
  status: ApiStateStatus;
};

export const initialState: State = {
  data: {
    appState: 'anonymous',
    csrfToken: '',
    supportRecaptchaSiteKey: '',
    version: '',
    environment: '',
    features: [],
    gtm: [],
    depositBlocked: false,
    depositVerified: false,
    withdrawalBlocked: false,
    bankidRegistrationBlocked: false,
  },
  status: ApiStateStatus.pending,
};

const guiParamsReducer = createReducer(
  initialState,
  on(loadGuiParamsSuccess, (state, { guiParams }) => ({
    status: ApiStateStatus.success,
    data: { ...state.data, ...guiParams },
  })),

  on(loadGuiParamsLoading, (state) => ({
    ...state,
    status: ApiStateStatus.loading,
  })),

  on(loadGuiParamsFailure, (state) => ({
    ...state,
    status: ApiStateStatus.error,
  })),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return guiParamsReducer(state, action);
}
