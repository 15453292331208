import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-icon-link',
  templateUrl: './menu-icon-link.component.html',
  styleUrls: ['./menu-icon-link.component.scss'],
})
export class MenuIconLinkComponent {
  @Input() link: string | string[] | undefined;
  @Input() tooltip: string | undefined;
}
