import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { SvgIconComponent } from 'angular-svg-icon';
import { BaseModalComponent } from '@app/shared/components/modal/base-modal/base-modal.component';

@Component({
  selector: 'app-components-overview-modal',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, BaseModalComponent, SharedModule],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() modalId = '';
  @Input() modalData: {
    size: 'x-large' | 'large' | 'medium' | 'small';
    fixedHeight: boolean;
  } = {
    size: 'large',
    fixedHeight: true,
  };
}
