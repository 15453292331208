<app-base-modal
  [id]="modalId"
  [title]="'shared.common.confirm' | translate"
  [size]="'small'"
  [isHiddenTitle]="true"
  (closeModal)="onClose()"
>
  <ng-container modal-header-icon>
    <svg-icon [src]="'/assets/icons/icon-info.svg'"></svg-icon>
  </ng-container>
  <app-base-modal-body *ngIf="modalData.modalBody">
    <div [innerHTML]="modalData.modalBody"></div>
  </app-base-modal-body>

  <app-base-modal-footer>
    <app-action-button
      [label]="modalData.cancelLabel || 'shared.common.cancel'"
      [size]="'small'"
      [facet]="'tertiary'"
      (click)="onCancel()"
    >
    </app-action-button>
    <app-action-button
      [label]="modalData.confirmLabel || 'shared.common.confirm'"
      [size]="'small'"
      [facet]="'danger'"
      (click)="onConfirm()"
    >
    </app-action-button>
  </app-base-modal-footer>
</app-base-modal>
