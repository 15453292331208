import { Action, createReducer, on } from '@ngrx/store';
import { setBalances, updateBalance } from '@app/shared/store/balances/balances.actions';
import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';

export type State = { [key: string]: CurrencyBalanceWithEquivalentsDto };

export const initialState: State = {};

const balancesReducer = createReducer(
  initialState,
  on(setBalances, (state, action) => action.balances),

  on(updateBalance, (state, action) => {
    return Object.entries(state || {}).reduce((acc, [currency, balance]) => {
      const updatedBalance = action.balances[currency];
      if (updatedBalance) {
        acc[currency] = {
          ...balance,
          inOpenOrders: updatedBalance.reserved,
          totalBalance: updatedBalance.balance,
          available: updatedBalance.balance - updatedBalance.reserved,
        };
      } else {
        acc[currency] = balance;
      }
      return acc;
    }, {} as State);
  }),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return balancesReducer(state, action);
}
