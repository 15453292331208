<div
  *ngIf="isOpen"
  class="fixed z-5000 inset-0 overflow-hidden"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="modal-backdrop"></div>
  <div class="modal-container" (click)="clickOutside($event)" #modalContainer>
    <div
      class="modal"
      [class]="modalClass"
      [ngClass]="{
        'modal-small': size === 'small',
        'modal-medium': size === 'medium',
        'modal-large': size === 'large',
        'modal-x-large': size === 'x-large',
        'modal-fixed-height': isFixedHeight,
        'modal-body-zero-spacing': isBodyZeroSpacing
      }"
    >
      <div *ngIf="title || !isCloseDisabled" class="modal-header">
        <div class="flex gap-4 items-center">
          <ng-content select="[modal-header-icon]"></ng-content>
          <h3 class="modal-title" id="modal-title" [ngClass]="{ 'sr-only': isHiddenTitle }">
            {{ title }}
          </h3>
          <button *ngIf="!isCloseDisabled" class="ml-[auto] mr-0 self-start" (click)="close()">
            <svg-icon src="/assets/icons/icon-modal-close.svg"></svg-icon>
          </button>
        </div>
        <div class="modal-header-content">
          <ng-content select="[modal-header]"></ng-content>
        </div>
      </div>

      <div class="modal-body-scroll-area">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
