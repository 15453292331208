import { Directive, ElementRef } from '@angular/core';

import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';

@Directive({
  selector: '[dropdown-trigger]:not(button)',
  standalone: true,
  host: {
    '(click)': 'togglePopover()',
    '[class]': 'baseClassName',
  },
})
export class DropdownTriggerDirective {
  constructor(public element: ElementRef, protected dropdownService: DropdownService) {}

  protected baseClassName = 'dropdown-trigger';

  togglePopover() {
    this.dropdownService.toggleDropdown();
  }
}
