import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyDto } from '@app-generated/models/currency-dto';
import { setCurrencies } from '@app-shared/store/currencies/currencies.actions';

export type State = CurrencyDto[];

export const initialState: State = [];

const currenciesReducer = createReducer(
  initialState,
  on(setCurrencies, (state, action) => action.currencies),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return currenciesReducer(state, action);
}
