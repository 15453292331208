import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class DropdownService implements OnDestroy {
  isVisible$ = new BehaviorSubject<boolean>(false);
  dropdownId = '';

  private unsubscribe$ = new Subject<void>();

  constructor(private dropdownPopoverService: DropdownPopoverService) {
    this.dropdownId = uuidv4();

    this.dropdownPopoverService.activeDropdownId$.pipe(takeUntil(this.unsubscribe$)).subscribe((activeDropdownId) => {
      this.isVisible$.next(this.dropdownId === activeDropdownId);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDropdown() {
    this.dropdownPopoverService.open(this.dropdownId);
  }

  closeDropdown() {
    this.dropdownPopoverService.close();
  }

  toggleDropdown() {
    if (this.isVisible$.getValue()) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }
}
