import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pick } from 'ramda';
import { CSRF_HEADER_NAME } from '@app/shared/const/csrf.const';
import { mergeMap } from 'rxjs/operators';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { TwoFactorType } from '@app/authentication/shared/api/two-factor.api';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorService {
  private requestsFor2FaVerification: Map<string, HttpRequest<any>> = new Map();

  constructor(private http: HttpClient, private guiParams: GuiParams) {}

  saveRequest(url: string, httpRequest: HttpRequest<any>): void {
    this.requestsFor2FaVerification.set(url, httpRequest);
  }

  popRequest(url: string): HttpRequest<any> | undefined {
    const result = this.requestsFor2FaVerification.get(url);
    this.requestsFor2FaVerification.delete(url);
    return result;
  }

  redoRequestWith2FA(originalRequest: HttpRequest<any>, value: string, addCsrf: boolean = false): Observable<any> {
    let headers = originalRequest.headers.set('TWO-FACTOR-DATA', value);
    if (addCsrf) {
      // we need to obtain current csrf token
      return this.guiParams.getCurrentAndSaveToStore().pipe(
        mergeMap((params) => {
          headers = headers.set(CSRF_HEADER_NAME, params?.csrfToken || '');
          return this.redoRequestWithWithCustomHeaders(originalRequest, headers);
        }),
      );
    } else {
      return this.redoRequestWithWithCustomHeaders(originalRequest, headers);
    }
  }

  isNewDeviceConfirmationType(error: HttpErrorResponse): boolean {
    return error.error.additionalInfo.twoFactorType === TwoFactorType.deviceConfirmation;
  }

  private redoRequestWithWithCustomHeaders(originalRequest: HttpRequest<any>, headers: HttpHeaders): Observable<any> {
    return this.http.request(originalRequest.method, originalRequest.url, {
      ...pick(['body', 'params', 'responseType', 'reportProgress', 'withCredentials'], originalRequest),
      headers,
    });
  }
}
