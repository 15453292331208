import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[double-section-page-template-title]',
  templateUrl: './double-section-page-template-title.component.html',
  styleUrls: ['../double-section-page-template.component.scss'],
  host: {
    '[class.double-section-page-title]': 'true',
    '[class.double-section-page-title-h1]': 'type === "h1"',
    '[class.double-section-page-title-h2]': 'type === "h2"',
    '[class.double-section-page-title-h3]': 'type === "h3"',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DoubleSectionPageTemplateTitleComponent {
  @Input()
  type: 'h1' | 'h2' | 'h3' = 'h2';
}
