/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TransactionHistoryDto } from '../models/transaction-history-dto';

@Injectable({
  providedIn: 'root',
})
export class UserTransactionsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTransactionHistoryUsingGet
   */
  static readonly GetTransactionHistoryUsingGetPath = '/frontend/user/transactions/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionHistoryUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionHistoryUsingGet$Response(params?: {
    offset?: number;
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TransactionHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserTransactionsControllerService.GetTransactionHistoryUsingGetPath, 'get');
    if (params) {
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TransactionHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactionHistoryUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionHistoryUsingGet(params?: {
    offset?: number;
    sortDirection?: 'ASCENDING' | 'DESCENDING';
  },
  context?: HttpContext

): Observable<Array<TransactionHistoryDto>> {

    return this.getTransactionHistoryUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TransactionHistoryDto>>) => r.body as Array<TransactionHistoryDto>)
    );
  }

  /**
   * Path part for operation redirectToDetailUsingGet
   */
  static readonly RedirectToDetailUsingGetPath = '/frontend/user/transactions/history/{transferId}/redirect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectToDetailUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToDetailUsingGet$Response(params: {
    transferId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserTransactionsControllerService.RedirectToDetailUsingGetPath, 'get');
    if (params) {
      rb.path('transferId', params.transferId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `redirectToDetailUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToDetailUsingGet(params: {
    transferId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.redirectToDetailUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTransactionHistoryAsCsvUsingGet
   */
  static readonly GetTransactionHistoryAsCsvUsingGetPath = '/frontend/user/transactions/history/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionHistoryAsCsvUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionHistoryAsCsvUsingGet$Response(params?: {
    type?: 'ACTIVE_ACCOUNT_ONLY' | 'ALL_ACCOUNTS' | 'ALL_FROM_ARCHIVE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserTransactionsControllerService.GetTransactionHistoryAsCsvUsingGetPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactionHistoryAsCsvUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionHistoryAsCsvUsingGet(params?: {
    type?: 'ACTIVE_ACCOUNT_ONLY' | 'ALL_ACCOUNTS' | 'ALL_FROM_ARCHIVE';
  },
  context?: HttpContext

): Observable<string> {

    return this.getTransactionHistoryAsCsvUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation cancelTransactionUsingDelete
   */
  static readonly CancelTransactionUsingDeletePath = '/frontend/user/transactions/{transferId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelTransactionUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTransactionUsingDelete$Response(params: {
    transferId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserTransactionsControllerService.CancelTransactionUsingDeletePath, 'delete');
    if (params) {
      rb.path('transferId', params.transferId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelTransactionUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTransactionUsingDelete(params: {
    transferId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.cancelTransactionUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
