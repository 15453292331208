export const isUserCzech = (): boolean => {
  return typeof navigator === 'undefined' ? false : navigator.language.includes('cs');
};

export const emailRegexp = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

export const passwordRegexp = /^(?=.*?\p{Ll})(?=.*?\p{Lu})(?=.*\d).{9,}$/u;

export const phoneNumberRegex = /^[+]?\d+$/;

export const capitalizeFirstLetter = (stringToCapitalize: string): string => {
  return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
};

export const separateThousands: (x: string) => string = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');
};

export const isString = (value: any) => {
  return typeof value === 'string' || value instanceof String;
};

export const roundNumber = (num: number, decimals: number) => {
  return Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
};
