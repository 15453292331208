/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DepositAdressesResultDto } from '../models/deposit-adresses-result-dto';
import { DepositConfirmStepsDto } from '../models/deposit-confirm-steps-dto';
import { DepositConfirmationDto } from '../models/deposit-confirmation-dto';
import { DepositConfirmationResultDto } from '../models/deposit-confirmation-result-dto';
import { DepositMethodDto } from '../models/deposit-method-dto';
import { GenerateAddressDto } from '../models/generate-address-dto';
import { TransferStatusChangeDto } from '../models/transfer-status-change-dto';

@Injectable({
  providedIn: 'root',
})
export class UserDepositControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDepositMethodUsingGet
   */
  static readonly GetDepositMethodUsingGetPath = '/frontend/user/deposits/{currencyCode}/methods/{method}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepositMethodUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositMethodUsingGet$Response(params: {
    currencyCode: string;
    method: string;
    withQuickBuy?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DepositMethodDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.GetDepositMethodUsingGetPath, 'get');
    if (params) {
      rb.path('currencyCode', params.currencyCode, {});
      rb.path('method', params.method, {});
      rb.query('withQuickBuy', params.withQuickBuy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepositMethodDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDepositMethodUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositMethodUsingGet(params: {
    currencyCode: string;
    method: string;
    withQuickBuy?: string;
  },
  context?: HttpContext

): Observable<DepositMethodDto> {

    return this.getDepositMethodUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<DepositMethodDto>) => r.body as DepositMethodDto)
    );
  }

  /**
   * Path part for operation depositConfirmationUsingPost
   */
  static readonly DepositConfirmationUsingPostPath = '/frontend/user/deposits/{currencyCode}/methods/{method}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `depositConfirmationUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositConfirmationUsingPost$Response(params: {
    currencyCode: string;
    method: string;
    body: DepositConfirmationDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DepositConfirmationResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.DepositConfirmationUsingPostPath, 'post');
    if (params) {
      rb.path('currencyCode', params.currencyCode, {});
      rb.path('method', params.method, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepositConfirmationResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `depositConfirmationUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositConfirmationUsingPost(params: {
    currencyCode: string;
    method: string;
    body: DepositConfirmationDto
  },
  context?: HttpContext

): Observable<DepositConfirmationResultDto> {

    return this.depositConfirmationUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<DepositConfirmationResultDto>) => r.body as DepositConfirmationResultDto)
    );
  }

  /**
   * Path part for operation confirmStepsUsingPost
   */
  static readonly ConfirmStepsUsingPostPath = '/frontend/user/deposits/confirmSteps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmStepsUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmStepsUsingPost$Response(params: {
    body: DepositConfirmStepsDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.ConfirmStepsUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmStepsUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmStepsUsingPost(params: {
    body: DepositConfirmStepsDto
  },
  context?: HttpContext

): Observable<void> {

    return this.confirmStepsUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation generateAddressUsingPost
   */
  static readonly GenerateAddressUsingPostPath = '/frontend/user/deposits/address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAddressUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateAddressUsingPost$Response(params: {
    body: GenerateAddressDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DepositAdressesResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.GenerateAddressUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepositAdressesResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateAddressUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateAddressUsingPost(params: {
    body: GenerateAddressDto
  },
  context?: HttpContext

): Observable<DepositAdressesResultDto> {

    return this.generateAddressUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<DepositAdressesResultDto>) => r.body as DepositAdressesResultDto)
    );
  }

  /**
   * Path part for operation getDepositMethodsUsingGet
   */
  static readonly GetDepositMethodsUsingGetPath = '/frontend/user/deposits/{currencyCode}/methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepositMethodsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositMethodsUsingGet$Response(params: {
    currencyCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DepositMethodDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.GetDepositMethodsUsingGetPath, 'get');
    if (params) {
      rb.path('currencyCode', params.currencyCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepositMethodDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDepositMethodsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositMethodsUsingGet(params: {
    currencyCode: string;
  },
  context?: HttpContext

): Observable<Array<DepositMethodDto>> {

    return this.getDepositMethodsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DepositMethodDto>>) => r.body as Array<DepositMethodDto>)
    );
  }

  /**
   * Path part for operation getDepositStateUsingGet
   */
  static readonly GetDepositStateUsingGetPath = '/frontend/user/deposits/{currencyCode}/methods/{method}/state/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepositStateUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositStateUsingGet$Response(params: {
    currencyCode: string;
    method: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TransferStatusChangeDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.GetDepositStateUsingGetPath, 'get');
    if (params) {
      rb.path('currencyCode', params.currencyCode, {});
      rb.path('method', params.method, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransferStatusChangeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDepositStateUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepositStateUsingGet(params: {
    currencyCode: string;
    method: string;
    id: string;
  },
  context?: HttpContext

): Observable<TransferStatusChangeDto> {

    return this.getDepositStateUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TransferStatusChangeDto>) => r.body as TransferStatusChangeDto)
    );
  }

  /**
   * Path part for operation getAddressesUsingGet
   */
  static readonly GetAddressesUsingGetPath = '/frontend/user/deposits/{currencyCode}/addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressesUsingGet$Response(params: {
    currencyCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DepositAdressesResultDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserDepositControllerService.GetAddressesUsingGetPath, 'get');
    if (params) {
      rb.path('currencyCode', params.currencyCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepositAdressesResultDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressesUsingGet(params: {
    currencyCode: string;
  },
  context?: HttpContext

): Observable<Array<DepositAdressesResultDto>> {

    return this.getAddressesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DepositAdressesResultDto>>) => r.body as Array<DepositAdressesResultDto>)
    );
  }

}
