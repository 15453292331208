const baseUrl = typeof process !== 'undefined' ? process.env.BASE_URL || 'https://coinmate.io' : '';

export const environment = {
  production: true,
  baseUrl,
  apiRoot: baseUrl,
  npmServerRoot: baseUrl + '/landing',
  cmsServerRoot: baseUrl + '/strapi',
  forceErrorReporting: false,
};
