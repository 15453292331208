import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[dropdown-item]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown-item.component.html',
  host: {
    '[class]': 'baseClassName',
    '[attr.data-dropdown-facet]': 'facet',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DropdownItemComponent {
  @Input() facet?: 'danger' | undefined;

  protected baseClassName = 'dropdown-item';

  constructor(public element: ElementRef) {}
}
