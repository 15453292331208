import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class LocalizedRouterService {
  constructor(private router: Router, private localize: LocalizeRouterService) {}

  get url() {
    return this.router.url;
  }

  navigateByUrl(url: string): Promise<boolean> {
    const [queryUrl, fragment] = url.split('#');
    const [baseUrl, queryParams] = queryUrl.split('?');

    let localizedUrl = this.localize.translateRoute(baseUrl);

    if (localizedUrl instanceof Array) {
      return this.router.navigate([localizedUrl]);
    } else {
      if (queryParams) localizedUrl += '?' + queryParams;
      if (fragment) localizedUrl += '#' + fragment;
      return this.router.navigateByUrl(localizedUrl);
    }
  }
}
