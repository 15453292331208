/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyPairDto } from '../models/currency-pair-dto';
import { CurrencyPairHomepageTradingStatsDto } from '../models/currency-pair-homepage-trading-stats-dto';
import { CurrencyPairWithStatsDto } from '../models/currency-pair-with-stats-dto';

@Injectable({
  providedIn: 'root',
})
export class CurrencyPairsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCurrencyPairsUsingGet
   */
  static readonly GetCurrencyPairsUsingGetPath = '/frontend/currency-pairs';

  /**
   * Get all available currency pairs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrencyPairsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencyPairsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CurrencyPairsControllerService.GetCurrencyPairsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairDto>>;
      })
    );
  }

  /**
   * Get all available currency pairs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrencyPairsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencyPairsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairDto>> {

    return this.getCurrencyPairsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairDto>>) => r.body as Array<CurrencyPairDto>)
    );
  }

  /**
   * Path part for operation getCurrencyPairsWithStatsUsingGet
   */
  static readonly GetCurrencyPairsWithStatsUsingGetPath = '/frontend/currency-pairs/stats';

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrencyPairsWithStatsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencyPairsWithStatsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairWithStatsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CurrencyPairsControllerService.GetCurrencyPairsWithStatsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairWithStatsDto>>;
      })
    );
  }

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrencyPairsWithStatsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencyPairsWithStatsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairWithStatsDto>> {

    return this.getCurrencyPairsWithStatsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairWithStatsDto>>) => r.body as Array<CurrencyPairWithStatsDto>)
    );
  }

  /**
   * Path part for operation getHomepageTradingStatsForCurrencyPairUsingGet
   */
  static readonly GetHomepageTradingStatsForCurrencyPairUsingGetPath = '/frontend/currency-pairs/homepage-stats/{currencyPairName}';

  /**
   * Returns all trading stats required on homepage for given currency pair name
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHomepageTradingStatsForCurrencyPairUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHomepageTradingStatsForCurrencyPairUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CurrencyPairHomepageTradingStatsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CurrencyPairsControllerService.GetHomepageTradingStatsForCurrencyPairUsingGetPath, 'get');
    if (params) {
      rb.path('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrencyPairHomepageTradingStatsDto>;
      })
    );
  }

  /**
   * Returns all trading stats required on homepage for given currency pair name
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHomepageTradingStatsForCurrencyPairUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHomepageTradingStatsForCurrencyPairUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<CurrencyPairHomepageTradingStatsDto> {

    return this.getHomepageTradingStatsForCurrencyPairUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<CurrencyPairHomepageTradingStatsDto>) => r.body as CurrencyPairHomepageTradingStatsDto)
    );
  }

}
