/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserInfoDto } from '../models/user-info-dto';

@Injectable({
  providedIn: 'root',
})
export class UserInfoControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getInfoUsingGet
   */
  static readonly GetInfoUsingGetPath = '/frontend/user/info';

  /**
   * Get authenticated user's personal information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfoUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserInfoControllerService.GetInfoUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoDto>;
      })
    );
  }

  /**
   * Get authenticated user's personal information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInfoUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoUsingGet(params?: {
  },
  context?: HttpContext

): Observable<UserInfoDto> {

    return this.getInfoUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserInfoDto>) => r.body as UserInfoDto)
    );
  }

  /**
   * Path part for operation getCurrentStepUsingGet
   */
  static readonly GetCurrentStepUsingGetPath = '/frontend/user/info/verification-flow-step';

  /**
   * Get authenticated user's current step in verification flow
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentStepUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStepUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>> {

    const rb = new RequestBuilder(this.rootUrl, UserInfoControllerService.GetCurrentStepUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>;
      })
    );
  }

  /**
   * Get authenticated user's current step in verification flow
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentStepUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStepUsingGet(params?: {
  },
  context?: HttpContext

): Observable<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'> {

    return this.getCurrentStepUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>) => r.body as 'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT')
    );
  }

  /**
   * Path part for operation getUserTradedVolumeUsingGet
   */
  static readonly GetUserTradedVolumeUsingGetPath = '/frontend/user/info/user-traded-volume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserTradedVolumeUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTradedVolumeUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, UserInfoControllerService.GetUserTradedVolumeUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserTradedVolumeUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTradedVolumeUsingGet(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.getUserTradedVolumeUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getAvatarUsingGet
   */
  static readonly GetAvatarUsingGetPath = '/frontend/user/info/avatar';

  /**
   * Get authenticated user's avatar
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvatarUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatarUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserInfoControllerService.GetAvatarUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get authenticated user's avatar
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvatarUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatarUsingGet(params?: {
  },
  context?: HttpContext

): Observable<any> {

    return this.getAvatarUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
