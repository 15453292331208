import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { LanguageService } from '@app/shared/services/language.service';
import { USER_PROFILE_LOCALE_DEFAULTS } from '@app/shared/const/user-locale.const';

const USER_PREFERENCES_IS_DISABLED = true;

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform, OnDestroy {
  private dateFormat = '';
  private currentLanguage = 'cs';
  private unsubscribe$ = new Subject<void>();

  constructor(private languageService: LanguageService, private userInfo: UserInfo) {
    if (!this.dateFormat) {
      combineLatest([this.languageService.currentLanguage$, this.userInfo.userInfo$])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([currentLanguage, profileInfo]) => {
          this.currentLanguage = currentLanguage;

          if (USER_PREFERENCES_IS_DISABLED) {
            this.dateFormat = currentLanguage === 'cs' ? 'dd.MM.yyyy HH:mm' : 'd MMM yyyy HH:mm';
          } else {
            if (profileInfo.profile.locale?.dateFormat) {
              this.dateFormat = profileInfo.profile.locale.dateFormat;
            } else {
              this.dateFormat = USER_PROFILE_LOCALE_DEFAULTS.dateFormat;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  transform(value: any, pattern: string = this.dateFormat) {
    const datePipe: DatePipe = new DatePipe(this.currentLanguage);
    return datePipe.transform(value, pattern || 'mediumDate');
  }
}
