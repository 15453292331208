import { Directive } from '@angular/core';
import { ModalService } from '@app/shared/components/modal/modal.service';

@Directive({
  selector: '[modal-close]',
  standalone: true,
  host: {
    '(click)': 'closeModal()',
  },
})
export class BaseModalDirective {
  constructor(private modalService: ModalService) {}

  closeModal() {
    this.modalService.closeActiveModal();
    this.modalService.closeActiveDynamicModal();
  }
}
