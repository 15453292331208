import { Directive, HostBinding } from '@angular/core';
import { CdkCell, CdkFooterCell, CdkHeaderCell } from '@angular/cdk/table';

/** Cell template container that adds the right classes and role. */
@Directive({
  selector: 'app-cell, td[appCell]',
})
export class CellDirective extends CdkCell {}

/** Header cell template container that adds the right classes and role. */
@Directive({
  selector: 'app-header-cell, th[appHeaderCell]',
})
export class HeaderCellDirective extends CdkHeaderCell {
  @HostBinding('role') role = 'columnHeader';
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
  selector: 'app-footer-cell, th[appFooterCell]',
})
export class FooterCellDirective extends CdkFooterCell {}
