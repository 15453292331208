import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[menu-dropdown-icon-left], [menu-dropdown-icon-right]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-dropdown-icon.component.html',
  host: {
    '[class.menu-dropdown-item-icon]': 'true',
  },
  encapsulation: ViewEncapsulation.None,
})
export class MenuDropdownIconComponent {}
