import { ChangeDetectorRef, Component, Inject, Input, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { AccordionBase, APP_ACCORDION } from '@app/shared/components/accordion/accordion-base';
import {
  AccordionItemBase,
  APP_ACCORDION_ITEM,
} from '@app/shared/components/accordion/accordion-item/accordion-item-base';
import { NgClass } from '@angular/common';

@Component({
  selector: '[app-accordion-item]',
  templateUrl: './accordion-item.component.html',
  providers: [
    { provide: APP_ACCORDION, useValue: undefined },
    { provide: APP_ACCORDION_ITEM, useExisting: AccordionItemComponent },
  ],
  standalone: true,
  host: {
    '[class.app-accordion-item]': 'true',
    '[attr.role]': "'button'",
    '[attr.aria-expanded]': 'expanded',
    '[attr.aria-controls]': "'accordion-body-' + getIndex()",
  },
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class AccordionItemComponent extends CdkAccordionItem implements AccordionItemBase {
  override accordion!: AccordionBase;

  constructor(
    @Optional() @SkipSelf() @Inject(APP_ACCORDION) accordion: AccordionBase,
    _changeDetectorRef: ChangeDetectorRef,
    _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
  ) {
    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
  }

  getIndex() {
    return this.accordion?.itemComponents?.toArray().findIndex(({ id }) => id === this.id);
  }
}
