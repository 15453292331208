import { IL10nsStrings } from 'ngx-timeago';

export const enStrings: IL10nsStrings = {
  suffixFromNow: 'from now',
  seconds: 'now',
  minute: '%d min',
  minutes: '%d mins',
  hour: '%d hr',
  hours: '%d hrs',
  day: '%d day',
  days: '%d days',
  week: '%d wk',
  weeks: '%d wks',
  month: '%d mo',
  months: '%d mos',
  year: '%d yr',
  years: '%d yrs',
  wordSeparator: ' ',
};

export const csStrings: IL10nsStrings = {};

export const deStrings: IL10nsStrings = {
  seconds: 'jezt',
  minutes: '%d Min',
  hours: '%d St',
  days: '%d Tg',
  weeks: '%d Wo',
  months: '%d Mo',
  year: '%d Jahr',
  years: '%d Jahren',
  wordSeparator: ' ',
};
