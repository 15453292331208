<ng-container *ngIf="!link && children; else routerLink">
  <app-menu-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
    <button
      class="cm-button cm-button-menu"
      [ngClass]="{ 'has-outline': hasOutline }"
      [withChevron]="true"
      menu-dropdown-trigger
    >
      <ng-container *ngIf="icon" menu-dropdown-icon-left>
        <svg-icon [src]="icon"></svg-icon>
      </ng-container>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <ng-container *ngFor="let subLink of children">
      <ng-container *ngIf="subLink.url?.startsWith('http'); else internalSubLink">
        <a [href]="subLink.url" [ngClass]="router.url.endsWith(subLink.url || '') ? 'active' : ''" menu-dropdown-item>
          {{ subLink.name | translate }}
        </a>
      </ng-container>

      <ng-template #internalSubLink>
        <a
          [routerLink]="[subLink.url | localize]"
          [ngClass]="router.url.endsWith(subLink.url || '') ? 'active' : ''"
          menu-dropdown-item
        >
          {{ subLink.name | translate }}
        </a>
      </ng-template>
    </ng-container>
  </app-menu-dropdown>
</ng-container>

<ng-template #routerLink>
  <a
    [routerLink]="link"
    [routerLinkActive]="'cm-button-active'"
    class="cm-button cm-button-menu"
    [ngClass]="{ 'has-outline': hasOutline }"
  >
    <svg-icon *ngIf="icon" [src]="icon"></svg-icon>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
