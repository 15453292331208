import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccordionComponent } from '@app/shared/components/accordion/accordion.component';
import { AccordionItemComponent } from '@app/shared/components/accordion/accordion-item/accordion-item.component';
import { AccordionHeaderComponent } from '@app/shared/components/accordion/accordion-header/accordion-header.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [],
  exports: [AccordionComponent, AccordionItemComponent, AccordionHeaderComponent],
  imports: [CommonModule, CdkAccordionModule, AccordionComponent, AccordionItemComponent, AccordionHeaderComponent],
})
export class AccordionModule {}
