import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';
import { CurrencyPairsControllerService } from '@app-generated/services/currency-pairs-controller.service';
import {
  loadCurrencyPairs,
  loadCurrencyPairsWithStats,
  loadFavouriteCurrencyPairs,
  setCurrencyPairs,
  setCurrencyPairsWithStats,
  setFavouriteCurrencyPairs,
  updateCurrencyPairWithStats,
  updateCurrencyPairWithStatsTemporaryEndpointForAllCurrencies,
} from '@app-shared/store/currency-pairs/currency-pairs.actions';
import { UserCurrencyPairFavouritesControllerService } from '@app-generated/services/user-currency-pair-favourites-controller.service';
import { Injectable } from '@angular/core';
import { PushpinService } from '@app-shared/services/pushpin.service';
import { QuickTradeControllerService } from '@app/generated/services/quick-trade-controller.service';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';

const IS_TEMPORARY_ENDPOINT_FOR_ALL_CURRENCY_PAIRS_USED = false;

@Injectable()
export class CurrencyPairsEffects {
  loadCurrencyPairsWithStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencyPairsWithStats),
      withLatestFrom(this.guiParams.loggedIn$),
      mergeMap(([action, isLoggedIn]) => {
        if (IS_TEMPORARY_ENDPOINT_FOR_ALL_CURRENCY_PAIRS_USED && isLoggedIn) {
          return this.quickTradeControllerService.getQuickTradeCurrencyPairsWithStatsUsingGet().pipe(
            map((currencyPairsWithStats) => setCurrencyPairsWithStats({ currencyPairsWithStats })),
            catchError(() => EMPTY),
          );
        }
        return this.currencyPairsController.getCurrencyPairsWithStatsUsingGet().pipe(
          map((currencyPairsWithStats) => setCurrencyPairsWithStats({ currencyPairsWithStats })),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  loadCurrencyPairsWithStatsWs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrencyPairsWithStats),
      withLatestFrom(this.guiParams.loggedIn$),
      mergeMap(([action, isLogged]) => {
        if (IS_TEMPORARY_ENDPOINT_FOR_ALL_CURRENCY_PAIRS_USED && isLogged) {
          return this.pushpinService.getCurrencyPairWithStats$().pipe(
            switchMap((currencyPairWithStatsPushPin) => {
              return this.quickTradeControllerService.getQuickTradeCurrencyPairsWithStatsUsingGet().pipe(
                map((currencyPairsWithStatsQT) =>
                  updateCurrencyPairWithStatsTemporaryEndpointForAllCurrencies({
                    currencyPairWithStats: currencyPairsWithStatsQT,
                  }),
                ),
              );
            }),
            catchError(() => EMPTY),
            takeUntil(this.actions$.pipe(ofType(loadCurrencyPairsWithStats))),
          );
        } else {
          return combineLatest([
            this.currencyPairsController.getCurrencyPairsWithStatsUsingGet(),
            this.pushpinService.getCurrencyPairWithStats$(),
          ]).pipe(
            map(([_currencyPairsWithStats, currencyPairWithStats]) =>
              updateCurrencyPairWithStats({ currencyPairWithStats }),
            ),
            catchError(() => EMPTY),
            takeUntil(this.actions$.pipe(ofType(loadCurrencyPairsWithStats))),
          );
        }
      }),
    ),
  );

  loadCurrencyPairs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencyPairs),
      mergeMap(() =>
        this.currencyPairsController.getCurrencyPairsUsingGet().pipe(
          map((currencyPairs) => setCurrencyPairs({ currencyPairs })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadFavouriteCurrencyPairs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFavouriteCurrencyPairs),
      mergeMap(() =>
        this.favouritePairsController.getFavouritesUsingGet().pipe(
          map((favouriteCurrencyPairs) => setFavouriteCurrencyPairs({ favouriteCurrencyPairs })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private currencyPairsController: CurrencyPairsControllerService,
    private favouritePairsController: UserCurrencyPairFavouritesControllerService,
    private pushpinService: PushpinService,
    private quickTradeControllerService: QuickTradeControllerService,
    private guiParams: GuiParams,
  ) {}
}
