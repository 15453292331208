import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorCodeService {
  private _twoFactorCode: string | null = null;

  setTwoFactorCode(code: string): void {
    this._twoFactorCode = code;
  }

  getTwoFactorCode(): string | null {
    return this._twoFactorCode;
  }

  clearTwoFactorCode(): void {
    this._twoFactorCode = null;
  }
}
