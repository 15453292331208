import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ApiError } from '@app-shared/api/error.api';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BadRequestErrorHandlerService } from '@app-shared/services/bad-request-error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiConfiguration } from '@app-generated/api-configuration';
import { TwoFactorService } from '@app/authentication/shared/services/two-factor.service';
import {
  DWErrorTypes,
  ErrorMappingService,
  globalHandledDWErrors,
} from '@app/authenticated/deposits-withdrawals/services/error-mapping-service';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  static readonly loginPage = '/login';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private twoFactor: TwoFactorService,
    private toastr: ToastrService,
    private guiParams: GuiParams,
    private badRequestErrorHandler: BadRequestErrorHandlerService,
    private translate: TranslateService,
    private config: ApiConfiguration,
    private errorMappingService: ErrorMappingService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (typeof error.error === 'string') {
            // this is for the case when error is not JSON, but text, needs to be solved on BE, then this can be removed
            // @ts-ignore
            error.error = JSON.parse(error.error);
          }

          let errorBody: ApiError = error.error;

          switch (error.status) {
            case 401:
              switch (errorBody.errorType) {
                case 'TwoFactorAuthenticationException':
                  this.twoFactor.saveRequest(error.url || '', request);
                  if (!this.twoFactor.isNewDeviceConfirmationType(error)) {
                    this.toastr.info(this.translate.instant('error.2fa-needed'));
                  }
                  // everything else is handled directly in the 2FA component
                  throw error;
                case 'TrezorUserNeedsRegisterException':
                  this.router.navigateByUrl('/sign-up', { state: { isTrezor: true } }).then();
                  // Replace this with Angular-native reload (currently no other option is available)
                  return EMPTY;
                case 'InvalidCsrfTokenException':
                case 'TradpoAuthenticationException':
                case 'InsufficientAuthenticationException':
                  this.guiParams
                    .getCurrentAndSaveToStore(true)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                      // if user is still logged, he will be taken back from login page to homepage
                      // however most of the time user is logged out, so he will be taken to login page
                      this.router.navigateByUrl(ErrorInterceptor.loginPage).then();
                    });
                  return EMPTY;
                default:
                  if (
                    request.url !== this.config.rootUrl + '/user/info' &&
                    (errorBody.errorType === 'InvalidCsrfTokenException' ||
                      errorBody.errorType === 'MissingCsrfTokenException')
                  ) {
                    this.router.navigateByUrl(ErrorInterceptor.loginPage).then();
                    throw error;
                  }
              }
              break;

            case 400:
            case 500:
              // ignore this warning, sometimes - at least SMS verification sometimes return text.
              if (typeof errorBody === 'string') {
                // for some errors I just get text.
                errorBody = JSON.parse(errorBody);
              }

              let isErrorHandled = false;

              if (globalHandledDWErrors.includes(errorBody.errorType as DWErrorTypes)) {
                this.toastr.error(
                  this.errorMappingService.getErrorMessage(
                    errorBody.errorType as DWErrorTypes,
                    errorBody.additionalInfo ?? null,
                  ),
                );
                isErrorHandled = true;
              }

              switch (errorBody.errorType) {
                case 'InsufficientMarketDepthException':
                  if (request.url.includes('/order/create')) {
                    this.toastr.info(this.translate.instant('quick-trade.main.error.otc'));
                  } else {
                    this.toastr.error(this.translate.instant('error.trading.insufficient-market-depth'));
                  }
                  isErrorHandled = true;
                  break;
                case 'TooManyFilesOver24hLimitException':
                  this.toastr.error(this.translate.instant('error.support-too-many-attachments'));
                  isErrorHandled = true;
                  break;
                case 'InvalidFileExtensionException':
                  this.toastr.error(this.translate.instant('error.invalid-file-extension'));
                  isErrorHandled = true;
                  break;
                case 'InvalidAmountInputPrecisionException':
                  this.toastr.error(this.translate.instant('error.trading.invalid-amount-input-precision'));
                  isErrorHandled = true;
                  break;
                case 'TransferNotFoundException':
                  this.toastr.error(this.translate.instant('error.quick-trade.transfer-not-found'));
                  isErrorHandled = true;
                  break;
                case 'InsufficientFeeException':
                  if (request.url.includes('/order/price')) {
                    // do nothing - handled in the component
                  } else {
                    this.toastr.error(this.translate.instant('error.quick-trade.insufficient-fee'));
                  }
                  isErrorHandled = true;
                  break;
                case 'InvalidSMSVerificationCodeException':
                  this.toastr.error(this.translate.instant('error.invalid-sms-code'));
                  isErrorHandled = true;
                  break;
                case 'InvalidGAResetConfirmCodesException':
                  this.toastr.error(
                    this.translate.instant('login.2fa.disable.deactivation-error-text'),
                    this.translate.instant('login.2fa.disable.deactivation-error-title'),
                  );
                  isErrorHandled = true;
                  break;
                case 'InvalidGAResetCredentialsException':
                  this.toastr.error(this.translate.instant('error.ga.reset-credentials'));
                  isErrorHandled = true;
                  break;
                case 'InvalidPasswordResetCredentialsException':
                  this.toastr.error(this.translate.instant('error.password.credentials'));
                  isErrorHandled = true;
                  break;
                case 'GAResetMaxCountExceededException':
                  this.toastr.error(this.translate.instant('error.ga.max-count'));
                  isErrorHandled = true;
                  break;
                case 'InvalidTwoFactorAuthenticationException':
                case 'TwoFactorChangeValidationException':
                  this.toastr.error(this.translate.instant('error.2fa-invalid'));
                  isErrorHandled = true;
                  break;
                case 'ResetPasswordException':
                  this.toastr.error(this.translate.instant('error.reset-password'));
                  isErrorHandled = true;
                  break;
                case 'SMSVerificationCodeExpiredException':
                  this.toastr.error(this.translate.instant('error.sms-code-expired'));
                  isErrorHandled = true;
                  break;
                case 'ResetPasswordEmailSendException':
                  const url =
                    this.translate.currentLang !== this.translate.defaultLang
                      ? `/${this.translate.currentLang}/support`
                      : '/support';
                  this.toastr.error(
                    `${this.translate.instant(
                      'error.process-password-recovery',
                    )} <a href="${url}">${this.translate.instant('lostPassword.support')}</a>.`,
                  );
                  isErrorHandled = true;
                  break;
              }

              if (isErrorHandled || error.status !== 500) {
                break;
              }

              this.translate
                .get(errorBody.message ? 'error.internal-server-n' : 'error.internal-server', {
                  value: errorBody.message,
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                  next: (translation: any) =>
                    this.toastr.error(translation, '', {
                      disableTimeOut: true,
                      closeButton: true,
                      tapToDismiss: false,
                    }),
                  error: () => {
                    // TODO DTP-5035
                  },
                });
              break;
            case 404:
            case 403:
            case 409:
              if (errorBody.business) {
                const methodName = 'handle' + errorBody.errorType;
                if (Reflect.has(this.badRequestErrorHandler, methodName)) {
                  // method exists in the component
                  const functionRef = Reflect.get(this.badRequestErrorHandler, methodName);
                  Reflect.apply(functionRef, this.badRequestErrorHandler, [errorBody.additionalInfo]);
                } else {
                  this.toastr.error(this.translate.instant('error.unknown'));
                }
              }
              break;
            case 503:
              if (error.headers.get('server') === 'cloudflare') {
                window.open(ErrorInterceptor.loginPage);
              }
              break;
            case 504:
              this.toastr.error(this.translate.instant('error.unable-connect'));
              break;
            default:
              console.error('Error from server: ', errorBody);
          }
        }

        console.error('Unhandled network error for url', request.url);

        throw error;
      }),
    );
  }
}
