/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyPairWithStatsDto } from '../models/currency-pair-with-stats-dto';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyPairFavouritesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFavouritesUsingGet
   */
  static readonly GetFavouritesUsingGetPath = '/frontend/user/currency-pairs/favourites';

  /**
   * Get a list of authenticated user's favourite currency pairs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouritesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairWithStatsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrencyPairFavouritesControllerService.GetFavouritesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairWithStatsDto>>;
      })
    );
  }

  /**
   * Get a list of authenticated user's favourite currency pairs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFavouritesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairWithStatsDto>> {

    return this.getFavouritesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairWithStatsDto>>) => r.body as Array<CurrencyPairWithStatsDto>)
    );
  }

  /**
   * Path part for operation addToFavouritesUsingPost
   */
  static readonly AddToFavouritesUsingPostPath = '/frontend/user/currency-pairs/favourites';

  /**
   * Add a currency pair to authenticated user's favourites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addToFavouritesUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addToFavouritesUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CurrencyPairWithStatsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrencyPairFavouritesControllerService.AddToFavouritesUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrencyPairWithStatsDto>;
      })
    );
  }

  /**
   * Add a currency pair to authenticated user's favourites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addToFavouritesUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addToFavouritesUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<CurrencyPairWithStatsDto> {

    return this.addToFavouritesUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<CurrencyPairWithStatsDto>) => r.body as CurrencyPairWithStatsDto)
    );
  }

  /**
   * Path part for operation removeFromFavouritesUsingDelete
   */
  static readonly RemoveFromFavouritesUsingDeletePath = '/frontend/user/currency-pairs/favourites/{currencyPairName}';

  /**
   * Remove a currency pair from authenticated user's favourites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromFavouritesUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavouritesUsingDelete$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrencyPairFavouritesControllerService.RemoveFromFavouritesUsingDeletePath, 'delete');
    if (params) {
      rb.path('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Remove a currency pair from authenticated user's favourites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeFromFavouritesUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavouritesUsingDelete(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.removeFromFavouritesUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
