import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumber]',
})
export class PhoneNumberDirective {
  private regex = new RegExp(/^[0-9]*$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Delete',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && ['c', 'x', 'v'].includes(event.key.toLowerCase())) {
      return;
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const currentValue: string = this.el.nativeElement.value;

    setTimeout(() => {
      const pastedValue: string = this.el.nativeElement.value;

      if (!pastedValue.match(this.regex)) {
        this.el.nativeElement.value = currentValue;
      }
    });
  }
}
