import { Component, Input } from '@angular/core';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logged-button',
  templateUrl: './logged-button.component.html',
  styleUrls: [],
})
export class LoggedButtonComponent {
  @Input() text!: string;
  @Input() link!: string;
  @Input() addressHref!: string;
  @Input() appearance?: string;

  isLogged$: Observable<boolean>;

  constructor(private guiParams: GuiParams) {
    this.isLogged$ = this.guiParams.loggedIn$;
  }
}
