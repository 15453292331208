import { csStrings, enStrings } from '@app-shared/const/timeago-strings.const';
import { LanguageInfoApi } from '@app-shared/api/language-info.api';

export const supportedLanguages: LanguageInfoApi[] = [
  {
    key: 'en',
    timeAgoStrings: enStrings,
    icon: '/assets/flags/en.svg',
    text: 'Do you speak English?',
  },
  {
    key: 'cs',
    timeAgoStrings: csStrings,
    icon: '/assets/flags/cz.svg',
    text: 'Mluvíte česky?',
  },
];

export const defaultLanguage = 'en';
