import { createSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer';
import { getSharedState } from '@app-shared/store/shared.selectors';
import { CURRENCY_ROUNDING } from '@app/shared/const/currencies';

export const queryCurrencies = () =>
  createSelector(getSharedState, (state: SharedState) => state.currencies.filter((currency) => currency.active));

export const queryCurrenciesRounding = () =>
  createSelector(queryCurrencies(), (currencies) => {
    if (!currencies.length) {
      return CURRENCY_ROUNDING;
    }

    return currencies.reduce((acc, currency) => {
      if (currency.guiDecimalCount !== undefined) {
        acc[currency.name] = currency.guiDecimalCount;
      }
      return acc;
    }, {} as { [key: string]: number });
  });
