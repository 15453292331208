/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UserCurrenciesFavouritesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFavouritesCurrenciesUsingGet
   */
  static readonly GetFavouritesCurrenciesUsingGetPath = '/frontend/user/currencies/favourites';

  /**
   * Get a list of authenticated user's favourite currency
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouritesCurrenciesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritesCurrenciesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrenciesFavouritesControllerService.GetFavouritesCurrenciesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of authenticated user's favourite currency
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFavouritesCurrenciesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritesCurrenciesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<string>> {

    return this.getFavouritesCurrenciesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation addToFavouritesCurrenciesUsingPost
   */
  static readonly AddToFavouritesCurrenciesUsingPostPath = '/frontend/user/currencies/favourites';

  /**
   * Add a currency to authenticated user's favourites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addToFavouritesCurrenciesUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addToFavouritesCurrenciesUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrenciesFavouritesControllerService.AddToFavouritesCurrenciesUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add a currency to authenticated user's favourites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addToFavouritesCurrenciesUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addToFavouritesCurrenciesUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.addToFavouritesCurrenciesUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeFromFavouritesCurrenciesUsingDelete
   */
  static readonly RemoveFromFavouritesCurrenciesUsingDeletePath = '/frontend/user/currencies/favourites/{currencyName}';

  /**
   * Remove a currency from authenticated user's favourites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromFavouritesCurrenciesUsingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavouritesCurrenciesUsingDelete$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCurrenciesFavouritesControllerService.RemoveFromFavouritesCurrenciesUsingDeletePath, 'delete');
    if (params) {
      rb.path('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Remove a currency from authenticated user's favourites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeFromFavouritesCurrenciesUsingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavouritesCurrenciesUsingDelete(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.removeFromFavouritesCurrenciesUsingDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
