import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-double-section-page-template',
  templateUrl: './double-section-page-template.component.html',
  styleUrls: ['./double-section-page-template.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class DoubleSectionPageTemplateComponent {
  @Input() showMiddlePageDivider?: boolean;
  @Input() narrower?: boolean;
  @Input() contentAlignment?: 'center';
}
