import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { DepositWithdrawalState } from '../store/deposit-withdrawal.state';

export interface RootState {
  depositWithdrawal: DepositWithdrawalState;
}

@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  codeSubject = new BehaviorSubject<string | null>(null);

  constructor(private store: Store<RootState>) {
    // Weird solution through global state, but the selectors don't work here.
    // Probably caused by global service registration and local reg doesn't work
    // TODO - check this
    this.store.subscribe((state) => {
      // todo: switch to hasOwn when we switch to ES20222
      if (state.hasOwnProperty('depositWithdrawal') && state.depositWithdrawal) {
        const depositWithdrawalState = state.depositWithdrawal;
        this.codeSubject.next(depositWithdrawalState.confirmCode || '');
      } else {
        this.codeSubject.next('');
      }
    });
  }

  getCurrentValue(): string {
    return this.codeSubject.value || '';
  }
}
