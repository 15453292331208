<span
  class="skeleton {{ class }}"
  [ngClass]="{
    'skeleton-text': variant === 'text',
    'skeleton-rectangular': variant === 'rectangular',
    'skeleton-rounded': variant === 'rounded',
    'skeleton-circular rounded-full': variant === 'circular',
  }"
  [style]="this.variant == 'text' ? '' : { width: this.width, height: this.height }"
></span>
