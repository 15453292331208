import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDataTableColumn]',
})
export class DataTableColumnDirective {
  @Input() sticky = false;
  @Input() autoHide = false;

  constructor(public template: TemplateRef<any>) {}
}
