<div tabindex="-1" class="ios-fix">
  <ng-container [ngSwitch]="layout">
    <app-default-layout *ngSwitchCase="templateLayoutsEnum.default">
      <ng-container *ngTemplateOutlet="router"> </ng-container>
    </app-default-layout>
    <app-basic-layout *ngSwitchCase="templateLayoutsEnum.basic">
      <ng-container *ngTemplateOutlet="router"> </ng-container>
    </app-basic-layout>
    <app-secured-layout *ngSwitchCase="templateLayoutsEnum.secured">
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </app-secured-layout>
    <app-trading-platform-layout *ngSwitchCase="templateLayoutsEnum.tradingPlatform">
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </app-trading-platform-layout>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="router"> </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #router><router-outlet></router-outlet></ng-template>
