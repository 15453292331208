/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TrezorDto } from '../models/trezor-dto';

@Injectable({
  providedIn: 'root',
})
export class TrezorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTrezorManifestEmailUsingGet
   */
  static readonly GetTrezorManifestEmailUsingGetPath = '/frontend/trezor/trezor-manifest-email';

  /**
   * Get trezor manifest email
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrezorManifestEmailUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrezorManifestEmailUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TrezorControllerService.GetTrezorManifestEmailUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get trezor manifest email
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrezorManifestEmailUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrezorManifestEmailUsingGet(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.getTrezorManifestEmailUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTrezorForCurrentUserUsingGet
   */
  static readonly GetTrezorForCurrentUserUsingGetPath = '/frontend/trezor/trezor-for-current-user';

  /**
   * Get trezor for current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrezorForCurrentUserUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrezorForCurrentUserUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TrezorDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrezorControllerService.GetTrezorForCurrentUserUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrezorDto>;
      })
    );
  }

  /**
   * Get trezor for current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrezorForCurrentUserUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrezorForCurrentUserUsingGet(params?: {
  },
  context?: HttpContext

): Observable<TrezorDto> {

    return this.getTrezorForCurrentUserUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TrezorDto>) => r.body as TrezorDto)
    );
  }

  /**
   * Path part for operation prepareChallengeVisualUsingGet
   */
  static readonly PrepareChallengeVisualUsingGetPath = '/frontend/trezor/challenge-visual';

  /**
   * Get challenge visual specified by type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareChallengeVisualUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareChallengeVisualUsingGet$Response(params: {
    type: 'LOGIN' | 'REGISTER' | 'TWO_FACTOR_AUTH';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TrezorControllerService.PrepareChallengeVisualUsingGetPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get challenge visual specified by type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareChallengeVisualUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareChallengeVisualUsingGet(params: {
    type: 'LOGIN' | 'REGISTER' | 'TWO_FACTOR_AUTH';
  },
  context?: HttpContext

): Observable<string> {

    return this.prepareChallengeVisualUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation prepareChallengeHiddenUsingGet
   */
  static readonly PrepareChallengeHiddenUsingGetPath = '/frontend/trezor/challenge-hidden';

  /**
   * Get challenge hidden
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareChallengeHiddenUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareChallengeHiddenUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TrezorControllerService.PrepareChallengeHiddenUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get challenge hidden
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareChallengeHiddenUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareChallengeHiddenUsingGet(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.prepareChallengeHiddenUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
