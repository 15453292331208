import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MenuVerticalService implements OnDestroy {
  private openMenuVerticalIdSubject = new BehaviorSubject<string | null>(null);

  protected readonly unsubscribe$ = new Subject<void>();

  menuVerticalId$: Observable<string | null> = this.openMenuVerticalIdSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.openMenuVerticalIdSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((menuVerticalId) => {
      this.document.body.style.overflow = menuVerticalId ? 'hidden' : '';
      this.document.documentElement.style.overflowX = menuVerticalId ? 'unset' : 'hidden';
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openMenu(id: string) {
    this.openMenuVerticalIdSubject.next(id);
  }

  closeMenu() {
    this.openMenuVerticalIdSubject.next(null);
  }
}
