export enum PushpinPublicChannels {
  currencyPairsStats = '/frontend/websocket/channel/currency-pair-stats',
  trades = '/frontend/websocket/channel/trades/{currPair}',
  orderBook = '/frontend/websocket/channel/order-book/{currPair}',
  tradeStats = '/frontend/websocket/channel/trade-stats/{currPair}',
}

export enum PushpinPrivateChannels {
  openOrders = '/frontend/websocket/user/channel/my-open-orders/{currPair}',
  tradesHistory = '/frontend/websocket/user/channel/my-trades/{currPair}',
  balances = '/frontend/websocket/user/channel/my-balances',
  userDeposit = '/frontend/websocket/user/channel/my-deposit-transfer-status/{transactionId}',
  userQuickTrade = '/frontend/websocket/user/channel/my-pending-quick-trade-status/{transactionId}',
}
