/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SignUpDto } from '../models/sign-up-dto';
import { SuccessLoginDto } from '../models/success-login-dto';

@Injectable({
  providedIn: 'root',
})
export class UsersControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation setNewsletterUsingPut
   */
  static readonly SetNewsletterUsingPutPath = '/frontend/users/newsletter';

  /**
   * Change newsletter preference for user registered via BankID without specifying newsletter to enable his login.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setNewsletterUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setNewsletterUsingPut$Response(params: {
    body: boolean
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.SetNewsletterUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change newsletter preference for user registered via BankID without specifying newsletter to enable his login.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setNewsletterUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setNewsletterUsingPut(params: {
    body: boolean
  },
  context?: HttpContext

): Observable<void> {

    return this.setNewsletterUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unsubscribeNewsletterUsingPost
   */
  static readonly UnsubscribeNewsletterUsingPostPath = '/frontend/users/unsubscribe-newsletter';

  /**
   * Unsubscribe user from newsletter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribeNewsletterUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unsubscribeNewsletterUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.UnsubscribeNewsletterUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Unsubscribe user from newsletter.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unsubscribeNewsletterUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unsubscribeNewsletterUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.unsubscribeNewsletterUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation signUpUsingPost
   */
  static readonly SignUpUsingPostPath = '/frontend/users/sign-up';

  /**
   * Register new user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signUpUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signUpUsingPost$Response(params: {
    body: SignUpDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SuccessLoginDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.SignUpUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessLoginDto>;
      })
    );
  }

  /**
   * Register new user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signUpUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signUpUsingPost(params: {
    body: SignUpDto
  },
  context?: HttpContext

): Observable<SuccessLoginDto> {

    return this.signUpUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<SuccessLoginDto>) => r.body as SuccessLoginDto)
    );
  }

  /**
   * Path part for operation setSendyFlagToBlockedUsingPost
   */
  static readonly SetSendyFlagToBlockedUsingPostPath = '/frontend/users/set-sendy-to-blocked';

  /**
   * Set users SENDY_BLOCKED flag to true.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSendyFlagToBlockedUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSendyFlagToBlockedUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.SetSendyFlagToBlockedUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set users SENDY_BLOCKED flag to true.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSendyFlagToBlockedUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSendyFlagToBlockedUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.setSendyFlagToBlockedUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation disableLoginUsingPost
   */
  static readonly DisableLoginUsingPostPath = '/frontend/users/disable-login';

  /**
   * Disable login of user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableLoginUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disableLoginUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.DisableLoginUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable login of user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableLoginUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disableLoginUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.disableLoginUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation confirmChangeEmailRequestUsingPost
   */
  static readonly ConfirmChangeEmailRequestUsingPostPath = '/frontend/users/change-email';

  /**
   * Change users email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmChangeEmailRequestUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmChangeEmailRequestUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.ConfirmChangeEmailRequestUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change users email.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmChangeEmailRequestUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmChangeEmailRequestUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.confirmChangeEmailRequestUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation activateAccountUsingPost
   */
  static readonly ActivateAccountUsingPostPath = '/frontend/users/activate';

  /**
   * Method for activating user account / validating email address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateAccountUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateAccountUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.ActivateAccountUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Method for activating user account / validating email address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateAccountUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateAccountUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.activateAccountUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getForbiddenEmailDomainsUsingGet
   */
  static readonly GetForbiddenEmailDomainsUsingGetPath = '/frontend/users/get-forbidden-email-domains';

  /**
   * Get forbidden email domains
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForbiddenEmailDomainsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForbiddenEmailDomainsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersControllerService.GetForbiddenEmailDomainsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get forbidden email domains
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForbiddenEmailDomainsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForbiddenEmailDomainsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<string>> {

    return this.getForbiddenEmailDomainsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
