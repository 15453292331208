import { CdkFooterRow, CdkHeaderRow, CdkNoDataRow, CdkRow } from '@angular/cdk/table';
import { Component, Directive, HostBinding, ViewEncapsulation } from '@angular/core';

const ROW_TEMPLATE = `<ng-container cdkCellOutlet></ng-container>`;

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'app-header-row, tr[appHeaderRow]',
  template: ROW_TEMPLATE,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkHeaderRow, useExisting: HeaderRowComponent }],
})
export class HeaderRowComponent extends CdkHeaderRow {
  @HostBinding('role') role = 'row';
}

/** Footer template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'app-footer-row, tr[appFooterRow]',
  template: ROW_TEMPLATE,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkFooterRow, useExisting: FooterRowComponent }],
})
export class FooterRowComponent extends CdkFooterRow {
  @HostBinding('role') role = 'row';
}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'app-row, tr[appRow]',
  template: ROW_TEMPLATE,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkRow, useExisting: RowComponent }],
})
export class RowComponent extends CdkRow {
  @HostBinding('role') role = 'row';
}

/** Row that can be used to display a message when no data is shown in the table. */
@Directive({
  selector: 'ng-template[appNoDataRow]',
  providers: [{ provide: CdkNoDataRow, useExisting: NoDataRowDirective }],
})
export class NoDataRowDirective extends CdkNoDataRow {}
