import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UserReferralControllerService } from '@app/generated/services/user-referral-controller.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferralGuard implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private referralService: UserReferralControllerService, private router: Router) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((obs) => {
      this.referralService
        .getReferralParametersUsingGet()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            if (!data.rewardEnabled || (data.reward || 0 || data.masterReward || 0) > 0) {
              obs.next(true);
            } else {
              this.router.navigate(['/']);
              obs.next(false);
            }
          },
          error: () => {
            this.router.navigate(['/login'], { queryParams: { redirect: '/' + route.url } });
            obs.next(false);
          },
        });
    });
  }
}
