import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subject, timer, TimeoutError } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { catchError, filter, finalize, map, retry, share, takeUntil, tap, timeout } from 'rxjs/operators';
import { PushpinPrivateChannels, PushpinPublicChannels } from '@app-shared/enums/pushpin.enum';
import { TradeDto } from '@app-generated/models/trade-dto';
import { OrderBookDto } from '@app-generated/models/order-book-dto';
import { AccountBalanceDto } from '@app-generated/models/account-balance-dto';
import { CurrencyPairWithStatsDto } from '@app-generated/models/currency-pair-with-stats-dto';
import { TransferStatusChangeDto } from '@app/generated/models/transfer-status-change-dto';
import { PrivatePendingQuickTradeStatusDto } from '@app/shared/api/quick-trade.api';
import { TradeStatisticsDto } from '@app/generated/models/trade-statistics-dto';
import { OpenOrderDto } from '@app/generated/models/open-order-dto';
import { TradeItemDto } from '@app/generated/models/trade-item-dto';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const CONNECTION_CHECK_INTERVAL = 10000;
const RECEIVE_MESSAGE_TIMEOUT = CONNECTION_CHECK_INTERVAL + 1000;
const MAX_RECONNECT_ATTEMPTS = 3;
const RECONNECT_INTERVAL = 6000;

export interface OpenOrder extends OpenOrderDto {
  orderChangePushEvent: 'CREATION' | 'REMOVAL';
}

export interface TradeItem extends TradeItemDto {
  orderType: string;
}

@Injectable({
  providedIn: 'root',
})
export class PushpinService {
  private channels: Map<string, Observable<any>> = new Map();
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public getChannel$(channelUrl: string): Observable<any> {
    if (!this.isBrowser) {
      return new Observable<any>();
    }

    if (!this.channels.has(channelUrl)) {
      const host = window.location.host;
      const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      const socket$ = webSocket(`${protocol}${host}${channelUrl}`);

      const connectionChecker$ = new Subject<Number>();
      const wsFinalized$ = new Subject();

      connectionChecker$
        .pipe(
          takeUntil(wsFinalized$),
          timeout(CONNECTION_CHECK_INTERVAL),
          catchError((err, caught) => {
            if (err instanceof TimeoutError) {
              socket$.next({ event: 'ping' });
              return caught;
            }

            throw err;
          }),
        )
        .subscribe();

      this.channels.set(
        channelUrl,
        socket$.pipe(
          tap(() => {
            connectionChecker$.next(1);
          }),
          timeout(RECEIVE_MESSAGE_TIMEOUT),
          retry({
            resetOnSuccess: true,
            count: MAX_RECONNECT_ATTEMPTS,
            delay: (_error, attempt) => {
              const delay = Math.pow(2, attempt - 1) * RECONNECT_INTERVAL;
              return timer(delay);
            },
          }),
          filter((message) => {
            return (message as { event: string }).event === 'data';
          }),
          map((message) => (message as { payload: any }).payload),
          catchError((err) => {
            this.toastrService.error(this.translateService.instant('error.lost-connection'), '', {
              timeOut: 0, // Permanent visible until the user closes it
            });
            throw err;
          }),
          finalize(() => {
            wsFinalized$.next(0);
            socket$.complete();
            connectionChecker$.unsubscribe();
            this.channels.delete(channelUrl);
          }),
          share(),
        ),
      );
    }
    return this.channels.get(channelUrl) as Observable<any>;
  }

  getLastTradesForSelectedPair$(selectedPair: string): Observable<TradeDto[]> {
    return this.getChannel$(PushpinPublicChannels.trades.replace('{currPair}', selectedPair));
  }

  public getOrderBookForSelectedPair$(selectedPair: string): Observable<OrderBookDto> {
    return this.getChannel$(PushpinPublicChannels.orderBook.replace('{currPair}', selectedPair));
  }

  public getTradesStatisticsForSelectedPair$(selectedPair: string): Observable<TradeStatisticsDto> {
    return this.getChannel$(PushpinPublicChannels.tradeStats.replace('{currPair}', selectedPair));
  }

  public getCurrencyPairWithStats$(): Observable<CurrencyPairWithStatsDto> {
    return this.getChannel$(PushpinPublicChannels.currencyPairsStats);
  }

  public getPrivateOpenOrdersForSelectedPair$(selectedPair: string): Observable<OpenOrder> {
    return this.getChannel$(PushpinPrivateChannels.openOrders.replace('{currPair}', selectedPair));
  }

  public getPrivateTradesHistoryForSelectedPair$(selectedPair: string): Observable<TradeItem[]> {
    return this.getChannel$(PushpinPrivateChannels.tradesHistory.replace('{currPair}', selectedPair));
  }

  public getBalances$(): Observable<{ balances: { [key: string]: AccountBalanceDto } }> {
    return this.getChannel$(PushpinPrivateChannels.balances);
  }

  public getDepositStatus$(depositId: string): Observable<TransferStatusChangeDto> {
    return this.getChannel$(PushpinPrivateChannels.userDeposit.replace('{transactionId}', depositId));
  }

  public getQuickTradeStatus$(depositId: string): Observable<PrivatePendingQuickTradeStatusDto> {
    return this.getChannel$(PushpinPrivateChannels.userQuickTrade.replace('{transactionId}', depositId));
  }
}
