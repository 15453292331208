<ng-container>
  <ul>
    <ng-container *ngFor="let item of columnData.links">
      <li *ngIf="!item.lang?.length || item.lang?.includes(lang)">
        <a
          *ngIf="getRouterLink(item); let routerLink"
          class="cm-footer-link"
          [routerLink]="[routerLink] | localize"
          [fragment]="item.fragment"
          >{{ item.text | translate }}</a
        >
        <a
          *ngIf="getHref(item); let href"
          target="{{ item.openInNewTab ? '_blank' : '_self' }}"
          class="cm-footer-link"
          [href]="[href]"
          >{{ item.text | translate }}</a
        >
      </li>
    </ng-container>
  </ul>
</ng-container>
