import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TwoFactorCodeService } from '@app/authenticated/security/services/two-factor-code-service';

@Injectable()
export class SecurityHttpInterceptor implements HttpInterceptor {
  constructor(private twoFactorService: TwoFactorCodeService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const twoFactorCode = this.twoFactorService.getTwoFactorCode();

    if (this.isChangeSettingsRequest(req)) {
      if (twoFactorCode) {
        const modifiedReq = req.clone({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          setHeaders: { 'TWO-FACTOR-DATA': twoFactorCode },
        });
        this.twoFactorService.clearTwoFactorCode();
        return next.handle(modifiedReq);
      }
    }
    return next.handle(req);
  }

  private isChangeSettingsRequest(req: HttpRequest<any>): boolean {
    const pattern = /\/frontend\/user\/security(\/|$)/;
    return pattern.test(req.url);
  }
}
