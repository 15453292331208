import { Action, createReducer, on } from '@ngrx/store';
import { UserInfoDto } from '@app-generated/models/user-info-dto';
import { loadUserInfoSuccess, loadUserInfoFailure } from './user-info.actions';
import { UserProfileDto } from '@app/generated/models/user-profile-dto';

export type State = UserInfoDto & {
  profile: UserProfileDto;
};

export const initialState: State = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  type: 'PERSON',
  userId: 0,
  username: '',
  verificationLevel: {},
  profile: {
    nickname: '',
    subscribedNewsletter: false,
  },
};

const userInfoReducer = createReducer(
  initialState,
  on(loadUserInfoSuccess, (state, action) => ({
    ...action.userInfo,
    profile: action.userProfile,
  })),
  on(loadUserInfoFailure, (state, action) => initialState),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return userInfoReducer(state, action);
}
