<p class="cm-checkbox cm-paragraph cm-form-paragraph">
  <input
    class="cm-checkbox-input"
    type="checkbox"
    id="{{ id }}"
    [disabled]="disabled || false"
    [(ngModel)]="value"
    [checked]="checked"
  />
  <label class="cursor-pointer" for="{{ id }}" [innerHTML]="sanitizedLabel"></label>
</p>
