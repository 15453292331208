import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractComponent } from '../abstract/abstract.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO: Otestovat chybove hlasky.

@Component({
  selector: 'app-textarea-new',
  templateUrl: './textarea-new.component.html',
  styleUrls: ['./textarea-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaNewComponent),
      multi: true,
    },
  ],
})
export class TextareaNewComponent extends AbstractComponent implements OnInit {
  @Input() parent!: UntypedFormGroup;
  @Input() size: 'default' | 'small' = 'default';
  @Input() showLabel = true;

  isFocused = false;

  @ViewChild('textarea') inputElement!: ElementRef;

  ngOnInit() {
    setTimeout(() => {
      if (this.forceAutofocus) {
        this.inputElement.nativeElement.focus();
      }
    }, 0);
  }

  public get text() {
    return this.parent.get(this.formControlName || 'text');
  }
}
