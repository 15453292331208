/**
 * Column definition for the table.
 * Defines a set of cells available for a table column.
 */
import { CdkColumnDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appColumnDef]',
  providers: [{ provide: CdkColumnDef, useExisting: ColumnDefDirective }],
})
export class ColumnDefDirective extends CdkColumnDef {
  /** Unique name for this column. */
  @Input('appColumnDef')
  override get name(): string {
    return this._name;
  }
  override set name(name: string) {
    this._setNameInput(name);
  }
}
