import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CurrenciesControllerService } from '@app-generated/services/currencies-controller.service';
import { loadCurrencies, setCurrencies } from '@app-shared/store/currencies/currencies.actions';

@Injectable()
export class CurrenciesEffects {
  loadCurrencies = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencies),
      mergeMap(() =>
        this.currenciesController.getCurrenciesUsingGet().pipe(
          map((currencies) => {
            return setCurrencies({ currencies });
          }),
          catchError((e) => {
            throw e;
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private currenciesController: CurrenciesControllerService) {}
}
