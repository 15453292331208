import { Directive, Input } from '@angular/core';
import { CdkFooterRowDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';

/**
 * Data row definition for the table.
 * Captures the data row's template and other properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
  selector: '[appRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: RowDefDirective }],
})
export class RowDefDirective<T> extends CdkRowDef<T> {
  @Input('appRowDefColumns') override columns: Iterable<string> = [];
  @Input('appRowDefWhen') override when: (index: number, rowData: T) => boolean = () => false;
}

/**
 * Header row definition for the table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
  selector: '[appHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: HeaderRowDefDirective }],
})
export class HeaderRowDefDirective extends CdkHeaderRowDef {
  @Input('appHeaderRowDef') override columns: Iterable<string> = [];
  // @ts-ignore
  @Input('appHeaderRowDefSticky') override sticky = false;
}

/**
 * Footer row definition for the table.
 * Captures the footer row's template and other footer properties such as the columns to display.
 */
@Directive({
  selector: '[appFooterRowDef]',
  providers: [{ provide: CdkFooterRowDef, useExisting: FooterRowDefDirective }],
})
export class FooterRowDefDirective extends CdkFooterRowDef {
  @Input('appFooterRowDef') override columns: Iterable<string> = [];
  // @ts-ignore
  @Input('appFooterRowDefSticky') override sticky = false;
}
