import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appCutZeros',
})
export class CutZerosPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '';
    }
    const stringArr = value.split('.');
    if (stringArr.length === 1) {
      return value;
    }

    for (let i = stringArr[1].length - 1; i > 1; i--) {
      if (stringArr[1].charAt(i) === '0') {
        stringArr[1] = stringArr[1].slice(0, -1);
      } else {
        break;
      }
    }

    return stringArr[0] + '.' + stringArr[1];
  }
}
