import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-base-modal',
  standalone: true,
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  imports: [CommonModule, SvgIconComponent],
  host: {
    '(document:keydown.escape)': 'close()',
  },
})
export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() id = '';
  @Input() title = '';
  @Input() size: 'x-large' | 'large' | 'medium' | 'small' = 'large';
  @Input() modalClass = '';
  @Input() isCloseDisabled = false;
  @Input() isHiddenTitle = false;
  @Input() isFixedHeight = false;
  @Input() isBodyZeroSpacing = false;
  @Output() openModal = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  isOpen = false;
  isDynamicModal = false;
  private subscription = Subscription.EMPTY;

  @ViewChild('modalContainer') modalContainerRef!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.isDynamicModal = this.modalService.isDynamicModal(this.id);

    if (this.isDynamicModal) {
      const modalState$ = this.modalService.getDynamicModalState(this.id).pipe(
        map((modalState) => {
          return modalState.show && !modalState?.minimized;
        }),
      );

      this.subscription = modalState$.subscribe((show) => {
        this.isOpen = show;
        this.toggleModal();
      });
    } else {
      const modalState$ = this.modalService.getModalState(this.id);

      this.subscription = modalState$.subscribe((show) => {
        this.isOpen = show;
        this.document.body.style.overflow = this.isOpen ? 'hidden' : '';
        this.document.documentElement.style.overflowX = this.isOpen ? 'unset' : 'hidden';
        this.toggleModal();
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clickOutside(event: Event) {
    if (this.modalContainerRef.nativeElement === event.target) {
      this.close();
    }
  }

  close() {
    if (this.isCloseDisabled) {
      return;
    }

    if (this.isDynamicModal) {
      this.modalService.closeDynamicModal(this.id);
    } else {
      this.modalService.closeModal(this.id);
    }
  }

  private toggleModal() {
    this.cdr.detectChanges();
    if (this.isOpen) {
      this.openModal.emit();
    } else {
      this.closeModal.emit();
    }
  }
}
