import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export type LabelPosition = 'before' | 'after';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true,
    },
  ],
})
export class SwitcherComponent implements OnInit {
  @Input() value = false;
  @Input() label?: string;
  @Input() labelPosition: LabelPosition = 'before';

  constructor() {}

  ngOnInit(): void {}

  internalToggleSwitch(value: boolean) {
    this.value = value;
  }
}
