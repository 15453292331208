import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackButtonSupportService {
  /**
   *  Class provides service for the basic tamplate to control display of back button
   * */

  private showBackButton = new BehaviorSubject(true);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentDisplayState = this.showBackButton.asObservable();

  constructor() {}

  show() {
    this.showBackButton.next(true);
  }

  hide() {
    this.showBackButton.next(false);
  }
}
