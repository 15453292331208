<button (click)="switchLanguage()" *ngIf="type === languageSwitchTypeEnum.footer">
  <svg-icon
    class="inline-block flag-shadow"
    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
    src="{{ currentLang !== 'cs' ? '/assets/icons/icon-english.svg' : '/assets/icons/icon-czech.svg' }}"
  ></svg-icon>
  <span class="link-with-icon-text-footer cm-footer-link">{{ currentLang !== 'cs' ? 'English' : 'Česky' }}</span>
</button>
<app-tooltip [text]="currentLang !== 'cs' ? 'English' : 'Česky'">
  <button (click)="switchLanguage()" *ngIf="type === languageSwitchTypeEnum.menu">
    <svg-icon
      class="inline-block flag-shadow"
      [svgStyle]="{ 'width.px': 33, 'height.px': 33 }"
      src="{{ currentLang !== 'cs' ? '/assets/icons/icon-english.svg' : '/assets/icons/icon-czech.svg' }}"
    ></svg-icon>
  </button>
</app-tooltip>
<button (click)="switchLanguage()" class="mobile-switcher" *ngIf="type === languageSwitchTypeEnum.menuMobile">
  <svg-icon
    class="inline-block flag-shadow"
    [svgStyle]="{ 'width.px': 33, 'height.px': 33 }"
    src="{{ currentLang !== 'cs' ? '/assets/icons/icon-english.svg' : '/assets/icons/icon-czech.svg' }}"
  ></svg-icon>
  <span class="link-with-icon-text">{{ currentLang !== 'cs' ? 'English' : 'Česky' }}</span>
</button>
