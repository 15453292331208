import { createAction, props } from '@ngrx/store';
import { UserInfoDto } from '@app-generated/models/user-info-dto';
import { UserProfileDto } from '@app/generated/models/user-profile-dto';

export const loadUserInfo = createAction('[User Info] Load');

export const loadUserInfoSuccess = createAction(
  '[User Info] Load Success',
  props<{ userInfo: UserInfoDto; userProfile: UserProfileDto }>(),
);

export const loadUserInfoFailure = createAction('[User Info] Load Failure', props<{ error: string }>());
