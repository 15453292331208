import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

import { MenuVerticalPopoverService } from '@app/shared/components/menu-vertical/menu-vertical-popover.service';

@Component({
  selector: '[menu-vertical-trigger]',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './menu-vertical-trigger.component.html',
  host: {
    '[class.menu-vertical-trigger]': 'true',
    '(click)': 'togglePopover($event)',
  },
})
export class MenuVerticalTriggerComponent implements OnInit {
  @ContentChild('mobileMenuVerticalTriggerContent', { static: false })
  mobileMenuVerticalTriggerContentRef!: TemplateRef<any>;

  mobileMenuVerticalIsVisible = false;

  constructor(private menuVerticalPopoverService: MenuVerticalPopoverService) {}

  ngOnInit() {
    this.menuVerticalPopoverService.popoverVisible$.subscribe((visible) => {
      this.mobileMenuVerticalIsVisible = visible;
    });
  }

  togglePopover(event: Event) {
    this.menuVerticalPopoverService.toggle();
  }
}
