import { Injectable } from '@angular/core';
import { queryCurrencies, queryCurrenciesRounding } from '@app-shared/store/currencies/currencies.selectors';
import { Store } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import { loadCurrencies } from '@app-shared/store/currencies/currencies.actions';

@Injectable({
  providedIn: 'root',
})
export class Currencies {
  currencies$ = this.store.select(queryCurrencies());
  currenciesRounding$ = this.store.select(queryCurrenciesRounding());

  constructor(private store: Store<SharedState>) {}

  load() {
    this.store.dispatch(loadCurrencies());
  }
}
