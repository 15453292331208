import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-number',
  templateUrl: './step-number.component.html',
  styleUrls: ['./step-number.component.scss'],
})
export class StepNumberComponent {
  @Input() value = 0;
  @Input() active = false;
  @Input() completed = false;
}
