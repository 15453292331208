<ng-container *ngTemplateOutlet="!link ? buttonTpl : linkTpl"></ng-container>

<ng-template #linkTpl>
  <a [routerLink]="link" [routerLinkActive]="'cm-button-active'" class="cm-button cm-button-menu">
    <app-tooltip class="menu-item-tooltip" [text]="tooltip">
      <span class="shape">
        <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </span>
    </app-tooltip>
    <span class="icon-label">
      {{ tooltip }}
    </span>
  </a>
</ng-template>

<ng-template #buttonTpl>
  <button type="button" class="cm-button cm-button-menu">
    <app-tooltip class="menu-item-tooltip" [text]="tooltip">
      <span class="shape">
        <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </span>
    </app-tooltip>
    <span class="lg:hidden menu-item-content">
      <ng-content select="[content]"></ng-content>
    </span>
  </button>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
