import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[dropdown-icon-left], [dropdown-icon-right]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown-icon.component.html',
  host: {
    '[class.dropdown-item-icon]': 'true',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DropdownIconComponent {}
