import { EventEmitter, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { SharedState } from '@app-shared/store/shared.reducer';
import { queryUserInfo } from '@app-shared/store/user-info/user-info.selectors';
import { loadUserInfo, loadUserInfoFailure } from '@app-shared/store/user-info/user-info.actions';
import { State } from '@app/shared/store/user-info/user-info.reducer';

@Injectable({
  providedIn: 'root',
})
export class UserInfo {
  userInfo$ = this.store.select(queryUserInfo());
  userInfoObservable$: Observable<State>;
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<SharedState>, private actions$: Actions) {
    this.userInfoObservable$ = this.store.pipe(
      select(queryUserInfo()),
      switchMap((userInfo) => {
        if (userInfo.userId !== 0) {
          return of(userInfo);
        } else {
          this.store.dispatch(loadUserInfo());

          return this.actions$.pipe(
            ofType(loadUserInfoFailure),
            switchMap((action) => {
              return throwError(() => action.error);
            }),
            take(1),
          );
        }
      }),
    );
  }

  load() {
    this.store.dispatch(loadUserInfo());
  }

  emitEvent(data: any) {
    this.eventEmitter.emit(data);
  }
}
