import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { ScreenSizes } from '@app-shared/enums/screenSizes';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-public-with-header',
  templateUrl: './public-with-header.component.html',
  styleUrls: ['./public-with-header.component.scss'],
})
export class PublicWithHeaderComponent implements OnInit, OnDestroy {
  @Input()
  headerImgDesktop!: string;

  @Input()
  headerImgMobile!: string;

  @Input()
  headingText!: string;

  @Input()
  headingSubText!: string;

  resizeObservable$!: Observable<Event>;
  screenWidth!: number;

  private unsubscribe$ = new Subject<void>();

  get backgroundImg(): string {
    return this.screenWidth > ScreenSizes.lg ? this.headerImgDesktop : this.headerImgMobile;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  ngOnInit() {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (evt) => {
        const target = evt.target as Window;
        this.screenWidth = target.innerWidth;
      },
      error: () => {
        // TODO DTP-5035
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
