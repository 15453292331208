export const BALANCES_TABLE_MOCK_DATA = [
  {
    guiDecimalCount: 8,
    withdrawDecimalCount: 8,
    name: 'BTC',
    symbol: 'Ƀ',
    displayName: 'Bitcoin',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: true,
    destinationTagNumericSupport: false,
    totalBalance: 601.50282401,
    inOpenOrders: 8.34459738,
    available: 593.15822663,
    fiatEquivalents: {
      CZK: 889737339.945,
      EUR: 14235856.754942663,
    },
    lastPrices: {
      CZK: 1500000,
      EUR: 24000.1,
    },
    changeIn24Hours: {
      CZK: 0.32,
      EUR: -2.89,
    },
    favorite: true,
  },
  {
    guiDecimalCount: 2,
    withdrawDecimalCount: null,
    name: 'EUR',
    symbol: '€',
    displayName: 'Euro',
    virtual: false,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: false,
    totalBalance: 55960.60013719,
    inOpenOrders: 11731.61674858,
    available: 44228.98338861,
    fiatEquivalents: {
      CZK: 1399015.00342975,
      EUR: 55960.60013719,
    },
    lastPrices: {
      CZK: 0,
      EUR: 0,
    },
    changeIn24Hours: {
      CZK: 0,
      EUR: 0,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 2,
    withdrawDecimalCount: null,
    name: 'CZK',
    symbol: 'Kč',
    displayName: 'Czech crown',
    virtual: false,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: false,
    totalBalance: 2972939.20410011,
    inOpenOrders: 899705.56063012,
    available: 2073233.64346999,
    fiatEquivalents: {
      CZK: 2972939.2041,
      EUR: 118917.56816400001,
    },
    lastPrices: {
      CZK: 0,
      EUR: 0,
    },
    changeIn24Hours: {
      CZK: 0,
      EUR: 0,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 8,
    withdrawDecimalCount: 8,
    name: 'LTC',
    symbol: 'Ł',
    displayName: 'Litecoin',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: true,
    destinationTagNumericSupport: false,
    totalBalance: 91994.77745307,
    inOpenOrders: 27.42931412,
    available: 91967.34813895,
    fiatEquivalents: {
      CZK: 148987103.985099,
      EUR: 6095595.834649606,
    },
    lastPrices: {
      CZK: 1620,
      EUR: 66.28,
    },
    changeIn24Hours: {
      CZK: -2.89,
      EUR: 0.32,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 8,
    withdrawDecimalCount: 8,
    name: 'ETH',
    symbol: 'Ξ',
    displayName: 'Ethereum',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: false,
    totalBalance: 23999.54847494,
    inOpenOrders: 25.81884753,
    available: 23973.72962741,
    fiatEquivalents: {
      CZK: 1138752157.301975,
      EUR: 29295897.60469502,
    },
    lastPrices: {
      CZK: 47500,
      EUR: 1222,
    },
    changeIn24Hours: {
      CZK: 0.32,
      EUR: -2.89,
    },
    favorite: true,
  },
  {
    guiDecimalCount: 8,
    withdrawDecimalCount: 6,
    name: 'XRP',
    symbol: 'Ʀ',
    displayName: 'Ripple',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: true,
    totalBalance: 33997.89140314,
    inOpenOrders: 16.0683054,
    available: 33981.82309774,
    fiatEquivalents: {
      CZK: 513465.3470068514,
      EUR: 23787.276168418,
    },
    lastPrices: {
      CZK: 15.11,
      EUR: 0.7,
    },
    changeIn24Hours: {
      CZK: -2.89,
      EUR: 0.32,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 6,
    withdrawDecimalCount: 6,
    name: 'ADA',
    symbol: '₳',
    displayName: 'Cardano',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: true,
    destinationTagNumericSupport: false,
    totalBalance: 23845,
    inOpenOrders: 15.45215,
    available: 23829.54785,
    fiatEquivalents: {
      CZK: 197070.3607195,
      EUR: 8340.3417475,
    },
    lastPrices: {
      CZK: 8.27,
      EUR: 0.35,
    },
    changeIn24Hours: {
      CZK: 0.32,
      EUR: -2.89,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 8,
    withdrawDecimalCount: 8,
    name: 'SOL',
    symbol: '◎',
    displayName: 'Solana',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: false,
    totalBalance: 0,
    inOpenOrders: 0,
    available: 0,
    fiatEquivalents: {
      CZK: 0,
      EUR: 0,
    },
    lastPrices: {
      CZK: 101,
      EUR: 0,
    },
    changeIn24Hours: {
      CZK: -2.89,
      EUR: 0.32,
    },
    favorite: false,
  },
  {
    guiDecimalCount: 2,
    withdrawDecimalCount: 2,
    name: 'USDT',
    symbol: '₮',
    displayName: 'USDT',
    virtual: true,
    active: true,
    depositActive: true,
    withdrawalActive: false,
    trezorSupport: false,
    destinationTagNumericSupport: false,
    totalBalance: 23759.79,
    inOpenOrders: 16059.26975,
    available: 7700.52025,
    fiatEquivalents: {
      CZK: 180962.225875,
      EUR: 19251300.625,
    },
    lastPrices: {
      CZK: 23.5,
      EUR: 2500,
    },
    changeIn24Hours: {
      CZK: 0.32,
      EUR: -2.89,
    },
    favorite: false,
  },
];
