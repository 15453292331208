import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-choice-button-bar',
  templateUrl: './multi-choice-button-bar.component.html',
  styleUrls: ['./multi-choice-button-bar.component.scss'],
})
export class MultiChoiceButtonBarComponent {
  @Input() options: { value: any; active: boolean }[] = [];
  @Input() description = '';

  @Output() selectedOptions = new EventEmitter<any[]>();

  get areAllOptionsInactive(): boolean {
    return this.options.every((option) => !option.active);
  }

  selectOption(option: { value: any; active: boolean }): void {
    option.active = !option.active;
    this.emitSelectedOptions();
  }

  resetOptions(): void {
    this.options.forEach((option) => (option.active = false));
    this.emitSelectedOptions();
  }

  private emitSelectedOptions(): void {
    const activeOptions = this.options.filter((option) => option.active).map((option) => option.value);
    this.selectedOptions.emit(activeOptions);
  }
}
