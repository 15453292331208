import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import BigNumber from 'bignumber.js';

const INPUT_TYPE_INSERT_FROM_PASTE = 'insertFromPaste';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersTextInputDirective {
  @Input('appOnlyNumbers') decimalPlaces = 0;

  private regex = new RegExp(/^\d*[.,]?\d*$/g);
  private delimiterRegex = new RegExp(/[.,]/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  /**
   * NOTE: `preventDefault` does not work for the `keydown` event in Chrome browser for Android OS.
   *
   * But it is fine for inputs that don't use a getter and setter for a value.
   * Thanks to the logic that returns a truncated value in the `input` event.
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      ((event.metaKey || event.ctrlKey) && ['a', 'c', 'x', 'v'].includes(event.key.toLowerCase()))
    ) {
      return;
    }

    const changedValue = this.applyInput(event.key);
    const isValid = this.isValidInput(changedValue);

    if (!isValid) {
      event.preventDefault();
    }
  }

  /**
   * Because preventDefault does not work for the `keydown` event in Chrome browser for Android OS.
   * The `beforeinput` event is used, which correctly supports `preventDefault in this browser.
   *
   * This is required for inputs that use a getter and setter for a value.
   *
   * In some cases, the `beforeinput` event is not fired.
   * Therefore, the `keydown` event is used, at least as a fallback method for other browsers.
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/beforeinput_event
   */
  @HostListener('beforeinput', ['$event']) onBeforeInput(event: InputEvent) {
    const inputValue = event.data || '';

    /**
     * If the user enters a value from the clipboard, try to adjust the value to the correct number of decimal places.
     */
    if (event.inputType === INPUT_TYPE_INSERT_FROM_PASTE) {
      // Prevents calling the `input` event for the original value
      event.preventDefault();

      const inputElement: HTMLInputElement = this.el.nativeElement;
      const truncatedValue = this.truncateValueToDecimals(inputValue);
      const changedValue = this.applyInput(truncatedValue);
      const isValid = this.isValidInput(changedValue);

      if (isValid) {
        inputElement.value = changedValue;
        // Calls the `input` event to update the form
        this.triggerEvent(inputElement, 'input');
      }
      return;
    }

    const changedValue = this.applyInput(inputValue);
    const isValid = this.isValidInput(changedValue);
    if (!isValid) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue: string = inputElement.value;
    event.preventDefault();

    if (!this.isValidInput(inputValue)) {
      event.preventDefault();
      const truncatedValue = this.truncateValueToDecimals(inputValue);
      inputElement.value = this.isValidInput(truncatedValue) ? truncatedValue : '';
      // Calls the `input` event to update the form
      this.triggerEvent(inputElement, 'input');
      return;
    }

    const selectionStart = inputElement.selectionStart ?? 0;
    const selectionEnd = inputElement.selectionEnd ?? 0;
    const matchLeadingZeroes = /^(0+)(\d+[.,]?\d*)/.exec(inputValue);

    if (matchLeadingZeroes) {
      this.el.nativeElement.value = matchLeadingZeroes[2];
      this.offsetInputSelection(inputElement, selectionStart, selectionEnd, -matchLeadingZeroes[1].length);
    }

    if (/^[.,]\d*$/.test(inputValue)) {
      this.el.nativeElement.value = '0' + inputValue;
      this.offsetInputSelection(inputElement, selectionStart, selectionEnd, 1);
    }
  }

  private offsetInputSelection(inputElement: HTMLInputElement, origStart: number, origEnd: number, offset: number) {
    inputElement.setSelectionRange(Math.max(origStart + offset, 0), Math.max(origEnd + offset, 0));
  }

  private isValidInput(value: string): boolean {
    const delimiters = value.match(this.delimiterRegex) || [];
    if (delimiters.length > 1) {
      return false;
    }

    if (delimiters.length === 1) {
      if (this.decimalPlaces === 0) {
        return false;
      }

      const delimiter = delimiters[0];
      const decimalPart = value.split(delimiter)[1];
      if (decimalPart && decimalPart.length > this.decimalPlaces) {
        return false;
      }
    }

    return value.match(this.regex) !== null;
  }

  private applyInput(value: string) {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue: string = inputElement.value;
    const cursorPosition = inputElement.selectionStart ?? 0;
    const cursorPositionEnd = inputElement.selectionEnd ?? 0;

    return inputValue.slice(0, cursorPosition) + value + inputValue.slice(cursorPositionEnd);
  }

  private truncateValueToDecimals(value: string) {
    const delimiters = value.match(this.delimiterRegex) || [];
    const delimiter = delimiters[0];

    if (delimiter) {
      const valueBN = BigNumber(value.replace(this.delimiterRegex, '.'));
      return valueBN.toFormat(this.decimalPlaces, BigNumber.ROUND_DOWN, {
        decimalSeparator: delimiter,
      });
    }

    return value;
  }

  private triggerEvent(el: HTMLInputElement, type: string): void {
    const e = new CustomEvent(type);
    el.dispatchEvent(e);
  }
}
