import { Component, Input, OnInit } from '@angular/core';
import { Paragraph } from '@app-shared/components/paragraph/paragraph.component';

@Component({
  selector: 'app-paragraph-with-img',
  templateUrl: './paragraph-with-img.component.html',
  styleUrls: ['./paragraph-with-img.component.scss'],
})
export class ParagraphWithImgComponent implements OnInit {
  @Input()
  imgSrc!: string;

  @Input()
  imgAlt?: string = '';

  @Input()
  nicerHeader!: string;

  @Input()
  paragraph!: Paragraph;

  constructor() {}

  ngOnInit(): void {}
}
