/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TradeItemDto } from '../models/trade-item-dto';
import { TradeStatisticsDto } from '../models/trade-statistics-dto';

@Injectable({
  providedIn: 'root',
})
export class TradesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getStatisticsUsingGet
   */
  static readonly GetStatisticsUsingGetPath = '/frontend/trades/statistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatisticsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatisticsUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TradeStatisticsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TradesControllerService.GetStatisticsUsingGetPath, 'get');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TradeStatisticsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatisticsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatisticsUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<TradeStatisticsDto> {

    return this.getStatisticsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TradeStatisticsDto>) => r.body as TradeStatisticsDto)
    );
  }

  /**
   * Path part for operation getLastTwoTradesUsingGet
   */
  static readonly GetLastTwoTradesUsingGetPath = '/frontend/trades/last-two';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastTwoTradesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastTwoTradesUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TradeItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TradesControllerService.GetLastTwoTradesUsingGetPath, 'get');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TradeItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastTwoTradesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastTwoTradesUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<Array<TradeItemDto>> {

    return this.getLastTwoTradesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TradeItemDto>>) => r.body as Array<TradeItemDto>)
    );
  }

  /**
   * Path part for operation getTradesUsingGet
   */
  static readonly GetTradesUsingGetPath = '/frontend/trades/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTradesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradesUsingGet$Response(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TradeItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TradesControllerService.GetTradesUsingGetPath, 'get');
    if (params) {
      rb.query('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TradeItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTradesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradesUsingGet(params: {
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<Array<TradeItemDto>> {

    return this.getTradesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TradeItemDto>>) => r.body as Array<TradeItemDto>)
    );
  }

}
