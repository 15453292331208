const userProfileLocaleDefaults = {
  btcLnUnit: 'SATS',
  appearance: 'SYSTEM',
  dateFormat: 'DD_MM_YYYY',
  language: 'CS',
  numberFormat: 'CS',
  timeFormat12Hour: false,
  timezone: 'Europe/Prague',
};

export const USER_PROFILE_LOCALE_DEFAULTS = Object.freeze(userProfileLocaleDefaults);
