import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-base-modal-footer',
  standalone: true,
  imports: [],
  templateUrl: './base-modal-footer.component.html',
  styleUrl: '../../base-modal.component.scss',
  host: {
    '[class.modal-footer]': 'true',
  },
  encapsulation: ViewEncapsulation.None,
})
export class BaseModalFooterComponent {}
