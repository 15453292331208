import { Component, Input } from '@angular/core';
import { LocalizedRouterService } from '@app/shared/services/localized-router.service';
import { PopoverPosition } from '@app/shared/components/dropdown/dropdown.enum';

@Component({
  selector: 'app-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
})
export class MenuLinkComponent {
  @Input() link: string | undefined | string[];
  @Input() icon: string | undefined;
  @Input() children: any[] | undefined = [];
  @Input() hasOutline: boolean | undefined = false;

  protected readonly popoverPositionEnum = PopoverPosition;

  constructor(public router: LocalizedRouterService) {}
}
