import { DateTime, Interval, DateTimeUnit } from 'luxon';

export enum IntervalUnit {
  'day' = 'day',
  'week' = 'week',
  'month' = 'month',
}

export const getIntervals = (startDate: DateTime, endDate: DateTime, interval: DateTimeUnit): Interval[] => {
  return Interval.fromDateTimes(startDate.startOf(interval), endDate.endOf(interval))
    .splitBy({ [interval]: 1 })
    .reduce((acc: Interval[], interval, index, intervals) => {
      if (!interval.start || !interval.end) {
        return acc;
      }

      const start = interval.start;
      const end = index < intervals.length - 1 ? interval.end.minus({ milliseconds: 1 }) : interval.end;
      acc.push(Interval.fromDateTimes(start, end));

      return acc;
    }, []);
};
