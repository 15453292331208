import { createAction, props } from '@ngrx/store';
import { GuiParamsDto } from '@app-generated/models/gui-params-dto';

export const loadGuiParamsLoading = createAction('[Gui params] Load Loading');

export const loadGuiParamsSuccess = createAction('[Gui params] Load Success', props<{ guiParams: GuiParamsDto }>());

export const loadGuiParamsFailure = createAction('[Gui params] Load Failure');

export const loadGuiParams = createAction('[Gui params] Load');
