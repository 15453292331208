/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountBalanceMoveDto } from '../models/account-balance-move-dto';
import { AccountDto } from '../models/account-dto';
import { AccountSettingsDto } from '../models/account-settings-dto';
import { AccountValueHistoryDto } from '../models/account-value-history-dto';
import { BalancesWrapperDto } from '../models/balances-wrapper-dto';
import { OrderFormDto } from '../models/order-form-dto';
import { OrderItemDto } from '../models/order-item-dto';
import { OrderResultDto } from '../models/order-result-dto';
import { TradeItemDto } from '../models/trade-item-dto';

@Injectable({
  providedIn: 'root',
})
export class UserAccountControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation renameAccountUsingPut
   */
  static readonly RenameAccountUsingPutPath = '/frontend/user/accounts/{accountId}/rename';

  /**
   * Rename account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameAccountUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameAccountUsingPut$Response(params: {
    accountId: number;
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.RenameAccountUsingPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Rename account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameAccountUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameAccountUsingPut(params: {
    accountId: number;
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.renameAccountUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateAccountUsingPut
   */
  static readonly DeactivateAccountUsingPutPath = '/frontend/user/accounts/{accountId}/deactivate';

  /**
   * Deactivate account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateAccountUsingPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAccountUsingPut$Response(params: {
    accountId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.DeactivateAccountUsingPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateAccountUsingPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAccountUsingPut(params: {
    accountId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.deactivateAccountUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getActiveGuiAccountUsingGet
   */
  static readonly GetActiveGuiAccountUsingGetPath = '/frontend/user/accounts/active-gui-account';

  /**
   * Get the currently selected account in GUI.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveGuiAccountUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveGuiAccountUsingGet$Response(params?: {

    /**
     * If the 'balances' param is set to true, then all balances of each account are included.
     */
    balances?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccountDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetActiveGuiAccountUsingGetPath, 'get');
    if (params) {
      rb.query('balances', params.balances, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDto>;
      })
    );
  }

  /**
   * Get the currently selected account in GUI.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveGuiAccountUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveGuiAccountUsingGet(params?: {

    /**
     * If the 'balances' param is set to true, then all balances of each account are included.
     */
    balances?: boolean;
  },
  context?: HttpContext

): Observable<AccountDto> {

    return this.getActiveGuiAccountUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation setActiveGuiAccountUsingPut
   */
  static readonly SetActiveGuiAccountUsingPutPath = '/frontend/user/accounts/active-gui-account';

  /**
   * Modify the currently selected account in GUI.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveGuiAccountUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setActiveGuiAccountUsingPut$Response(params: {
    body: number
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccountDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.SetActiveGuiAccountUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDto>;
      })
    );
  }

  /**
   * Modify the currently selected account in GUI.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setActiveGuiAccountUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setActiveGuiAccountUsingPut(params: {
    body: number
  },
  context?: HttpContext

): Observable<AccountDto> {

    return this.setActiveGuiAccountUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation getAllAccountsOfUserUsingGet
   */
  static readonly GetAllAccountsOfUserUsingGetPath = '/frontend/user/accounts';

  /**
   * Return all active accounts of the logged in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccountsOfUserUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountsOfUserUsingGet$Response(params?: {

    /**
     * If the 'balances' param is set to true, then all balances of each account are included.
     */
    balances?: boolean;

    /**
     * If 'show-active-gui-account' param is set to true, each account will contain a flag, if it should or should not be selected in GUI (only one can be active).
     */
    'show-active-gui-account'?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetAllAccountsOfUserUsingGetPath, 'get');
    if (params) {
      rb.query('balances', params.balances, {});
      rb.query('show-active-gui-account', params['show-active-gui-account'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountDto>>;
      })
    );
  }

  /**
   * Return all active accounts of the logged in user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAccountsOfUserUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountsOfUserUsingGet(params?: {

    /**
     * If the 'balances' param is set to true, then all balances of each account are included.
     */
    balances?: boolean;

    /**
     * If 'show-active-gui-account' param is set to true, each account will contain a flag, if it should or should not be selected in GUI (only one can be active).
     */
    'show-active-gui-account'?: boolean;
  },
  context?: HttpContext

): Observable<Array<AccountDto>> {

    return this.getAllAccountsOfUserUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountDto>>) => r.body as Array<AccountDto>)
    );
  }

  /**
   * Path part for operation createAccountUsingPost
   */
  static readonly CreateAccountUsingPostPath = '/frontend/user/accounts';

  /**
   * Create a new Account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccountUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccountUsingPost$Response(params: {
    body: AccountDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccountDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.CreateAccountUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDto>;
      })
    );
  }

  /**
   * Create a new Account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccountUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccountUsingPost(params: {
    body: AccountDto
  },
  context?: HttpContext

): Observable<AccountDto> {

    return this.createAccountUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation computeOrderFormDataUsingPost
   */
  static readonly ComputeOrderFormDataUsingPostPath = '/frontend/user/accounts/{accountId}/{currencyPairName}/compute-order-form-data';

  /**
   * Count and returns total amount for buy.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `computeOrderFormDataUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  computeOrderFormDataUsingPost$Response(params: {
    accountId: number;
    currencyPairName: string;
    body: OrderFormDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderFormDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.ComputeOrderFormDataUsingPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('currencyPairName', params.currencyPairName, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderFormDto>;
      })
    );
  }

  /**
   * Count and returns total amount for buy.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `computeOrderFormDataUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  computeOrderFormDataUsingPost(params: {
    accountId: number;
    currencyPairName: string;
    body: OrderFormDto
  },
  context?: HttpContext

): Observable<OrderFormDto> {

    return this.computeOrderFormDataUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderFormDto>) => r.body as OrderFormDto)
    );
  }

  /**
   * Path part for operation sellUsingPost
   */
  static readonly SellUsingPostPath = '/frontend/user/accounts/{accountId}/sell';

  /**
   * Make a sell (create order) for account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sellUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sellUsingPost$Response(params: {
    accountId: number;
    force?: boolean;
    body: OrderItemDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.SellUsingPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderResultDto>;
      })
    );
  }

  /**
   * Make a sell (create order) for account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sellUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sellUsingPost(params: {
    accountId: number;
    force?: boolean;
    body: OrderItemDto
  },
  context?: HttpContext

): Observable<OrderResultDto> {

    return this.sellUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderResultDto>) => r.body as OrderResultDto)
    );
  }

  /**
   * Path part for operation moveBalanceToAccountUsingPost
   */
  static readonly MoveBalanceToAccountUsingPostPath = '/frontend/user/accounts/{accountId}/move-balance';

  /**
   * Transfer balance from one account to another. Both accounts need to belong to the same user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveBalanceToAccountUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveBalanceToAccountUsingPost$Response(params: {
    accountId: number;
    body: AccountBalanceMoveDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.MoveBalanceToAccountUsingPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Transfer balance from one account to another. Both accounts need to belong to the same user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveBalanceToAccountUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveBalanceToAccountUsingPost(params: {
    accountId: number;
    body: AccountBalanceMoveDto
  },
  context?: HttpContext

): Observable<void> {

    return this.moveBalanceToAccountUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation buyUsingPost
   */
  static readonly BuyUsingPostPath = '/frontend/user/accounts/{accountId}/buy';

  /**
   * Make a buy (create order) for account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buyUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyUsingPost$Response(params: {
    accountId: number;
    force?: boolean;
    body: OrderItemDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.BuyUsingPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderResultDto>;
      })
    );
  }

  /**
   * Make a buy (create order) for account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buyUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyUsingPost(params: {
    accountId: number;
    force?: boolean;
    body: OrderItemDto
  },
  context?: HttpContext

): Observable<OrderResultDto> {

    return this.buyUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderResultDto>) => r.body as OrderResultDto)
    );
  }

  /**
   * Path part for operation getAccountTradesHistoryUsingGet
   */
  static readonly GetAccountTradesHistoryUsingGetPath = '/frontend/user/accounts/{accountId}/{currencyPairName}/trades-history';

  /**
   * Get history of trades for account.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountTradesHistoryUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountTradesHistoryUsingGet$Response(params: {
    accountId: number;
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TradeItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetAccountTradesHistoryUsingGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('currencyPairName', params.currencyPairName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TradeItemDto>>;
      })
    );
  }

  /**
   * Get history of trades for account.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountTradesHistoryUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountTradesHistoryUsingGet(params: {
    accountId: number;
    currencyPairName: string;
  },
  context?: HttpContext

): Observable<Array<TradeItemDto>> {

    return this.getAccountTradesHistoryUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TradeItemDto>>) => r.body as Array<TradeItemDto>)
    );
  }

  /**
   * Path part for operation getAccountValueHistoryUsingGet
   */
  static readonly GetAccountValueHistoryUsingGetPath = '/frontend/user/accounts/{accountId}/value-history';

  /**
   * Get account value history.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountValueHistoryUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountValueHistoryUsingGet$Response(params: {
    accountId: number;
    'number-of-days': number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountValueHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetAccountValueHistoryUsingGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('number-of-days', params['number-of-days'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountValueHistoryDto>>;
      })
    );
  }

  /**
   * Get account value history.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountValueHistoryUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountValueHistoryUsingGet(params: {
    accountId: number;
    'number-of-days': number;
  },
  context?: HttpContext

): Observable<Array<AccountValueHistoryDto>> {

    return this.getAccountValueHistoryUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountValueHistoryDto>>) => r.body as Array<AccountValueHistoryDto>)
    );
  }

  /**
   * Path part for operation getBalancesUsingGet
   */
  static readonly GetBalancesUsingGetPath = '/frontend/user/accounts/{accountId}/balances';

  /**
   * Get account balances.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBalancesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBalancesUsingGet$Response(params: {
    accountId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BalancesWrapperDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetBalancesUsingGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BalancesWrapperDto>;
      })
    );
  }

  /**
   * Get account balances.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBalancesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBalancesUsingGet(params: {
    accountId: number;
  },
  context?: HttpContext

): Observable<BalancesWrapperDto> {

    return this.getBalancesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BalancesWrapperDto>) => r.body as BalancesWrapperDto)
    );
  }

  /**
   * Path part for operation getAccountValueHistoryTotalUsingGet
   */
  static readonly GetAccountValueHistoryTotalUsingGetPath = '/frontend/user/accounts/value-history';

  /**
   * Get total value history.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountValueHistoryTotalUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountValueHistoryTotalUsingGet$Response(params: {
    'number-of-days': number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountValueHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetAccountValueHistoryTotalUsingGetPath, 'get');
    if (params) {
      rb.query('number-of-days', params['number-of-days'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountValueHistoryDto>>;
      })
    );
  }

  /**
   * Get total value history.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountValueHistoryTotalUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountValueHistoryTotalUsingGet(params: {
    'number-of-days': number;
  },
  context?: HttpContext

): Observable<Array<AccountValueHistoryDto>> {

    return this.getAccountValueHistoryTotalUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountValueHistoryDto>>) => r.body as Array<AccountValueHistoryDto>)
    );
  }

  /**
   * Path part for operation getSettingsUsingGet
   */
  static readonly GetSettingsUsingGetPath = '/frontend/user/accounts/settings';

  /**
   * Get account settings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccountSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountControllerService.GetSettingsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingsDto>;
      })
    );
  }

  /**
   * Get account settings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<AccountSettingsDto> {

    return this.getSettingsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccountSettingsDto>) => r.body as AccountSettingsDto)
    );
  }

}
