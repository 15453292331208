import { ErrorHandler, Inject, Injectable, Injector, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '../../../express.tokens';
import { Response } from 'express';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  async handleError(error: any) {
    const router = this.injector.get(Router);

    // handle critical errors
    if (error instanceof HttpErrorResponse && error.url?.endsWith('/frontend/gui-parameters')) {
      this.response?.sendStatus(error.status); // would be nice to have custom 500 error page :/

      // TODO create navigate to error page in SPA - we have only 404
      setTimeout(() => router.navigate(['/error'], { skipLocationChange: true }));
    }
  }

  private initSentry() {
    // TODO move sentry init code from init.service.ts here
  }
}
