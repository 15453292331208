import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '@app/shared/store/shared.actions';
import { UserControllerService } from '@app/generated/services/user-controller.service';
import { take } from 'rxjs/operators';
import { LanguageService } from '@app/shared/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private userController: UserControllerService,
    private store: Store,
    private languageService: LanguageService,
  ) {}

  logout() {
    this.userController
      .logoutUsingDelete()
      .pipe(take(1))
      .subscribe(() => {
        this.refreshAfterLogout();
      });
  }

  refreshAfterLogout() {
    this.store.dispatch(logout());
    window.sessionStorage.setItem('loggedOut', 'true');
    window.location.href = '/' + this.languageService.getCurrentLanguage();
    if (window.location.pathname === '/') {
      window.location.reload();
    }
  }
}
