import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserFingerprintControllerService } from '@app/generated/services/user-fingerprint-controller.service';
import { firstValueFrom } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window {
    seon: {
      init(): void;
      getSession(config: { silentMode?: boolean }): Promise<any>;
    };
  }
}

export enum SeonValidationActionType {
  VERIFICATION = 'verification',
  ACCOUNT_LOGIN = 'account_login',
}

@Injectable({
  providedIn: 'root',
})
export class SeonService {
  private readonly isBrowser: boolean;
  constructor(
    private readonly userFingerprintService: UserFingerprintControllerService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  init(): void {
    if (this.isBrowser && typeof window.seon !== 'undefined') {
      window.seon.init();
    }
  }

  public async sendUserFingerprint(actionType: SeonValidationActionType): Promise<void> {
    if (!this.isBrowser || typeof window.seon === 'undefined') {
      return;
    }

    try {
      const session = await window.seon.getSession({
        silentMode: false,
      });
      await firstValueFrom(
        this.userFingerprintService.receiveFingerprintUsingPost({
          body: {
            actionType,
            session,
          },
        }),
      );
    } catch (error) {
      console.error('Failed to process fingerprint:', error);
      throw error;
    }
  }
}
