import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPairWithStatsDto } from '@app-generated/models/currency-pair-with-stats-dto';
import { Currencies } from '@app-shared/store/currencies/currencies-facade.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'appCurrencyPairDisplay',
})
export class AppCurrencyPairDisplayPipe implements PipeTransform {
  constructor(private currencies: Currencies) {}

  transform(pair: CurrencyPairWithStatsDto | string): Observable<string> {
    let firstPairName: string;
    let secondPairName: string;

    if (typeof pair === 'string') {
      firstPairName = pair.split('_')[0];
      secondPairName = pair.split('_')[1];
    } else {
      firstPairName = pair.firstCurrency || '';
      secondPairName = pair.secondCurrency || '';
    }

    return this.currencies.currencies$.pipe(
      map((currencies) => {
        const first = currencies.find((currency) => currency.name === firstPairName)?.name || '';
        const second = currencies.find((currency) => currency.name === secondPairName)?.name || '';
        return `${first}/${second}`;
      }),
    );
  }
}
