<app-base-modal [id]="modalId" [title]="'balances' | translate" [size]="'large'" [isBodyZeroSpacing]="true">
  <ng-container modal-header>
    <app-currencies-price-update-button
      *ngIf="lastUpdate"
      [date]="lastUpdate"
      (click)="refreshData()"
    ></app-currencies-price-update-button>
    <app-search
      [searchPlaceholder]="'quick-trade.main.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <app-base-modal-body>
    <ng-container *ngIf="currenciesLimited$ | async as currencies">
      <ng-container *ngIf="currencies.length; else noData">
        <table class="table-auto w-full">
          <thead>
            <tr class="currencies-table-row">
              <th *ngFor="let header of headers; let isLast = last" class="currencies-table-cell">
                <div class="inline-flex items-center gap-x-6px">
                  {{ header | translate }}
                  <ng-container *ngIf="isLast">
                    <ng-container *ngTemplateOutlet="priceUpdateInfo"></ng-container>
                  </ng-container>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="currencies-table-row" *ngFor="let currency of currencies">
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="flex items-center gap-x-8px md:gap-x-20px">
                    <img
                      class="h-8 w-8 rounded-full"
                      [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                      alt="Currency logo"
                    />
                    <div class="flex flex-col gap-y-2px">
                      <div class="font-bold">
                        {{ getCurrencyDisplayName(currency.name) }}
                      </div>
                      <div class="flex flex-col gap-8px md:flex-row">
                        <span class="text-dimmed text-sm">
                          {{ currency.name }}
                        </span>
                        <span class="hidden md:block">
                          <ng-container
                            *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"
                          ></ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="md:hidden">
                    <ng-container *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"></ng-container>
                  </div>
                </div>
              </td>
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="font-bold">
                    {{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}
                  </div>
                  <div
                    *ngIf="currency.name !== 'CZK' && currency.name !== 'EUR'"
                    class="text-dimmed text-sm whitespace-normal"
                  >
                    {{ currency.fiatEquivalentsAvailable.CZK | appCurrencyFloor: 'CZK' | async }} CZK ≈
                    {{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}
                  </div>
                  <div
                    *ngIf="
                      currency.name !== 'CZK' && currency.name !== 'EUR' && currency.lastPrices.CZK as lastPriceCZK
                    "
                    class="text-sm font-bold"
                  >
                    {{ lastPriceCZK | appCurrencyFloor: 'CZK' | async }} CZK
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="no-data">
        {{ (searchTermSubject.value ? 'currencies.not-found' : 'shared.common.no-data') | translate }}
      </div>
    </ng-template>

    <ng-template #onlyBuySellInfo let-currency>
      <app-dropdown
        *ngIf="!currency.depositActive || !currency.withdrawalActive"
        [popoverPosition]="'topStart'"
        [popoverClass]="'md:min-w-[382px]'"
      >
        <button ribbon dropdown-trigger>
          {{ 'currencies.only-buy-sell' | translate }}
        </button>
        <app-message
          [type]="'info'"
          [showMessage]="true"
          [compact]="true"
          [title]="'currencies.blocked-deposit-withdrawal.title' | translate"
          [message]="'currencies.blocked-deposit-withdrawal.message' | translate"
        >
          <app-action-button
            [facet]="'secondary'"
            [size]="'small'"
            [label]="'shared.common.close' | translate"
          ></app-action-button>
        </app-message>
      </app-dropdown>
    </ng-template>

    <ng-template #priceUpdateInfo>
      <app-dropdown [popoverPosition]="'bottomEnd'" [popoverClass]="'md:min-w-[382px]'">
        <button dropdown-trigger>
          <svg-icon src="/assets/icons/12/info-circle.svg"></svg-icon>
        </button>
        <app-message
          [type]="'info'"
          [showMessage]="true"
          [compact]="true"
          [title]="'currencies.price-info.title' | translate"
          [message]="'currencies.price-info.message' | translate"
        >
          <app-action-button
            [facet]="'secondary'"
            [size]="'small'"
            [label]="'shared.common.close' | translate"
          ></app-action-button>
        </app-message>
      </app-dropdown>
    </ng-template>

    <app-currencies-show-more-button
      *ngIf="!(searchTermSubject | async)"
      class="mt-7"
      [currencies$]="currencies$"
      [currenciesLimited$]="currenciesLimited$"
      (click)="showAllCurrencies()"
    ></app-currencies-show-more-button>
  </app-base-modal-body>
</app-base-modal>
