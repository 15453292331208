/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AmlOptionsDto } from '../models/aml-options-dto';
import { CompanyDto } from '../models/company-dto';
import { PersonBasicInfoDto } from '../models/person-basic-info-dto';
import { ValueWrapperLong } from '../models/value-wrapper-long';
import { ValueWrapperString } from '../models/value-wrapper-string';
import { VerificationDocumentDto } from '../models/verification-document-dto';

@Injectable({
  providedIn: 'root',
})
export class UserVerificationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation validateSmsCodeAndMoveVerificationFlowUsingPut
   */
  static readonly ValidateSmsCodeAndMoveVerificationFlowUsingPutPath = '/frontend/user/verification/validate-sms-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateSmsCodeAndMoveVerificationFlowUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateSmsCodeAndMoveVerificationFlowUsingPut$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.ValidateSmsCodeAndMoveVerificationFlowUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateSmsCodeAndMoveVerificationFlowUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateSmsCodeAndMoveVerificationFlowUsingPut(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.validateSmsCodeAndMoveVerificationFlowUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation validateCompanyShareholdersAndMoveVerificationFlowUsingPost
   */
  static readonly ValidateCompanyShareholdersAndMoveVerificationFlowUsingPostPath = '/frontend/user/verification/validate-shareholders';

  /**
   * Move verification flow step forward after shareholders have been added/edited
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCompanyShareholdersAndMoveVerificationFlowUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCompanyShareholdersAndMoveVerificationFlowUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.ValidateCompanyShareholdersAndMoveVerificationFlowUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow step forward after shareholders have been added/edited
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateCompanyShareholdersAndMoveVerificationFlowUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCompanyShareholdersAndMoveVerificationFlowUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.validateCompanyShareholdersAndMoveVerificationFlowUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation validateCompanyDirectorsAndMoveVerificationFlowUsingPost
   */
  static readonly ValidateCompanyDirectorsAndMoveVerificationFlowUsingPostPath = '/frontend/user/verification/validate-directors';

  /**
   * Move verification flow after directors have been added/edited
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCompanyDirectorsAndMoveVerificationFlowUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCompanyDirectorsAndMoveVerificationFlowUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.ValidateCompanyDirectorsAndMoveVerificationFlowUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow after directors have been added/edited
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateCompanyDirectorsAndMoveVerificationFlowUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCompanyDirectorsAndMoveVerificationFlowUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.validateCompanyDirectorsAndMoveVerificationFlowUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation uploadDocumentUsingPost
   */
  static readonly UploadDocumentUsingPostPath = '/frontend/user/verification/upload-document';

  /**
   * Upload document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocumentUsingPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocumentUsingPost$Response(params?: {
    documentType?: 'BANK_STATEMENT' | 'COMPANY_REGISTER' | 'KYC_QUESTIONNAIRE' | 'ID_FRONT_PHOTO' | 'ID_BACK_PHOTO' | 'IDNOW_ID_HOLOGRAMS' | 'FACE_PHOTO' | 'IDNOW_LIVENESS_LEFT' | 'IDNOW_LIVENESS_RIGHT' | 'ID_FRONT_VIDEO' | 'ID_BACK_VIDEO' | 'FACE_VIDEO' | 'OTHER' | 'IDENTITY' | 'UTILITY_BILL' | 'ADDITIONAL_DOCUMENT' | 'ADDITIONAL_DOCUMENT_VIDEO' | 'ADDITIONAL_DOCUMENT_BACKSIDE' | 'ADDITIONAL_DOCUMENT_BACKSIDE_VIDEO' | 'TAX_RETURN' | 'PROFIT_LOSS' | 'BALANCE_SHEET' | 'ADDRESS' | 'UBO' | 'NOTARIAL_DEED' | 'CRYPTOWALLET' | 'AML_INTERNAL_POLICY' | 'AML_FORMULAIRE_CLIENT_CHECK' | 'PURCHASE_AGREEMENT' | 'INHERITANCE' | 'GIFT_COVENANCE' | 'PEP_DECLARATION' | 'MANUAL_VERIFICATION';
    'content-type'?: 'multipart/form-data';
    body?: {
'attachment': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VerificationDocumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.UploadDocumentUsingPostPath, 'post');
    if (params) {
      rb.query('documentType', params.documentType, {});
      rb.header('content-type', params['content-type'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VerificationDocumentDto>;
      })
    );
  }

  /**
   * Upload document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadDocumentUsingPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocumentUsingPost(params?: {
    documentType?: 'BANK_STATEMENT' | 'COMPANY_REGISTER' | 'KYC_QUESTIONNAIRE' | 'ID_FRONT_PHOTO' | 'ID_BACK_PHOTO' | 'IDNOW_ID_HOLOGRAMS' | 'FACE_PHOTO' | 'IDNOW_LIVENESS_LEFT' | 'IDNOW_LIVENESS_RIGHT' | 'ID_FRONT_VIDEO' | 'ID_BACK_VIDEO' | 'FACE_VIDEO' | 'OTHER' | 'IDENTITY' | 'UTILITY_BILL' | 'ADDITIONAL_DOCUMENT' | 'ADDITIONAL_DOCUMENT_VIDEO' | 'ADDITIONAL_DOCUMENT_BACKSIDE' | 'ADDITIONAL_DOCUMENT_BACKSIDE_VIDEO' | 'TAX_RETURN' | 'PROFIT_LOSS' | 'BALANCE_SHEET' | 'ADDRESS' | 'UBO' | 'NOTARIAL_DEED' | 'CRYPTOWALLET' | 'AML_INTERNAL_POLICY' | 'AML_FORMULAIRE_CLIENT_CHECK' | 'PURCHASE_AGREEMENT' | 'INHERITANCE' | 'GIFT_COVENANCE' | 'PEP_DECLARATION' | 'MANUAL_VERIFICATION';
    'content-type'?: 'multipart/form-data';
    body?: {
'attachment': Blob;
}
  },
  context?: HttpContext

): Observable<VerificationDocumentDto> {

    return this.uploadDocumentUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<VerificationDocumentDto>) => r.body as VerificationDocumentDto)
    );
  }

  /**
   * Path part for operation saveTurnoverUsingPost
   */
  static readonly SaveTurnoverUsingPostPath = '/frontend/user/verification/turnover';

  /**
   * Save data about user expected turnover
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTurnoverUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveTurnoverUsingPost$Response(params: {
    body: ValueWrapperLong
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SaveTurnoverUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save data about user expected turnover
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveTurnoverUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveTurnoverUsingPost(params: {
    body: ValueWrapperLong
  },
  context?: HttpContext

): Observable<void> {

    return this.saveTurnoverUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowToUpdateAmlFormUsingPost
   */
  static readonly MoveVerificationFlowToUpdateAmlFormUsingPostPath = '/frontend/user/verification/to-questionnaire-update';

  /**
   * Move verification flow to questionnaire update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowToUpdateAmlFormUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowToUpdateAmlFormUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowToUpdateAmlFormUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow to questionnaire update
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowToUpdateAmlFormUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowToUpdateAmlFormUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowToUpdateAmlFormUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stepBackUsingPost
   */
  static readonly StepBackUsingPostPath = '/frontend/user/verification/step-back';

  /**
   * Moves user in verification flow one step back
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stepBackUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  stepBackUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.StepBackUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>;
      })
    );
  }

  /**
   * Moves user in verification flow one step back
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stepBackUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stepBackUsingPost(params?: {
  },
  context?: HttpContext

): Observable<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'> {

    return this.stepBackUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT'>) => r.body as 'BASIC_INFO' | 'ADD_DIRECTORS' | 'SELECT_DIRECTOR' | 'PHONE_VERIFICATION' | 'VIDEO_VERIFICATION' | 'VIDEO_VERIFICATION_SUCCESS' | 'CONGRATULATIONS' | 'VERIFICATION_SUCCESS' | 'SHAREHOLDERS' | 'BENEFICIARIES' | 'DOCUMENTS_UPLOAD' | 'PLANNED_ACTIVITY' | 'TURNOVER' | 'AML_FORM' | 'AML_FORM_UPDATE' | 'AML_FORM_SENT' | 'AML_SOFT_REJECT' | 'AML_HARD_REJECT')
    );
  }

  /**
   * Path part for operation sendVerificationSmsUsingPost
   */
  static readonly SendVerificationSmsUsingPostPath = '/frontend/user/verification/sms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendVerificationSmsUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVerificationSmsUsingPost$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SendVerificationSmsUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendVerificationSmsUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVerificationSmsUsingPost(params: {
    body: string
  },
  context?: HttpContext

): Observable<void> {

    return this.sendVerificationSmsUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation selectCompanyDirectorForVerificationUsingPost
   */
  static readonly SelectCompanyDirectorForVerificationUsingPostPath = '/frontend/user/verification/select-director/{directorId}';

  /**
   * Selected company director for verifications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectCompanyDirectorForVerificationUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectCompanyDirectorForVerificationUsingPost$Response(params: {
    directorId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SelectCompanyDirectorForVerificationUsingPostPath, 'post');
    if (params) {
      rb.path('directorId', params.directorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Selected company director for verifications
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `selectCompanyDirectorForVerificationUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectCompanyDirectorForVerificationUsingPost(params: {
    directorId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.selectCompanyDirectorForVerificationUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation savePlannedActivityUsingPost
   */
  static readonly SavePlannedActivityUsingPostPath = '/frontend/user/verification/planned-activity';

  /**
   * Save data of planned activity page - AML options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePlannedActivityUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlannedActivityUsingPost$Response(params: {
    body: AmlOptionsDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SavePlannedActivityUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save data of planned activity page - AML options
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePlannedActivityUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePlannedActivityUsingPost(params: {
    body: AmlOptionsDto
  },
  context?: HttpContext

): Observable<void> {

    return this.savePlannedActivityUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation savePersonBasicInfoUsingPost
   */
  static readonly SavePersonBasicInfoUsingPostPath = '/frontend/user/verification/person-basic-info';

  /**
   * Save basic information of logged user's person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePersonBasicInfoUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePersonBasicInfoUsingPost$Response(params: {
    body: PersonBasicInfoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SavePersonBasicInfoUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save basic information of logged user's person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePersonBasicInfoUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePersonBasicInfoUsingPost(params: {
    body: PersonBasicInfoDto
  },
  context?: HttpContext

): Observable<void> {

    return this.savePersonBasicInfoUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveCompanyBasicInfoUsingPost
   */
  static readonly SaveCompanyBasicInfoUsingPostPath = '/frontend/user/verification/company-basic-info';

  /**
   * Save Company's basic information and move verification flow step forward
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCompanyBasicInfoUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCompanyBasicInfoUsingPost$Response(params: {
    body: CompanyDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.SaveCompanyBasicInfoUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save Company's basic information and move verification flow step forward
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveCompanyBasicInfoUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCompanyBasicInfoUsingPost(params: {
    body: CompanyDto
  },
  context?: HttpContext

): Observable<void> {

    return this.saveCompanyBasicInfoUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowFromVideoVerificationUsingPost
   */
  static readonly MoveVerificationFlowFromVideoVerificationUsingPostPath = '/frontend/user/verification/after-video-verification';

  /**
   * Move verification flow from successful video verification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowFromVideoVerificationUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromVideoVerificationUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowFromVideoVerificationUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow from successful video verification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowFromVideoVerificationUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromVideoVerificationUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowFromVideoVerificationUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowToLimitsIncreasingUsingPost
   */
  static readonly MoveVerificationFlowToLimitsIncreasingUsingPostPath = '/frontend/user/verification/after-verification';

  /**
   * Move verification flow to limits increasing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowToLimitsIncreasingUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowToLimitsIncreasingUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowToLimitsIncreasingUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow to limits increasing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowToLimitsIncreasingUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowToLimitsIncreasingUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowToLimitsIncreasingUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowFromDocumentUploadUsingPost
   */
  static readonly MoveVerificationFlowFromDocumentUploadUsingPostPath = '/frontend/user/verification/after-document-upload';

  /**
   * Move verification flow step forward after document upload
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowFromDocumentUploadUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromDocumentUploadUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowFromDocumentUploadUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow step forward after document upload
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowFromDocumentUploadUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromDocumentUploadUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowFromDocumentUploadUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowFromCongratulationsUsingPost
   */
  static readonly MoveVerificationFlowFromCongratulationsUsingPostPath = '/frontend/user/verification/after-congratulations';

  /**
   * Move verification flow step forward after congratulations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowFromCongratulationsUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromCongratulationsUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowFromCongratulationsUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow step forward after congratulations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowFromCongratulationsUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromCongratulationsUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowFromCongratulationsUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveVerificationFlowFromCompanyBeneficiariesUsingPost
   */
  static readonly MoveVerificationFlowFromCompanyBeneficiariesUsingPostPath = '/frontend/user/verification/after-beneficiaries';

  /**
   * Move verification flow step forward after beneficiaries have been added/edited
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveVerificationFlowFromCompanyBeneficiariesUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromCompanyBeneficiariesUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.MoveVerificationFlowFromCompanyBeneficiariesUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move verification flow step forward after beneficiaries have been added/edited
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveVerificationFlowFromCompanyBeneficiariesUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveVerificationFlowFromCompanyBeneficiariesUsingPost(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.moveVerificationFlowFromCompanyBeneficiariesUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getVerificationCommentUsingGet
   */
  static readonly GetVerificationCommentUsingGetPath = '/frontend/user/verification/verification-comment';

  /**
   * Get verification comment for current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerificationCommentUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationCommentUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValueWrapperString>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.GetVerificationCommentUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValueWrapperString>;
      })
    );
  }

  /**
   * Get verification comment for current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVerificationCommentUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationCommentUsingGet(params?: {
  },
  context?: HttpContext

): Observable<ValueWrapperString> {

    return this.getVerificationCommentUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValueWrapperString>) => r.body as ValueWrapperString)
    );
  }

  /**
   * Path part for operation getSmsCodeTimerDurationUsingGet
   */
  static readonly GetSmsCodeTimerDurationUsingGetPath = '/frontend/user/verification/sms-timer-duration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsCodeTimerDurationUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsCodeTimerDurationUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.GetSmsCodeTimerDurationUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSmsCodeTimerDurationUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsCodeTimerDurationUsingGet(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.getSmsCodeTimerDurationUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getVerificationSmsSendCountUsingGet
   */
  static readonly GetVerificationSmsSendCountUsingGetPath = '/frontend/user/verification/sms-attempts-left';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerificationSmsSendCountUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationSmsSendCountUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.GetVerificationSmsSendCountUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVerificationSmsSendCountUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationSmsSendCountUsingGet(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.getVerificationSmsSendCountUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getVerificationDocumentsUsingGet
   */
  static readonly GetVerificationDocumentsUsingGetPath = '/frontend/user/verification/documents';

  /**
   * Get verification documents for current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerificationDocumentsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationDocumentsUsingGet$Response(params?: {
    documentType?: 'BANK_STATEMENT' | 'COMPANY_REGISTER' | 'KYC_QUESTIONNAIRE' | 'ID_FRONT_PHOTO' | 'ID_BACK_PHOTO' | 'IDNOW_ID_HOLOGRAMS' | 'FACE_PHOTO' | 'IDNOW_LIVENESS_LEFT' | 'IDNOW_LIVENESS_RIGHT' | 'ID_FRONT_VIDEO' | 'ID_BACK_VIDEO' | 'FACE_VIDEO' | 'OTHER' | 'IDENTITY' | 'UTILITY_BILL' | 'ADDITIONAL_DOCUMENT' | 'ADDITIONAL_DOCUMENT_VIDEO' | 'ADDITIONAL_DOCUMENT_BACKSIDE' | 'ADDITIONAL_DOCUMENT_BACKSIDE_VIDEO' | 'TAX_RETURN' | 'PROFIT_LOSS' | 'BALANCE_SHEET' | 'ADDRESS' | 'UBO' | 'NOTARIAL_DEED' | 'CRYPTOWALLET' | 'AML_INTERNAL_POLICY' | 'AML_FORMULAIRE_CLIENT_CHECK' | 'PURCHASE_AGREEMENT' | 'INHERITANCE' | 'GIFT_COVENANCE' | 'PEP_DECLARATION' | 'MANUAL_VERIFICATION';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VerificationDocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserVerificationControllerService.GetVerificationDocumentsUsingGetPath, 'get');
    if (params) {
      rb.query('documentType', params.documentType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerificationDocumentDto>>;
      })
    );
  }

  /**
   * Get verification documents for current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVerificationDocumentsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerificationDocumentsUsingGet(params?: {
    documentType?: 'BANK_STATEMENT' | 'COMPANY_REGISTER' | 'KYC_QUESTIONNAIRE' | 'ID_FRONT_PHOTO' | 'ID_BACK_PHOTO' | 'IDNOW_ID_HOLOGRAMS' | 'FACE_PHOTO' | 'IDNOW_LIVENESS_LEFT' | 'IDNOW_LIVENESS_RIGHT' | 'ID_FRONT_VIDEO' | 'ID_BACK_VIDEO' | 'FACE_VIDEO' | 'OTHER' | 'IDENTITY' | 'UTILITY_BILL' | 'ADDITIONAL_DOCUMENT' | 'ADDITIONAL_DOCUMENT_VIDEO' | 'ADDITIONAL_DOCUMENT_BACKSIDE' | 'ADDITIONAL_DOCUMENT_BACKSIDE_VIDEO' | 'TAX_RETURN' | 'PROFIT_LOSS' | 'BALANCE_SHEET' | 'ADDRESS' | 'UBO' | 'NOTARIAL_DEED' | 'CRYPTOWALLET' | 'AML_INTERNAL_POLICY' | 'AML_FORMULAIRE_CLIENT_CHECK' | 'PURCHASE_AGREEMENT' | 'INHERITANCE' | 'GIFT_COVENANCE' | 'PEP_DECLARATION' | 'MANUAL_VERIFICATION';
  },
  context?: HttpContext

): Observable<Array<VerificationDocumentDto>> {

    return this.getVerificationDocumentsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VerificationDocumentDto>>) => r.body as Array<VerificationDocumentDto>)
    );
  }

}
