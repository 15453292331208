import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';

@Component({
  selector: 'app-currency-pair',
  templateUrl: './currency-pair.component.html',
  styleUrls: ['./currency-pair.component.scss'],
})
export class CurrencyPairComponent implements OnInit {
  @Input()
  type: 'add' | 'remove' = 'add';

  @Input()
  currencyPair!: CurrencyPairWithStatsDto;

  @Input()
  buttonAction!: (currencyPair: CurrencyPairWithStatsDto) => void;

  @Input()
  isButtonDisplayed = false;

  @Input()
  changeSelectedPair!: (currencyPair: CurrencyPairWithStatsDto) => void;

  buttonIcon!: string;
  buttonIconDark!: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.buttonIcon = `/assets/icons/icon-${this.type}.svg`;
    this.buttonIconDark = `/assets/icons/icon-${this.type}-dark.svg`;
  }

  pairIcon(): string {
    return `/assets/crypto/${this.currencyPair.firstCurrency.toLowerCase()}-bw.svg`;
  }

  getButtonTooltip(): string {
    switch (this.type) {
      case 'add':
        return this.translate.instant('Add to favourites');
      case 'remove':
        return this.translate.instant('Remove from favourites');
      default:
        return '';
    }
  }

  getTooltip(currencyPair: CurrencyPairWithStatsDto): string {
    return currencyPair.name;
  }
}
