import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (typeof window === 'undefined') {
      // not browser
      return next.handle(req);
    }

    let clonedRequest = req;
    const sseChannelId = sessionStorage.getItem('sseChannelId');
    if (sseChannelId) {
      clonedRequest = req.clone({ headers: req.headers.append('X-CM-SSE-ChannelId', sseChannelId) });
    }
    return next.handle(clonedRequest);
  }
}
