import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyPairDto } from '@app-generated/models/currency-pair-dto';
import {
  addCurrencyPairToFavourites,
  clearFilter,
  removeCurrencyPairFromFavourites,
  setCurrencyPairs,
  setCurrencyPairsWithStats,
  setFavouriteCurrencyPairs,
  toggleFilter,
  updateCurrencyPairWithStats,
  updateCurrencyPairWithStatsTemporaryEndpointForAllCurrencies,
} from '@app-shared/store/currency-pairs/currency-pairs.actions';
import { CurrencyPairWithStatsDto } from '@app-generated/models/currency-pair-with-stats-dto';
import { append, assoc, update } from 'ramda';

export type State = {
  pairs: CurrencyPairDto[];
  pairsWithStats: CurrencyPairWithStatsDto[];
  favouritePairs: CurrencyPairWithStatsDto[];
  pairsFilter: { name: string; active: boolean }[];
};

export const initialState: State = {
  pairs: [],
  pairsWithStats: [],
  favouritePairs: [],
  pairsFilter: [
    { name: 'BTC', active: false },
    { name: 'LTC', active: false },
    { name: 'CZK', active: false },
    { name: 'EUR', active: false },
  ],
};

const currencyPairsReducer = createReducer(
  initialState,
  on(setCurrencyPairs, (state, action) => ({ ...state, pairs: action.currencyPairs })),
  on(setCurrencyPairsWithStats, (state, action) => ({
    ...state,
    pairsWithStats: action.currencyPairsWithStats,
  })),
  on(updateCurrencyPairWithStats, (state, action) => ({
    ...state,
    pairsWithStats: update(
      state.pairsWithStats.findIndex(({ name }) => name === action.currencyPairWithStats.name),
      action.currencyPairWithStats,
      state.pairsWithStats,
    ),
  })),
  on(updateCurrencyPairWithStatsTemporaryEndpointForAllCurrencies, (state, action) => ({
    ...state,
    pairsWithStats: action.currencyPairWithStats,
  })),
  on(setFavouriteCurrencyPairs, (state, action) => ({
    ...state,
    favouritePairs: action.favouriteCurrencyPairs,
  })),
  on(addCurrencyPairToFavourites, (state, action) => ({
    ...state,
    favouritePairs: append(action.pair, state.favouritePairs),
  })),
  on(removeCurrencyPairFromFavourites, (state, action) => ({
    ...state,
    favouritePairs: state.favouritePairs.filter((pair) => action.name !== pair.name),
  })),
  on(clearFilter, (state, action) => ({
    ...state,
    pairsFilter: state.pairsFilter.map((option) => assoc('active', false, option)),
  })),
  on(toggleFilter, (state, action) => ({
    ...state,
    pairsFilter: state.pairsFilter.map((option) =>
      option.name === action.name ? assoc('active', !option.active, option) : option,
    ),
  })),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return currencyPairsReducer(state, action);
}
