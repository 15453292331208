<div
  *ngIf="showMessage"
  [ngClass]="{
    'alert-info': type === 'info',
    'alert-warning': type === 'warning',
    'alert-error': type === 'error',
    'alert-success': type === 'success',
    'alert-compact': compact
  }"
  class="alert"
>
  <div *ngIf="title" class="heading">
    <div class="title-wrap">
      <div class="title" [innerHTML]="title"></div>
    </div>
    <ng-container [ngTemplateOutlet]="closeButton"></ng-container>
  </div>

  <div class="body">
    <div class="message-wrap">
      <div *ngIf="message" class="message" [innerHTML]="message"></div>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!title" [ngTemplateOutlet]="closeButton"></ng-container>
  </div>

  <ng-template #closeButton>
    <button *ngIf="showCloseButton && !compact" class="close" (click)="showMessage = false">
      <svg-icon src="/assets/icons/icon-infobox-close.svg"></svg-icon>
    </button>
  </ng-template>
</div>
