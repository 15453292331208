/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfigDto } from '../models/config-dto';
import { HistoryBarsDto } from '../models/history-bars-dto';
import { SymbolInfoDto } from '../models/symbol-info-dto';

@Injectable({
  providedIn: 'root',
})
export class TradingViewControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSymbolsUsingGet
   */
  static readonly GetSymbolsUsingGetPath = '/frontend/tv/symbols';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSymbolsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSymbolsUsingGet$Response(params: {
    symbol: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SymbolInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, TradingViewControllerService.GetSymbolsUsingGetPath, 'get');
    if (params) {
      rb.query('symbol', params.symbol, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SymbolInfoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSymbolsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSymbolsUsingGet(params: {
    symbol: string;
  },
  context?: HttpContext

): Observable<SymbolInfoDto> {

    return this.getSymbolsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<SymbolInfoDto>) => r.body as SymbolInfoDto)
    );
  }

  /**
   * Path part for operation searchUsingGet
   */
  static readonly SearchUsingGetPath = '/frontend/tv/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<{
}>>> {

    const rb = new RequestBuilder(this.rootUrl, TradingViewControllerService.SearchUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<{
}>> {

    return this.searchUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>) => r.body as Array<{
}>)
    );
  }

  /**
   * Path part for operation getHistoryUsingGet
   */
  static readonly GetHistoryUsingGetPath = '/frontend/tv/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHistoryUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistoryUsingGet$Response(params: {
    symbol: string;
    from: number;
    to: number;
    resolution: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HistoryBarsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TradingViewControllerService.GetHistoryUsingGetPath, 'get');
    if (params) {
      rb.query('symbol', params.symbol, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('resolution', params.resolution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HistoryBarsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHistoryUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistoryUsingGet(params: {
    symbol: string;
    from: number;
    to: number;
    resolution: string;
  },
  context?: HttpContext

): Observable<HistoryBarsDto> {

    return this.getHistoryUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<HistoryBarsDto>) => r.body as HistoryBarsDto)
    );
  }

  /**
   * Path part for operation getConfigUsingGet
   */
  static readonly GetConfigUsingGetPath = '/frontend/tv/config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, TradingViewControllerService.GetConfigUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfigUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigUsingGet(params?: {
  },
  context?: HttpContext

): Observable<ConfigDto> {

    return this.getConfigUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConfigDto>) => r.body as ConfigDto)
    );
  }

}
